html,
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  /* background-color: lightgray !important; */
  background-color: whitesmoke !important;
  font-size: 13px !important;
  line-height: 1.2285em !important;
  min-height: 680px;
}

.main {
  padding-right: 3px;
  padding-left: 5px;
  height: auto !important; /* real browsers */
  min-height: 785px; /* real browsers */
}
