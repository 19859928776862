@font-face {
    font-family: Material Icons;
    src: url(https://cdnjs.cloudflare.com/ajax/libs/material-design-iconic-font/2.2.0/fonts/Material-Design-Iconic-Font.woff2) format('woff2'),url(https://cdnjs.cloudflare.com/ajax/libs/material-design-iconic-font/2.2.0/Material-Design-Iconic-Font.woff) format('woff'),url(https://cdnjs.cloudflare.com/ajax/libs/material-design-iconic-font/2.2.0/Material-Design-Iconic-Font.ttf) format('truetype');
  }
  
  .mdi {
    display: inline-block;
    font: normal normal normal 14px/1 'Material Icons';
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .mdi-hc-lg {
    font-size: 1.33333333em;
    line-height: .75em;
    vertical-align: -15%;
  }
  
  .mdi-hc-2x {
    font-size: 2em;
  }
  
  .mdi-hc-3x {
    font-size: 3em;
  }
  
  .mdi-hc-4x {
    font-size: 4em;
  }
  
  .mdi-hc-5x {
    font-size: 5em;
  }
  
  .mdi-hc-fw {
    width: 1.28571429em;
    text-align: center;
  }
  
  .mdi-hc-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
  }
  
  .mdi-hc-ul>li {
    position: relative;
  }
  
  .mdi-hc-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: .14285714em;
    text-align: center;
  }
  
  .mdi-hc-li.mdi-hc-lg {
    left: -1.85714286em;
  }
  
  .mdi-hc-border {
    padding: .1em .25em;
    border: solid .1em #9e9e9e;
    border-radius: 2px;
  }
  
  .mdi-hc-border-circle {
    padding: .1em .25em;
    border: solid .1em #9e9e9e;
    border-radius: 50%;
  }
  
  .mdi.pull-left {
    float: left;
    margin-right: .15em;
  }
  
  .mdi.pull-right {
    float: right;
    margin-left: .15em;
  }
  
  .mdi-hc-spin {
    -webkit-animation: zmdi-spin 1.5s infinite linear;
    animation: zmdi-spin 1.5s infinite linear;
  }
  
  .mdi-hc-spin-reverse {
    -webkit-animation: zmdi-spin-reverse 1.5s infinite linear;
    animation: zmdi-spin-reverse 1.5s infinite linear;
  }
  
  @-webkit-keyframes zmdi-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
  
  @keyframes zmdi-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
  
  @-webkit-keyframes zmdi-spin-reverse {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(-359deg);
      transform: rotate(-359deg);
    }
  }
  
  @keyframes zmdi-spin-reverse {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(-359deg);
      transform: rotate(-359deg);
    }
  }
  
  .mdi-hc-rotate-90 {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  
  .mdi-hc-rotate-180 {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  
  .mdi-hc-rotate-270 {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  
  .mdi-hc-flip-horizontal {
    -webkit-transform: scale(-1,1);
    transform: scale(-1,1);
  }
  
  .mdi-hc-flip-vertical {
    -webkit-transform: scale(1,-1);
    transform: scale(1,-1);
  }
  
  .mdi-hc-stack {
    position: relative;
    display: inline-block;
    width: 2em;
    height: 2em;
    line-height: 2em;
    vertical-align: middle;
  }
  
  .mdi-hc-stack-1x,.mdi-hc-stack-2x {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
  }
  
  .mdi-hc-stack-1x {
    line-height: inherit;
  }
  
  .mdi-hc-stack-2x {
    font-size: 2em;
  }
  
  .mdi-hc-inverse {
    color: #fff;
  }
  
  .mdi-3d-rotation:before {
    content: '\f101';
  }
  
  .mdi-airplane-off:before {
    content: '\f102';
  }
  
  .mdi-airplane:before {
    content: '\f103';
  }
  
  .mdi-album:before {
    content: '\f104';
  }
  
  .mdi-archive:before {
    content: '\f105';
  }
  
  .mdi-assignment-account:before {
    content: '\f106';
  }
  
  .mdi-assignment-alert:before {
    content: '\f107';
  }
  
  .mdi-assignment-check:before {
    content: '\f108';
  }
  
  .mdi-assignment-o:before {
    content: '\f109';
  }
  
  .mdi-assignment-return:before {
    content: '\f10a';
  }
  
  .mdi-assignment-returned:before {
    content: '\f10b';
  }
  
  .mdi-assignment:before {
    content: '\f10c';
  }
  
  .mdi-attachment-alt:before {
    content: '\f10d';
  }
  
  .mdi-attachment:before {
    content: '\f10e';
  }
  
  .mdi-audio:before {
    content: '\f10f';
  }
  
  .mdi-badge-check:before {
    content: '\f110';
  }
  
  .mdi-balance-wallet:before {
    content: '\f111';
  }
  
  .mdi-balance:before {
    content: '\f112';
  }
  
  .mdi-battery-alert:before {
    content: '\f113';
  }
  
  .mdi-battery-flash:before {
    content: '\f114';
  }
  
  .mdi-battery-unknown:before {
    content: '\f115';
  }
  
  .mdi-battery:before {
    content: '\f116';
  }
  
  .mdi-bike:before {
    content: '\f117';
  }
  
  .mdi-block-alt:before {
    content: '\f118';
  }
  
  .mdi-block:before {
    content: '\f119';
  }
  
  .mdi-boat:before {
    content: '\f11a';
  }
  
  .mdi-book-image:before {
    content: '\f11b';
  }
  
  .mdi-book:before {
    content: '\f11c';
  }
  
  .mdi-bookmark-outline:before {
    content: '\f11d';
  }
  
  .mdi-bookmark:before {
    content: '\f11e';
  }
  
  .mdi-brush:before {
    content: '\f11f';
  }
  
  .mdi-bug:before {
    content: '\f120';
  }
  
  .mdi-bus:before {
    content: '\f121';
  }
  
  .mdi-cake:before {
    content: '\f122';
  }
  
  .mdi-car-taxi:before {
    content: '\f123';
  }
  
  .mdi-car-wash:before {
    content: '\f124';
  }
  
  .mdi-car:before {
    content: '\f125';
  }
  
  .mdi-card-giftcard:before {
    content: '\f126';
  }
  
  .mdi-card-membership:before {
    content: '\f127';
  }
  
  .mdi-card-travel:before {
    content: '\f128';
  }
  
  .mdi-card:before {
    content: '\f129';
  }
  
  .mdi-case-check:before {
    content: '\f12a';
  }
  
  .mdi-case-download:before {
    content: '\f12b';
  }
  
  .mdi-case-play:before {
    content: '\f12c';
  }
  
  .mdi-case:before {
    content: '\f12d';
  }
  
  .mdi-cast-connected:before {
    content: '\f12e';
  }
  
  .mdi-cast:before {
    content: '\f12f';
  }
  
  .mdi-chart-donut:before {
    content: '\f130';
  }
  
  .mdi-chart:before {
    content: '\f131';
  }
  
  .mdi-city-alt:before {
    content: '\f132';
  }
  
  .mdi-city:before {
    content: '\f133';
  }
  
  .mdi-close-circle-o:before {
    content: '\f134';
  }
  
  .mdi-close-circle:before {
    content: '\f135';
  }
  
  .mdi-close:before {
    content: '\f136';
  }
  
  .mdi-cocktail:before {
    content: '\f137';
  }
  
  .mdi-code-setting:before {
    content: '\f138';
  }
  
  .mdi-code-smartphone:before {
    content: '\f139';
  }
  
  .mdi-code:before {
    content: '\f13a';
  }
  
  .mdi-coffee:before {
    content: '\f13b';
  }
  
  .mdi-collection-bookmark:before {
    content: '\f13c';
  }
  
  .mdi-collection-case-play:before {
    content: '\f13d';
  }
  
  .mdi-collection-folder-image:before {
    content: '\f13e';
  }
  
  .mdi-collection-image-o:before {
    content: '\f13f';
  }
  
  .mdi-collection-image:before {
    content: '\f140';
  }
  
  .mdi-collection-item-1:before {
    content: '\f141';
  }
  
  .mdi-collection-item-2:before {
    content: '\f142';
  }
  
  .mdi-collection-item-3:before {
    content: '\f143';
  }
  
  .mdi-collection-item-4:before {
    content: '\f144';
  }
  
  .mdi-collection-item-5:before {
    content: '\f145';
  }
  
  .mdi-collection-item-6:before {
    content: '\f146';
  }
  
  .mdi-collection-item-7:before {
    content: '\f147';
  }
  
  .mdi-collection-item-8:before {
    content: '\f148';
  }
  
  .mdi-collection-item-9-plus:before {
    content: '\f149';
  }
  
  .mdi-collection-item-9:before {
    content: '\f14a';
  }
  
  .mdi-collection-item:before {
    content: '\f14b';
  }
  
  .mdi-collection-music:before {
    content: '\f14c';
  }
  
  .mdi-collection-pdf:before {
    content: '\f14d';
  }
  
  .mdi-collection-plus:before {
    content: '\f14e';
  }
  
  .mdi-collection-speaker:before {
    content: '\f14f';
  }
  
  .mdi-collection-text:before {
    content: '\f150';
  }
  
  .mdi-collection-video:before {
    content: '\f151';
  }
  
  .mdi-compass:before {
    content: '\f152';
  }
  
  .mdi-cutlery:before {
    content: '\f153';
  }
  
  .mdi-delete:before {
    content: '\f154';
  }
  
  .mdi-dialpad:before {
    content: '\f155';
  }
  
  .mdi-dns:before {
    content: '\f156';
  }
  
  .mdi-drink:before {
    content: '\f157';
  }
  
  .mdi-edit:before {
    content: '\f158';
  }
  
  .mdi-email-open:before {
    content: '\f159';
  }
  
  .mdi-email:before {
    content: '\f15a';
  }
  
  .mdi-eye-off:before {
    content: '\f15b';
  }
  
  .mdi-eye:before {
    content: '\f15c';
  }
  
  .mdi-eyedropper:before {
    content: '\f15d';
  }
  
  .mdi-favorite-outline:before {
    content: '\f15e';
  }
  
  .mdi-favorite:before {
    content: '\f15f';
  }
  
  .mdi-filter-list:before {
    content: '\f160';
  }
  
  .mdi-fire:before {
    content: '\f161';
  }
  
  .mdi-flag:before {
    content: '\f162';
  }
  
  .mdi-flare:before {
    content: '\f163';
  }
  
  .mdi-flash-auto:before {
    content: '\f164';
  }
  
  .mdi-flash-off:before {
    content: '\f165';
  }
  
  .mdi-flash:before {
    content: '\f166';
  }
  
  .mdi-flip:before {
    content: '\f167';
  }
  
  .mdi-flower-alt:before {
    content: '\f168';
  }
  
  .mdi-flower:before {
    content: '\f169';
  }
  
  .mdi-font:before {
    content: '\f16a';
  }
  
  .mdi-fullscreen-alt:before {
    content: '\f16b';
  }
  
  .mdi-fullscreen-exit:before {
    content: '\f16c';
  }
  
  .mdi-fullscreen:before {
    content: '\f16d';
  }
  
  .mdi-functions:before {
    content: '\f16e';
  }
  
  .mdi-gas-station:before {
    content: '\f16f';
  }
  
  .mdi-gesture:before {
    content: '\f170';
  }
  
  .mdi-globe-alt:before {
    content: '\f171';
  }
  
  .mdi-globe-lock:before {
    content: '\f172';
  }
  
  .mdi-globe:before {
    content: '\f173';
  }
  
  .mdi-graduation-cap:before {
    content: '\f174';
  }
  
  .mdi-home:before {
    content: '\f175';
  }
  
  .mdi-hospital-alt:before {
    content: '\f176';
  }
  
  .mdi-hospital:before {
    content: '\f177';
  }
  
  .mdi-hotel:before {
    content: '\f178';
  }
  
  .mdi-hourglass-alt:before {
    content: '\f179';
  }
  
  .mdi-hourglass-outline:before {
    content: '\f17a';
  }
  
  .mdi-hourglass:before {
    content: '\f17b';
  }
  
  .mdi-http:before {
    content: '\f17c';
  }
  
  .mdi-image-alt:before {
    content: '\f17d';
  }
  
  .mdi-image-o:before {
    content: '\f17e';
  }
  
  .mdi-image:before {
    content: '\f17f';
  }
  
  .mdi-inbox:before {
    content: '\f180';
  }
  
  .mdi-invert-colors-off:before {
    content: '\f181';
  }
  
  .mdi-invert-colors:before {
    content: '\f182';
  }
  
  .mdi-key:before {
    content: '\f183';
  }
  
  .mdi-label-alt-outline:before {
    content: '\f184';
  }
  
  .mdi-label-alt:before {
    content: '\f185';
  }
  
  .mdi-label-heart:before {
    content: '\f186';
  }
  
  .mdi-label:before {
    content: '\f187';
  }
  
  .mdi-labels:before {
    content: '\f188';
  }
  
  .mdi-lamp:before {
    content: '\f189';
  }
  
  .mdi-landscape:before {
    content: '\f18a';
  }
  
  .mdi-layers-off:before {
    content: '\f18b';
  }
  
  .mdi-layers:before {
    content: '\f18c';
  }
  
  .mdi-library:before {
    content: '\f18d';
  }
  
  .mdi-link:before {
    content: '\f18e';
  }
  
  .mdi-lock-open:before {
    content: '\f18f';
  }
  
  .mdi-lock-outline:before {
    content: '\f190';
  }
  
  .mdi-lock:before {
    content: '\f191';
  }
  
  .mdi-mail-reply-all:before {
    content: '\f192';
  }
  
  .mdi-mail-reply:before {
    content: '\f193';
  }
  
  .mdi-mail-send:before {
    content: '\f194';
  }
  
  .mdi-mall:before {
    content: '\f195';
  }
  
  .mdi-map:before {
    content: '\f196';
  }
  
  .mdi-menu:before {
    content: '\f197';
  }
  
  .mdi-money-box:before {
    content: '\f198';
  }
  
  .mdi-money-off:before {
    content: '\f199';
  }
  
  .mdi-money:before {
    content: '\f19a';
  }
  
  .mdi-more-vert:before {
    content: '\f19b';
  }
  
  .mdi-more:before {
    content: '\f19c';
  }
  
  .mdi-movie-alt:before {
    content: '\f19d';
  }
  
  .mdi-movie:before {
    content: '\f19e';
  }
  
  .mdi-nature-people:before {
    content: '\f19f';
  }
  
  .mdi-nature:before {
    content: '\f1a0';
  }
  
  .mdi-navigation:before {
    content: '\f1a1';
  }
  
  .mdi-open-in-browser:before {
    content: '\f1a2';
  }
  
  .mdi-open-in-new:before {
    content: '\f1a3';
  }
  
  .mdi-palette:before {
    content: '\f1a4';
  }
  
  .mdi-parking:before {
    content: '\f1a5';
  }
  
  .mdi-pin-account:before {
    content: '\f1a6';
  }
  
  .mdi-pin-assistant:before {
    content: '\f1a7';
  }
  
  .mdi-pin-drop:before {
    content: '\f1a8';
  }
  
  .mdi-pin-help:before {
    content: '\f1a9';
  }
  
  .mdi-pin-off:before {
    content: '\f1aa';
  }
  
  .mdi-pin:before {
    content: '\f1ab';
  }
  
  .mdi-pizza:before {
    content: '\f1ac';
  }
  
  .mdi-plaster:before {
    content: '\f1ad';
  }
  
  .mdi-power-setting:before {
    content: '\f1ae';
  }
  
  .mdi-power:before {
    content: '\f1af';
  }
  
  .mdi-print:before {
    content: '\f1b0';
  }
  
  .mdi-puzzle-piece:before {
    content: '\f1b1';
  }
  
  .mdi-quote:before {
    content: '\f1b2';
  }
  
  .mdi-railway:before {
    content: '\f1b3';
  }
  
  .mdi-receipt:before {
    content: '\f1b4';
  }
  
  .mdi-refresh-alt:before {
    content: '\f1b5';
  }
  
  .mdi-refresh-sync-alert:before {
    content: '\f1b6';
  }
  
  .mdi-refresh-sync-off:before {
    content: '\f1b7';
  }
  
  .mdi-refresh-sync:before {
    content: '\f1b8';
  }
  
  .mdi-refresh:before {
    content: '\f1b9';
  }
  
  .mdi-roller:before {
    content: '\f1ba';
  }
  
  .mdi-ruler:before {
    content: '\f1bb';
  }
  
  .mdi-scissors:before {
    content: '\f1bc';
  }
  
  .mdi-screen-rotation-lock:before {
    content: '\f1bd';
  }
  
  .mdi-screen-rotation:before {
    content: '\f1be';
  }
  
  .mdi-search-for:before {
    content: '\f1bf';
  }
  
  .mdi-search-in-file:before {
    content: '\f1c0';
  }
  
  .mdi-search-in-page:before {
    content: '\f1c1';
  }
  
  .mdi-search-replace:before {
    content: '\f1c2';
  }
  
  .mdi-search:before {
    content: '\f1c3';
  }
  
  .mdi-seat:before {
    content: '\f1c4';
  }
  
  .mdi-settings-square:before {
    content: '\f1c5';
  }
  
  .mdi-settings:before {
    content: '\f1c6';
  }
  
  .mdi-shield-check:before {
    content: '\f1c7';
  }
  
  .mdi-shield-security:before {
    content: '\f1c8';
  }
  
  .mdi-shopping-basket:before {
    content: '\f1c9';
  }
  
  .mdi-shopping-cart-plus:before {
    content: '\f1ca';
  }
  
  .mdi-shopping-cart:before {
    content: '\f1cb';
  }
  
  .mdi-sign-in:before {
    content: '\f1cc';
  }
  
  .mdi-sort-amount-asc:before {
    content: '\f1cd';
  }
  
  .mdi-sort-amount-desc:before {
    content: '\f1ce';
  }
  
  .mdi-sort-asc:before {
    content: '\f1cf';
  }
  
  .mdi-sort-desc:before {
    content: '\f1d0';
  }
  
  .mdi-spellcheck:before {
    content: '\f1d1';
  }
  
  .mdi-storage:before {
    content: '\f1d2';
  }
  
  .mdi-store-24:before {
    content: '\f1d3';
  }
  
  .mdi-store:before {
    content: '\f1d4';
  }
  
  .mdi-subway:before {
    content: '\f1d5';
  }
  
  .mdi-sun:before {
    content: '\f1d6';
  }
  
  .mdi-tab-unselected:before {
    content: '\f1d7';
  }
  
  .mdi-tab:before {
    content: '\f1d8';
  }
  
  .mdi-tag-close:before {
    content: '\f1d9';
  }
  
  .mdi-tag-more:before {
    content: '\f1da';
  }
  
  .mdi-tag:before {
    content: '\f1db';
  }
  
  .mdi-thumb-down:before {
    content: '\f1dc';
  }
  
  .mdi-thumb-up-down:before {
    content: '\f1dd';
  }
  
  .mdi-thumb-up:before {
    content: '\f1de';
  }
  
  .mdi-ticket-star:before {
    content: '\f1df';
  }
  
  .mdi-toll:before {
    content: '\f1e0';
  }
  
  .mdi-toys:before {
    content: '\f1e1';
  }
  
  .mdi-traffic:before {
    content: '\f1e2';
  }
  
  .mdi-translate:before {
    content: '\f1e3';
  }
  
  .mdi-triangle-down:before {
    content: '\f1e4';
  }
  
  .mdi-triangle-up:before {
    content: '\f1e5';
  }
  
  .mdi-truck:before {
    content: '\f1e6';
  }
  
  .mdi-turning-sign:before {
    content: '\f1e7';
  }
  
  .mdi-wallpaper:before {
    content: '\f1e8';
  }
  
  .mdi-washing-machine:before {
    content: '\f1e9';
  }
  
  .mdi-window-maximize:before {
    content: '\f1ea';
  }
  
  .mdi-window-minimize:before {
    content: '\f1eb';
  }
  
  .mdi-window-restore:before {
    content: '\f1ec';
  }
  
  .mdi-wrench:before {
    content: '\f1ed';
  }
  
  .mdi-zoom-in:before {
    content: '\f1ee';
  }
  
  .mdi-zoom-out:before {
    content: '\f1ef';
  }
  
  .mdi-alert-circle-o:before {
    content: '\f1f0';
  }
  
  .mdi-alert-circle:before {
    content: '\f1f1';
  }
  
  .mdi-alert-octagon:before {
    content: '\f1f2';
  }
  
  .mdi-alert-polygon:before {
    content: '\f1f3';
  }
  
  .mdi-alert-triangle:before {
    content: '\f1f4';
  }
  
  .mdi-help-outline:before {
    content: '\f1f5';
  }
  
  .mdi-help:before {
    content: '\f1f6';
  }
  
  .mdi-info-outline:before {
    content: '\f1f7';
  }
  
  .mdi-info:before {
    content: '\f1f8';
  }
  
  .mdi-notifications-active:before {
    content: '\f1f9';
  }
  
  .mdi-notifications-add:before {
    content: '\f1fa';
  }
  
  .mdi-notifications-none:before {
    content: '\f1fb';
  }
  
  .mdi-notifications-off:before {
    content: '\f1fc';
  }
  
  .mdi-notifications-paused:before {
    content: '\f1fd';
  }
  
  .mdi-notifications:before {
    content: '\f1fe';
  }
  
  .mdi-account-add:before {
    content: '\f1ff';
  }
  
  .mdi-account-box-mail:before {
    content: '\f200';
  }
  
  .mdi-account-box-o:before {
    content: '\f201';
  }
  
  .mdi-account-box-phone:before {
    content: '\f202';
  }
  
  .mdi-account-box:before {
    content: '\f203';
  }
  
  .mdi-account-calendar:before {
    content: '\f204';
  }
  
  .mdi-account-circle:before {
    content: '\f205';
  }
  
  .mdi-account-o:before {
    content: '\f206';
  }
  
  .mdi-account:before {
    content: '\f207';
  }
  
  .mdi-accounts-add:before {
    content: '\f208';
  }
  
  .mdi-accounts-alt:before {
    content: '\f209';
  }
  
  .mdi-accounts-list-alt:before {
    content: '\f20a';
  }
  
  .mdi-accounts-list:before {
    content: '\f20b';
  }
  
  .mdi-accounts-outline:before {
    content: '\f20c';
  }
  
  .mdi-accounts:before {
    content: '\f20d';
  }
  
  .mdi-face:before {
    content: '\f20e';
  }
  
  .mdi-female:before {
    content: '\f20f';
  }
  
  .mdi-male-alt:before {
    content: '\f210';
  }
  
  .mdi-male-female:before {
    content: '\f211';
  }
  
  .mdi-male:before {
    content: '\f212';
  }
  
  .mdi-mood-bad:before {
    content: '\f213';
  }
  
  .mdi-mood:before {
    content: '\f214';
  }
  
  .mdi-run:before {
    content: '\f215';
  }
  
  .mdi-walk:before {
    content: '\f216';
  }
  
  .mdi-cloud-box:before {
    content: '\f217';
  }
  
  .mdi-cloud-circle:before {
    content: '\f218';
  }
  
  .mdi-cloud-done:before {
    content: '\f219';
  }
  
  .mdi-cloud-download:before {
    content: '\f21a';
  }
  
  .mdi-cloud-off:before {
    content: '\f21b';
  }
  
  .mdi-cloud-outline-alt:before {
    content: '\f21c';
  }
  
  .mdi-cloud-outline:before {
    content: '\f21d';
  }
  
  .mdi-cloud-upload:before {
    content: '\f21e';
  }
  
  .mdi-cloud:before {
    content: '\f21f';
  }
  
  .mdi-download:before {
    content: '\f220';
  }
  
  .mdi-file-plus:before {
    content: '\f221';
  }
  
  .mdi-file-text:before {
    content: '\f222';
  }
  
  .mdi-file:before {
    content: '\f223';
  }
  
  .mdi-folder-outline:before {
    content: '\f224';
  }
  
  .mdi-folder-person:before {
    content: '\f225';
  }
  
  .mdi-folder-star-alt:before {
    content: '\f226';
  }
  
  .mdi-folder-star:before {
    content: '\f227';
  }
  
  .mdi-folder:before {
    content: '\f228';
  }
  
  .mdi-gif:before {
    content: '\f229';
  }
  
  .mdi-upload:before {
    content: '\f22a';
  }
  
  .mdi-border-all:before {
    content: '\f22b';
  }
  
  .mdi-border-bottom:before {
    content: '\f22c';
  }
  
  .mdi-border-clear:before {
    content: '\f22d';
  }
  
  .mdi-border-color:before {
    content: '\f22e';
  }
  
  .mdi-border-horizontal:before {
    content: '\f22f';
  }
  
  .mdi-border-inner:before {
    content: '\f230';
  }
  
  .mdi-border-left:before {
    content: '\f231';
  }
  
  .mdi-border-outer:before {
    content: '\f232';
  }
  
  .mdi-border-right:before {
    content: '\f233';
  }
  
  .mdi-border-style:before {
    content: '\f234';
  }
  
  .mdi-border-top:before {
    content: '\f235';
  }
  
  .mdi-border-vertical:before {
    content: '\f236';
  }
  
  .mdi-copy:before {
    content: '\f237';
  }
  
  .mdi-crop:before {
    content: '\f238';
  }
  
  .mdi-format-align-center:before {
    content: '\f239';
  }
  
  .mdi-format-align-justify:before {
    content: '\f23a';
  }
  
  .mdi-format-align-left:before {
    content: '\f23b';
  }
  
  .mdi-format-align-right:before {
    content: '\f23c';
  }
  
  .mdi-format-bold:before {
    content: '\f23d';
  }
  
  .mdi-format-clear-all:before {
    content: '\f23e';
  }
  
  .mdi-format-clear:before {
    content: '\f23f';
  }
  
  .mdi-format-color-fill:before {
    content: '\f240';
  }
  
  .mdi-format-color-reset:before {
    content: '\f241';
  }
  
  .mdi-format-color-text:before {
    content: '\f242';
  }
  
  .mdi-format-indent-decrease:before {
    content: '\f243';
  }
  
  .mdi-format-indent-increase:before {
    content: '\f244';
  }
  
  .mdi-format-italic:before {
    content: '\f245';
  }
  
  .mdi-format-line-spacing:before {
    content: '\f246';
  }
  
  .mdi-format-list-bulleted:before {
    content: '\f247';
  }
  
  .mdi-format-list-numbered:before {
    content: '\f248';
  }
  
  .mdi-format-ltr:before {
    content: '\f249';
  }
  
  .mdi-format-rtl:before {
    content: '\f24a';
  }
  
  .mdi-format-size:before {
    content: '\f24b';
  }
  
  .mdi-format-strikethrough-s:before {
    content: '\f24c';
  }
  
  .mdi-format-strikethrough:before {
    content: '\f24d';
  }
  
  .mdi-format-subject:before {
    content: '\f24e';
  }
  
  .mdi-format-underlined:before {
    content: '\f24f';
  }
  
  .mdi-format-valign-bottom:before {
    content: '\f250';
  }
  
  .mdi-format-valign-center:before {
    content: '\f251';
  }
  
  .mdi-format-valign-top:before {
    content: '\f252';
  }
  
  .mdi-redo:before {
    content: '\f253';
  }
  
  .mdi-select-all:before {
    content: '\f254';
  }
  
  .mdi-space-bar:before {
    content: '\f255';
  }
  
  .mdi-text-format:before {
    content: '\f256';
  }
  
  .mdi-transform:before {
    content: '\f257';
  }
  
  .mdi-undo:before {
    content: '\f258';
  }
  
  .mdi-wrap-text:before {
    content: '\f259';
  }
  
  .mdi-comment-alert:before {
    content: '\f25a';
  }
  
  .mdi-comment-alt-text:before {
    content: '\f25b';
  }
  
  .mdi-comment-alt:before {
    content: '\f25c';
  }
  
  .mdi-comment-edit:before {
    content: '\f25d';
  }
  
  .mdi-comment-image:before {
    content: '\f25e';
  }
  
  .mdi-comment-list:before {
    content: '\f25f';
  }
  
  .mdi-comment-more:before {
    content: '\f260';
  }
  
  .mdi-comment-outline:before {
    content: '\f261';
  }
  
  .mdi-comment-text-alt:before {
    content: '\f262';
  }
  
  .mdi-comment-text:before {
    content: '\f263';
  }
  
  .mdi-comment-video:before {
    content: '\f264';
  }
  
  .mdi-comment:before {
    content: '\f265';
  }
  
  .mdi-comments:before {
    content: '\f266';
  }
  
  .mdi-check-all:before {
    content: '\f267';
  }
  
  .mdi-check-circle-u:before {
    content: '\f268';
  }
  
  .mdi-check-circle:before {
    content: '\f269';
  }
  
  .mdi-check-square:before {
    content: '\f26a';
  }
  
  .mdi-check:before {
    content: '\f26b';
  }
  
  .mdi-circle-o:before {
    content: '\f26c';
  }
  
  .mdi-circle:before {
    content: '\f26d';
  }
  
  .mdi-dot-circle-alt:before {
    content: '\f26e';
  }
  
  .mdi-dot-circle:before {
    content: '\f26f';
  }
  
  .mdi-minus-circle-outline:before {
    content: '\f270';
  }
  
  .mdi-minus-circle:before {
    content: '\f271';
  }
  
  .mdi-minus-square:before {
    content: '\f272';
  }
  
  .mdi-minus:before {
    content: '\f273';
  }
  
  .mdi-plus-circle-o-duplicate:before {
    content: '\f274';
  }
  
  .mdi-plus-circle-o:before {
    content: '\f275';
  }
  
  .mdi-plus-circle:before {
    content: '\f276';
  }
  
  .mdi-plus-square:before {
    content: '\f277';
  }
  
  .mdi-plus:before {
    content: '\f278';
  }
  
  .mdi-square-o:before {
    content: '\f279';
  }
  
  .mdi-star-circle:before {
    content: '\f27a';
  }
  
  .mdi-star-half:before {
    content: '\f27b';
  }
  
  .mdi-star-outline:before {
    content: '\f27c';
  }
  
  .mdi-star:before {
    content: '\f27d';
  }
  
  .mdi-bluetooth-connected:before {
    content: '\f27e';
  }
  
  .mdi-bluetooth-off:before {
    content: '\f27f';
  }
  
  .mdi-bluetooth-search:before {
    content: '\f280';
  }
  
  .mdi-bluetooth-setting:before {
    content: '\f281';
  }
  
  .mdi-bluetooth:before {
    content: '\f282';
  }
  
  .mdi-camera-add:before {
    content: '\f283';
  }
  
  .mdi-camera-alt:before {
    content: '\f284';
  }
  
  .mdi-camera-bw:before {
    content: '\f285';
  }
  
  .mdi-camera-front:before {
    content: '\f286';
  }
  
  .mdi-camera-mic:before {
    content: '\f287';
  }
  
  .mdi-camera-party-mode:before {
    content: '\f288';
  }
  
  .mdi-camera-rear:before {
    content: '\f289';
  }
  
  .mdi-camera-roll:before {
    content: '\f28a';
  }
  
  .mdi-camera-switch:before {
    content: '\f28b';
  }
  
  .mdi-camera:before {
    content: '\f28c';
  }
  
  .mdi-card-alert:before {
    content: '\f28d';
  }
  
  .mdi-card-off:before {
    content: '\f28e';
  }
  
  .mdi-card-sd:before {
    content: '\f28f';
  }
  
  .mdi-card-sim:before {
    content: '\f290';
  }
  
  .mdi-desktop-mac:before {
    content: '\f291';
  }
  
  .mdi-desktop-windows:before {
    content: '\f292';
  }
  
  .mdi-device-hub:before {
    content: '\f293';
  }
  
  .mdi-devices-off:before {
    content: '\f294';
  }
  
  .mdi-devices:before {
    content: '\f295';
  }
  
  .mdi-dock:before {
    content: '\f296';
  }
  
  .mdi-floppy:before {
    content: '\f297';
  }
  
  .mdi-gamepad:before {
    content: '\f298';
  }
  
  .mdi-gps-dot:before {
    content: '\f299';
  }
  
  .mdi-gps-off:before {
    content: '\f29a';
  }
  
  .mdi-gps:before {
    content: '\f29b';
  }
  
  .mdi-headset-mic:before {
    content: '\f29c';
  }
  
  .mdi-headset:before {
    content: '\f29d';
  }
  
  .mdi-input-antenna:before {
    content: '\f29e';
  }
  
  .mdi-input-composite:before {
    content: '\f29f';
  }
  
  .mdi-input-hdmi:before {
    content: '\f2a0';
  }
  
  .mdi-input-power:before {
    content: '\f2a1';
  }
  
  .mdi-input-svideo:before {
    content: '\f2a2';
  }
  
  .mdi-keyboard-hide:before {
    content: '\f2a3';
  }
  
  .mdi-keyboard:before {
    content: '\f2a4';
  }
  
  .mdi-laptop-chromebook:before {
    content: '\f2a5';
  }
  
  .mdi-laptop-mac:before {
    content: '\f2a6';
  }
  
  .mdi-laptop:before {
    content: '\f2a7';
  }
  
  .mdi-mic-off:before {
    content: '\f2a8';
  }
  
  .mdi-mic-outline:before {
    content: '\f2a9';
  }
  
  .mdi-mic-setting:before {
    content: '\f2aa';
  }
  
  .mdi-mic:before {
    content: '\f2ab';
  }
  
  .mdi-mouse:before {
    content: '\f2ac';
  }
  
  .mdi-network-alert:before {
    content: '\f2ad';
  }
  
  .mdi-network-locked:before {
    content: '\f2ae';
  }
  
  .mdi-network-off:before {
    content: '\f2af';
  }
  
  .mdi-network-outline:before {
    content: '\f2b0';
  }
  
  .mdi-network-setting:before {
    content: '\f2b1';
  }
  
  .mdi-network:before {
    content: '\f2b2';
  }
  
  .mdi-phone-bluetooth:before {
    content: '\f2b3';
  }
  
  .mdi-phone-end:before {
    content: '\f2b4';
  }
  
  .mdi-phone-forwarded:before {
    content: '\f2b5';
  }
  
  .mdi-phone-in-talk:before {
    content: '\f2b6';
  }
  
  .mdi-phone-locked:before {
    content: '\f2b7';
  }
  
  .mdi-phone-missed:before {
    content: '\f2b8';
  }
  
  .mdi-phone-msg:before {
    content: '\f2b9';
  }
  
  .mdi-phone-paused:before {
    content: '\f2ba';
  }
  
  .mdi-phone-ring:before {
    content: '\f2bb';
  }
  
  .mdi-phone-setting:before {
    content: '\f2bc';
  }
  
  .mdi-phone-sip:before {
    content: '\f2bd';
  }
  
  .mdi-phone:before {
    content: '\f2be';
  }
  
  .mdi-portable-wifi-changes:before {
    content: '\f2bf';
  }
  
  .mdi-portable-wifi-off:before {
    content: '\f2c0';
  }
  
  .mdi-portable-wifi:before {
    content: '\f2c1';
  }
  
  .mdi-radio:before {
    content: '\f2c2';
  }
  
  .mdi-reader:before {
    content: '\f2c3';
  }
  
  .mdi-remote-control-alt:before {
    content: '\f2c4';
  }
  
  .mdi-remote-control:before {
    content: '\f2c5';
  }
  
  .mdi-router:before {
    content: '\f2c6';
  }
  
  .mdi-scanner:before {
    content: '\f2c7';
  }
  
  .mdi-smartphone-android:before {
    content: '\f2c8';
  }
  
  .mdi-smartphone-download:before {
    content: '\f2c9';
  }
  
  .mdi-smartphone-erase:before {
    content: '\f2ca';
  }
  
  .mdi-smartphone-info:before {
    content: '\f2cb';
  }
  
  .mdi-smartphone-iphone:before {
    content: '\f2cc';
  }
  
  .mdi-smartphone-landscape-lock:before {
    content: '\f2cd';
  }
  
  .mdi-smartphone-landscape:before {
    content: '\f2ce';
  }
  
  .mdi-smartphone-lock:before {
    content: '\f2cf';
  }
  
  .mdi-smartphone-portrait-lock:before {
    content: '\f2d0';
  }
  
  .mdi-smartphone-ring:before {
    content: '\f2d1';
  }
  
  .mdi-smartphone-setting:before {
    content: '\f2d2';
  }
  
  .mdi-smartphone-setup:before {
    content: '\f2d3';
  }
  
  .mdi-smartphone:before {
    content: '\f2d4';
  }
  
  .mdi-speaker:before {
    content: '\f2d5';
  }
  
  .mdi-tablet-android:before {
    content: '\f2d6';
  }
  
  .mdi-tablet-mac:before {
    content: '\f2d7';
  }
  
  .mdi-tablet:before {
    content: '\f2d8';
  }
  
  .mdi-tv-alt-play:before {
    content: '\f2d9';
  }
  
  .mdi-tv-list:before {
    content: '\f2da';
  }
  
  .mdi-tv-play:before {
    content: '\f2db';
  }
  
  .mdi-tv:before {
    content: '\f2dc';
  }
  
  .mdi-usb:before {
    content: '\f2dd';
  }
  
  .mdi-videocam-off:before {
    content: '\f2de';
  }
  
  .mdi-videocam-switch:before {
    content: '\f2df';
  }
  
  .mdi-videocam:before {
    content: '\f2e0';
  }
  
  .mdi-watch:before {
    content: '\f2e1';
  }
  
  .mdi-wifi-alt-2:before {
    content: '\f2e2';
  }
  
  .mdi-wifi-alt:before {
    content: '\f2e3';
  }
  
  .mdi-wifi-info:before {
    content: '\f2e4';
  }
  
  .mdi-wifi-lock:before {
    content: '\f2e5';
  }
  
  .mdi-wifi-off:before {
    content: '\f2e6';
  }
  
  .mdi-wifi-outline:before {
    content: '\f2e7';
  }
  
  .mdi-wifi:before {
    content: '\f2e8';
  }
  
  .mdi-arrow-left-bottom:before {
    content: '\f2e9';
  }
  
  .mdi-arrow-left:before {
    content: '\f2ea';
  }
  
  .mdi-arrow-merge:before {
    content: '\f2eb';
  }
  
  .mdi-arrow-missed:before {
    content: '\f2ec';
  }
  
  .mdi-arrow-right-top:before {
    content: '\f2ed';
  }
  
  .mdi-arrow-right:before {
    content: '\f2ee';
  }
  
  .mdi-arrow-split:before {
    content: '\f2ef';
  }
  
  .mdi-arrows:before {
    content: '\f2f0';
  }
  
  .mdi-caret-down-circle:before {
    content: '\f2f1';
  }
  
  .mdi-caret-down:before {
    content: '\f2f2';
  }
  
  .mdi-caret-left-circle:before {
    content: '\f2f3';
  }
  
  .mdi-caret-left:before {
    content: '\f2f4';
  }
  
  .mdi-caret-right-circle:before {
    content: '\f2f5';
  }
  
  .mdi-caret-right:before {
    content: '\f2f6';
  }
  
  .mdi-caret-up-circle:before {
    content: '\f2f7';
  }
  
  .mdi-caret-up:before {
    content: '\f2f8';
  }
  
  .mdi-chevron-down:before {
    content: '\f2f9';
  }
  
  .mdi-chevron-left:before {
    content: '\f2fa';
  }
  
  .mdi-chevron-right:before {
    content: '\f2fb';
  }
  
  .mdi-chevron-up:before {
    content: '\f2fc';
  }
  
  .mdi-forward:before {
    content: '\f2fd';
  }
  
  .mdi-long-arrow-down:before {
    content: '\f2fe';
  }
  
  .mdi-long-arrow-left:before {
    content: '\f2ff';
  }
  
  .mdi-long-arrow-return:before {
    content: '\f300';
  }
  
  .mdi-long-arrow-right:before {
    content: '\f301';
  }
  
  .mdi-long-arrow-tab:before {
    content: '\f302';
  }
  
  .mdi-long-arrow-up:before {
    content: '\f303';
  }
  
  .mdi-rotate-ccw:before {
    content: '\f304';
  }
  
  .mdi-rotate-cw:before {
    content: '\f305';
  }
  
  .mdi-rotate-left:before {
    content: '\f306';
  }
  
  .mdi-rotate-right:before {
    content: '\f307';
  }
  
  .mdi-square-down:before {
    content: '\f308';
  }
  
  .mdi-square-right:before {
    content: '\f309';
  }
  
  .mdi-swap-alt:before {
    content: '\f30a';
  }
  
  .mdi-swap-vertical-circle:before {
    content: '\f30b';
  }
  
  .mdi-swap-vertical:before {
    content: '\f30c';
  }
  
  .mdi-swap:before {
    content: '\f30d';
  }
  
  .mdi-trending-down:before {
    content: '\f30e';
  }
  
  .mdi-trending-flat:before {
    content: '\f30f';
  }
  
  .mdi-trending-up:before {
    content: '\f310';
  }
  
  .mdi-unfold-less:before {
    content: '\f311';
  }
  
  .mdi-unfold-more:before {
    content: '\f312';
  }
  
  .mdi-apps:before {
    content: '\f313';
  }
  
  .mdi-grid-off:before {
    content: '\f314';
  }
  
  .mdi-grid:before {
    content: '\f315';
  }
  
  .mdi-view-agenda:before {
    content: '\f316';
  }
  
  .mdi-view-array:before {
    content: '\f317';
  }
  
  .mdi-view-carousel:before {
    content: '\f318';
  }
  
  .mdi-view-column:before {
    content: '\f319';
  }
  
  .mdi-view-comfy:before {
    content: '\f31a';
  }
  
  .mdi-view-compact:before {
    content: '\f31b';
  }
  
  .mdi-view-dashboard:before {
    content: '\f31c';
  }
  
  .mdi-view-day:before {
    content: '\f31d';
  }
  
  .mdi-view-headline:before {
    content: '\f31e';
  }
  
  .mdi-view-list-alt:before {
    content: '\f31f';
  }
  
  .mdi-view-list:before {
    content: '\f320';
  }
  
  .mdi-view-module:before {
    content: '\f321';
  }
  
  .mdi-view-quilt:before {
    content: '\f322';
  }
  
  .mdi-view-stream:before {
    content: '\f323';
  }
  
  .mdi-view-subtitles:before {
    content: '\f324';
  }
  
  .mdi-view-toc:before {
    content: '\f325';
  }
  
  .mdi-view-web:before {
    content: '\f326';
  }
  
  .mdi-view-week:before {
    content: '\f327';
  }
  
  .mdi-widgets:before {
    content: '\f328';
  }
  
  .mdi-alarm-check:before {
    content: '\f329';
  }
  
  .mdi-alarm-off:before {
    content: '\f32a';
  }
  
  .mdi-alarm-plus:before {
    content: '\f32b';
  }
  
  .mdi-alarm-snooze:before {
    content: '\f32c';
  }
  
  .mdi-alarm:before {
    content: '\f32d';
  }
  
  .mdi-calendar-alt:before {
    content: '\f32e';
  }
  
  .mdi-calendar-check:before {
    content: '\f32f';
  }
  
  .mdi-calendar-close:before {
    content: '\f330';
  }
  
  .mdi-calendar-note:before {
    content: '\f331';
  }
  
  .mdi-calendar:before {
    content: '\f332';
  }
  
  .mdi-time-countdown:before {
    content: '\f333';
  }
  
  .mdi-time-interval:before {
    content: '\f334';
  }
  
  .mdi-time-restore-setting:before {
    content: '\f335';
  }
  
  .mdi-time-restore:before {
    content: '\f336';
  }
  
  .mdi-time:before {
    content: '\f337';
  }
  
  .mdi-timer-off:before {
    content: '\f338';
  }
  
  .mdi-timer:before {
    content: '\f339';
  }
  
  .mdi-android-alt:before {
    content: '\f33a';
  }
  
  .mdi-android:before {
    content: '\f33b';
  }
  
  .mdi-apple:before {
    content: '\f33c';
  }
  
  .mdi-behance:before {
    content: '\f33d';
  }
  
  .mdi-codepen:before {
    content: '\f33e';
  }
  
  .mdi-dribbble:before {
    content: '\f33f';
  }
  
  .mdi-dropbox:before {
    content: '\f340';
  }
  
  .mdi-evernote:before {
    content: '\f341';
  }
  
  .mdi-facebook-box:before {
    content: '\f342';
  }
  
  .mdi-facebook:before {
    content: '\f343';
  }
  
  .mdi-github-box:before {
    content: '\f344';
  }
  
  .mdi-github:before {
    content: '\f345';
  }
  
  .mdi-google-drive:before {
    content: '\f346';
  }
  
  .mdi-google-earth:before {
    content: '\f347';
  }
  
  .mdi-google-glass:before {
    content: '\f348';
  }
  
  .mdi-google-maps:before {
    content: '\f349';
  }
  
  .mdi-google-pages:before {
    content: '\f34a';
  }
  
  .mdi-google-play:before {
    content: '\f34b';
  }
  
  .mdi-google-plus-box:before {
    content: '\f34c';
  }
  
  .mdi-google-plus:before {
    content: '\f34d';
  }
  
  .mdi-google:before {
    content: '\f34e';
  }
  
  .mdi-instagram:before {
    content: '\f34f';
  }
  
  .mdi-language-css3:before {
    content: '\f350';
  }
  
  .mdi-language-html5:before {
    content: '\f351';
  }
  
  .mdi-language-javascript:before {
    content: '\f352';
  }
  
  .mdi-language-python-alt:before {
    content: '\f353';
  }
  
  .mdi-language-python:before {
    content: '\f354';
  }
  
  .mdi-lastfm:before {
    content: '\f355';
  }
  
  .mdi-linkedin-box:before {
    content: '\f356';
  }
  
  .mdi-paypal:before {
    content: '\f357';
  }
  
  .mdi-pinterest-box:before {
    content: '\f358';
  }
  
  .mdi-pocket:before {
    content: '\f359';
  }
  
  .mdi-polymer:before {
    content: '\f35a';
  }
  
  .mdi-share:before {
    content: '\f35b';
  }
  
  .mdi-stackoverflow:before {
    content: '\f35c';
  }
  
  .mdi-steam-square:before {
    content: '\f35d';
  }
  
  .mdi-steam:before {
    content: '\f35e';
  }
  
  .mdi-twitter-box:before {
    content: '\f35f';
  }
  
  .mdi-twitter:before {
    content: '\f360';
  }
  
  .mdi-vk:before {
    content: '\f361';
  }
  
  .mdi-wikipedia:before {
    content: '\f362';
  }
  
  .mdi-windows:before {
    content: '\f363';
  }
  
  .mdi-aspect-ratio-alt:before {
    content: '\f364';
  }
  
  .mdi-aspect-ratio:before {
    content: '\f365';
  }
  
  .mdi-blur-circular:before {
    content: '\f366';
  }
  
  .mdi-blur-linear:before {
    content: '\f367';
  }
  
  .mdi-blur-off:before {
    content: '\f368';
  }
  
  .mdi-blur:before {
    content: '\f369';
  }
  
  .mdi-brightness-2:before {
    content: '\f36a';
  }
  
  .mdi-brightness-3:before {
    content: '\f36b';
  }
  
  .mdi-brightness-4:before {
    content: '\f36c';
  }
  
  .mdi-brightness-5:before {
    content: '\f36d';
  }
  
  .mdi-brightness-6:before {
    content: '\f36e';
  }
  
  .mdi-brightness-7:before {
    content: '\f36f';
  }
  
  .mdi-brightness-auto:before {
    content: '\f370';
  }
  
  .mdi-brightness-setting:before {
    content: '\f371';
  }
  
  .mdi-broken-image:before {
    content: '\f372';
  }
  
  .mdi-center-focus-strong:before {
    content: '\f373';
  }
  
  .mdi-center-focus-weak:before {
    content: '\f374';
  }
  
  .mdi-compare:before {
    content: '\f375';
  }
  
  .mdi-crop-16-9:before {
    content: '\f376';
  }
  
  .mdi-crop-3-2:before {
    content: '\f377';
  }
  
  .mdi-crop-5-4:before {
    content: '\f378';
  }
  
  .mdi-crop-7-5:before {
    content: '\f379';
  }
  
  .mdi-crop-din:before {
    content: '\f37a';
  }
  
  .mdi-crop-free:before {
    content: '\f37b';
  }
  
  .mdi-crop-landscape:before {
    content: '\f37c';
  }
  
  .mdi-crop-portrait:before {
    content: '\f37d';
  }
  
  .mdi-crop-square:before {
    content: '\f37e';
  }
  
  .mdi-exposure-alt:before {
    content: '\f37f';
  }
  
  .mdi-exposure:before {
    content: '\f380';
  }
  
  .mdi-filter-b-and-w:before {
    content: '\f381';
  }
  
  .mdi-filter-center-focus:before {
    content: '\f382';
  }
  
  .mdi-filter-frames:before {
    content: '\f383';
  }
  
  .mdi-filter-tilt-shift:before {
    content: '\f384';
  }
  
  .mdi-gradient:before {
    content: '\f385';
  }
  
  .mdi-grain:before {
    content: '\f386';
  }
  
  .mdi-graphic-eq:before {
    content: '\f387';
  }
  
  .mdi-hdr-off:before {
    content: '\f388';
  }
  
  .mdi-hdr-strong:before {
    content: '\f389';
  }
  
  .mdi-hdr-weak:before {
    content: '\f38a';
  }
  
  .mdi-hdr:before {
    content: '\f38b';
  }
  
  .mdi-iridescent:before {
    content: '\f38c';
  }
  
  .mdi-leak-off:before {
    content: '\f38d';
  }
  
  .mdi-leak:before {
    content: '\f38e';
  }
  
  .mdi-looks:before {
    content: '\f38f';
  }
  
  .mdi-loupe:before {
    content: '\f390';
  }
  
  .mdi-panorama-horizontal:before {
    content: '\f391';
  }
  
  .mdi-panorama-vertical:before {
    content: '\f392';
  }
  
  .mdi-panorama-wide-angle:before {
    content: '\f393';
  }
  
  .mdi-photo-size-select-large:before {
    content: '\f394';
  }
  
  .mdi-photo-size-select-small:before {
    content: '\f395';
  }
  
  .mdi-picture-in-picture:before {
    content: '\f396';
  }
  
  .mdi-slideshow:before {
    content: '\f397';
  }
  
  .mdi-texture:before {
    content: '\f398';
  }
  
  .mdi-tonality:before {
    content: '\f399';
  }
  
  .mdi-vignette:before {
    content: '\f39a';
  }
  
  .mdi-wb-auto:before {
    content: '\f39b';
  }
  
  .mdi-eject-alt:before {
    content: '\f39c';
  }
  
  .mdi-eject:before {
    content: '\f39d';
  }
  
  .mdi-equalizer:before {
    content: '\f39e';
  }
  
  .mdi-fast-forward:before {
    content: '\f39f';
  }
  
  .mdi-fast-rewind:before {
    content: '\f3a0';
  }
  
  .mdi-forward-10:before {
    content: '\f3a1';
  }
  
  .mdi-forward-30:before {
    content: '\f3a2';
  }
  
  .mdi-forward-5:before {
    content: '\f3a3';
  }
  
  .mdi-hearing:before {
    content: '\f3a4';
  }
  
  .mdi-pause-circle-outline:before {
    content: '\f3a5';
  }
  
  .mdi-pause-circle:before {
    content: '\f3a6';
  }
  
  .mdi-pause:before {
    content: '\f3a7';
  }
  
  .mdi-play-circle-outline:before {
    content: '\f3a8';
  }
  
  .mdi-play-circle:before {
    content: '\f3a9';
  }
  
  .mdi-play:before {
    content: '\f3aa';
  }
  
  .mdi-playlist-audio:before {
    content: '\f3ab';
  }
  
  .mdi-playlist-plus:before {
    content: '\f3ac';
  }
  
  .mdi-repeat-one:before {
    content: '\f3ad';
  }
  
  .mdi-repeat:before {
    content: '\f3ae';
  }
  
  .mdi-replay-10:before {
    content: '\f3af';
  }
  
  .mdi-replay-30:before {
    content: '\f3b0';
  }
  
  .mdi-replay-5:before {
    content: '\f3b1';
  }
  
  .mdi-replay:before {
    content: '\f3b2';
  }
  
  .mdi-shuffle:before {
    content: '\f3b3';
  }
  
  .mdi-skip-next:before {
    content: '\f3b4';
  }
  
  .mdi-skip-previous:before {
    content: '\f3b5';
  }
  
  .mdi-stop:before {
    content: '\f3b6';
  }
  
  .mdi-surround-sound:before {
    content: '\f3b7';
  }
  
  .mdi-tune:before {
    content: '\f3b8';
  }
  
  .mdi-volume-down:before {
    content: '\f3b9';
  }
  
  .mdi-volume-mute:before {
    content: '\f3ba';
  }
  
  .mdi-volume-off:before {
    content: '\f3bb';
  }
  
  .mdi-volume-up:before {
    content: '\f3bc';
  }
  
  .mdi-n-1-square:before {
    content: '\f3bd';
  }
  
  .mdi-n-2-square:before {
    content: '\f3be';
  }
  
  .mdi-n-3-square:before {
    content: '\f3bf';
  }
  
  .mdi-n-4-square:before {
    content: '\f3c0';
  }
  
  .mdi-n-5-square:before {
    content: '\f3c1';
  }
  
  .mdi-n-6-square:before {
    content: '\f3c2';
  }
  
  .mdi-neg-1:before {
    content: '\f3c3';
  }
  
  .mdi-neg-2:before {
    content: '\f3c4';
  }
  
  .mdi-plus-1:before {
    content: '\f3c5';
  }
  
  .mdi-plus-2:before {
    content: '\f3c6';
  }
  
  .mdi-sec-10:before {
    content: '\f3c7';
  }
  
  .mdi-sec-3:before {
    content: '\f3c8';
  }
  
  .mdi-zero:before {
    content: '\f3c9';
  }
  
  .mdi-airline-seat-flat-angled:before {
    content: '\f3ca';
  }
  
  .mdi-airline-seat-flat:before {
    content: '\f3cb';
  }
  
  .mdi-airline-seat-individual-suite:before {
    content: '\f3cc';
  }
  
  .mdi-airline-seat-legroom-extra:before {
    content: '\f3cd';
  }
  
  .mdi-airline-seat-legroom-normal:before {
    content: '\f3ce';
  }
  
  .mdi-airline-seat-legroom-reduced:before {
    content: '\f3cf';
  }
  
  .mdi-airline-seat-recline-extra:before {
    content: '\f3d0';
  }
  
  .mdi-airline-seat-recline-normal:before {
    content: '\f3d1';
  }
  
  .mdi-airplay:before {
    content: '\f3d2';
  }
  
  .mdi-closed-caption:before {
    content: '\f3d3';
  }
  
  .mdi-confirmation-number:before {
    content: '\f3d4';
  }
  
  .mdi-developer-board:before {
    content: '\f3d5';
  }
  
  .mdi-disc-full:before {
    content: '\f3d6';
  }
  
  .mdi-explicit:before {
    content: '\f3d7';
  }
  
  .mdi-flight-land:before {
    content: '\f3d8';
  }
  
  .mdi-flight-takeoff:before {
    content: '\f3d9';
  }
  
  .mdi-flip-to-back:before {
    content: '\f3da';
  }
  
  .mdi-flip-to-front:before {
    content: '\f3db';
  }
  
  .mdi-group-work:before {
    content: '\f3dc';
  }
  
  .mdi-hd:before {
    content: '\f3dd';
  }
  
  .mdi-hq:before {
    content: '\f3de';
  }
  
  .mdi-markunread-mailbox:before {
    content: '\f3df';
  }
  
  .mdi-memory:before {
    content: '\f3e0';
  }
  
  .mdi-nfc:before {
    content: '\f3e1';
  }
  
  .mdi-play-for-work:before {
    content: '\f3e2';
  }
  
  .mdi-power-input:before {
    content: '\f3e3';
  }
  
  .mdi-present-to-all:before {
    content: '\f3e4';
  }
  
  .mdi-satellite:before {
    content: '\f3e5';
  }
  
  .mdi-tap-and-play:before {
    content: '\f3e6';
  }
  
  .mdi-vibration:before {
    content: '\f3e7';
  }
  
  .mdi-voicemail:before {
    content: '\f3e8';
  }
  
  .mdi-group:before {
    content: '\f3e9';
  }
  
  .mdi-rss:before {
    content: '\f3ea';
  }
  
  .mdi-shape:before {
    content: '\f3eb';
  }
  
  .mdi-spinner:before {
    content: '\f3ec';
  }
  
  .mdi-ungroup:before {
    content: '\f3ed';
  }
  
  .mdi-500px:before {
    content: '\f3ee';
  }
  
  .mdi-8tracks:before {
    content: '\f3ef';
  }
  
  .mdi-amazon:before {
    content: '\f3f0';
  }
  
  .mdi-blogger:before {
    content: '\f3f1';
  }
  
  .mdi-delicious:before {
    content: '\f3f2';
  }
  
  .mdi-disqus:before {
    content: '\f3f3';
  }
  
  .mdi-flattr:before {
    content: '\f3f4';
  }
  
  .mdi-flickr:before {
    content: '\f3f5';
  }
  
  .mdi-github-alt:before {
    content: '\f3f6';
  }
  
  .mdi-google-old:before {
    content: '\f3f7';
  }
  
  .mdi-linkedin:before {
    content: '\f3f8';
  }
  
  .mdi-odnoklassniki:before {
    content: '\f3f9';
  }
  
  .mdi-outlook:before {
    content: '\f3fa';
  }
  
  .mdi-paypal-alt:before {
    content: '\f3fb';
  }
  
  .mdi-pinterest:before {
    content: '\f3fc';
  }
  
  .mdi-playstation:before {
    content: '\f3fd';
  }
  
  .mdi-reddit:before {
    content: '\f3fe';
  }
  
  .mdi-skype:before {
    content: '\f3ff';
  }
  
  .mdi-slideshare:before {
    content: '\f400';
  }
  
  .mdi-soundcloud:before {
    content: '\f401';
  }
  
  .mdi-tumblr:before {
    content: '\f402';
  }
  
  .mdi-twitch:before {
    content: '\f403';
  }
  
  .mdi-vimeo:before {
    content: '\f404';
  }
  
  .mdi-whatsapp:before {
    content: '\f405';
  }
  
  .mdi-xbox:before {
    content: '\f406';
  }
  
  .mdi-yahoo:before {
    content: '\f407';
  }
  
  .mdi-youtube-play:before {
    content: '\f408';
  }
  
  .mdi-youtube:before {
    content: '\f409';
  }
  
  .mdi-3d-rotation:before {
    content: '\f101';
  }
  
  .mdi-airplane-off:before {
    content: '\f102';
  }
  
  .mdi-airplane:before {
    content: '\f103';
  }
  
  .mdi-album:before {
    content: '\f104';
  }
  
  .mdi-archive:before {
    content: '\f105';
  }
  
  .mdi-assignment-account:before {
    content: '\f106';
  }
  
  .mdi-assignment-alert:before {
    content: '\f107';
  }
  
  .mdi-assignment-check:before {
    content: '\f108';
  }
  
  .mdi-assignment-o:before {
    content: '\f109';
  }
  
  .mdi-assignment-return:before {
    content: '\f10a';
  }
  
  .mdi-assignment-returned:before {
    content: '\f10b';
  }
  
  .mdi-assignment:before {
    content: '\f10c';
  }
  
  .mdi-attachment-alt:before {
    content: '\f10d';
  }
  
  .mdi-attachment:before {
    content: '\f10e';
  }
  
  .mdi-audio:before {
    content: '\f10f';
  }
  
  .mdi-badge-check:before {
    content: '\f110';
  }
  
  .mdi-balance-wallet:before {
    content: '\f111';
  }
  
  .mdi-balance:before {
    content: '\f112';
  }
  
  .mdi-battery-alert:before {
    content: '\f113';
  }
  
  .mdi-battery-flash:before {
    content: '\f114';
  }
  
  .mdi-battery-unknown:before {
    content: '\f115';
  }
  
  .mdi-battery:before {
    content: '\f116';
  }
  
  .mdi-bike:before {
    content: '\f117';
  }
  
  .mdi-block-alt:before {
    content: '\f118';
  }
  
  .mdi-block:before {
    content: '\f119';
  }
  
  .mdi-boat:before {
    content: '\f11a';
  }
  
  .mdi-book-image:before {
    content: '\f11b';
  }
  
  .mdi-book:before {
    content: '\f11c';
  }
  
  .mdi-bookmark-outline:before {
    content: '\f11d';
  }
  
  .mdi-bookmark:before {
    content: '\f11e';
  }
  
  .mdi-brush:before {
    content: '\f11f';
  }
  
  .mdi-bug:before {
    content: '\f120';
  }
  
  .mdi-bus:before {
    content: '\f121';
  }
  
  .mdi-cake:before {
    content: '\f122';
  }
  
  .mdi-car-taxi:before {
    content: '\f123';
  }
  
  .mdi-car-wash:before {
    content: '\f124';
  }
  
  .mdi-car:before {
    content: '\f125';
  }
  
  .mdi-card-giftcard:before {
    content: '\f126';
  }
  
  .mdi-card-membership:before {
    content: '\f127';
  }
  
  .mdi-card-travel:before {
    content: '\f128';
  }
  
  .mdi-card:before {
    content: '\f129';
  }
  
  .mdi-case-check:before {
    content: '\f12a';
  }
  
  .mdi-case-download:before {
    content: '\f12b';
  }
  
  .mdi-case-play:before {
    content: '\f12c';
  }
  
  .mdi-case:before {
    content: '\f12d';
  }
  
  .mdi-cast-connected:before {
    content: '\f12e';
  }
  
  .mdi-cast:before {
    content: '\f12f';
  }
  
  .mdi-chart-donut:before {
    content: '\f130';
  }
  
  .mdi-chart:before {
    content: '\f131';
  }
  
  .mdi-city-alt:before {
    content: '\f132';
  }
  
  .mdi-city:before {
    content: '\f133';
  }
  
  .mdi-close-circle-o:before {
    content: '\f134';
  }
  
  .mdi-close-circle:before {
    content: '\f135';
  }
  
  .mdi-close:before {
    content: '\f136';
  }
  
  .mdi-cocktail:before {
    content: '\f137';
  }
  
  .mdi-code-setting:before {
    content: '\f138';
  }
  
  .mdi-code-smartphone:before {
    content: '\f139';
  }
  
  .mdi-code:before {
    content: '\f13a';
  }
  
  .mdi-coffee:before {
    content: '\f13b';
  }
  
  .mdi-collection-bookmark:before {
    content: '\f13c';
  }
  
  .mdi-collection-case-play:before {
    content: '\f13d';
  }
  
  .mdi-collection-folder-image:before {
    content: '\f13e';
  }
  
  .mdi-collection-image-o:before {
    content: '\f13f';
  }
  
  .mdi-collection-image:before {
    content: '\f140';
  }
  
  .mdi-collection-item-1:before {
    content: '\f141';
  }
  
  .mdi-collection-item-2:before {
    content: '\f142';
  }
  
  .mdi-collection-item-3:before {
    content: '\f143';
  }
  
  .mdi-collection-item-4:before {
    content: '\f144';
  }
  
  .mdi-collection-item-5:before {
    content: '\f145';
  }
  
  .mdi-collection-item-6:before {
    content: '\f146';
  }
  
  .mdi-collection-item-7:before {
    content: '\f147';
  }
  
  .mdi-collection-item-8:before {
    content: '\f148';
  }
  
  .mdi-collection-item-9-plus:before {
    content: '\f149';
  }
  
  .mdi-collection-item-9:before {
    content: '\f14a';
  }
  
  .mdi-collection-item:before {
    content: '\f14b';
  }
  
  .mdi-collection-music:before {
    content: '\f14c';
  }
  
  .mdi-collection-pdf:before {
    content: '\f14d';
  }
  
  .mdi-collection-plus:before {
    content: '\f14e';
  }
  
  .mdi-collection-speaker:before {
    content: '\f14f';
  }
  
  .mdi-collection-text:before {
    content: '\f150';
  }
  
  .mdi-collection-video:before {
    content: '\f151';
  }
  
  .mdi-compass:before {
    content: '\f152';
  }
  
  .mdi-cutlery:before {
    content: '\f153';
  }
  
  .mdi-delete:before {
    content: '\f154';
  }
  
  .mdi-dialpad:before {
    content: '\f155';
  }
  
  .mdi-dns:before {
    content: '\f156';
  }
  
  .mdi-drink:before {
    content: '\f157';
  }
  
  .mdi-edit:before {
    content: '\f158';
  }
  
  .mdi-email-open:before {
    content: '\f159';
  }
  
  .mdi-email:before {
    content: '\f15a';
  }
  
  .mdi-eye-off:before {
    content: '\f15b';
  }
  
  .mdi-eye:before {
    content: '\f15c';
  }
  
  .mdi-eyedropper:before {
    content: '\f15d';
  }
  
  .mdi-favorite-outline:before {
    content: '\f15e';
  }
  
  .mdi-favorite:before {
    content: '\f15f';
  }
  
  .mdi-filter-list:before {
    content: '\f160';
  }
  
  .mdi-fire:before {
    content: '\f161';
  }
  
  .mdi-flag:before {
    content: '\f162';
  }
  
  .mdi-flare:before {
    content: '\f163';
  }
  
  .mdi-flash-auto:before {
    content: '\f164';
  }
  
  .mdi-flash-off:before {
    content: '\f165';
  }
  
  .mdi-flash:before {
    content: '\f166';
  }
  
  .mdi-flip:before {
    content: '\f167';
  }
  
  .mdi-flower-alt:before {
    content: '\f168';
  }
  
  .mdi-flower:before {
    content: '\f169';
  }
  
  .mdi-font:before {
    content: '\f16a';
  }
  
  .mdi-fullscreen-alt:before {
    content: '\f16b';
  }
  
  .mdi-fullscreen-exit:before {
    content: '\f16c';
  }
  
  .mdi-fullscreen:before {
    content: '\f16d';
  }
  
  .mdi-functions:before {
    content: '\f16e';
  }
  
  .mdi-gas-station:before {
    content: '\f16f';
  }
  
  .mdi-gesture:before {
    content: '\f170';
  }
  
  .mdi-globe-alt:before {
    content: '\f171';
  }
  
  .mdi-globe-lock:before {
    content: '\f172';
  }
  
  .mdi-globe:before {
    content: '\f173';
  }
  
  .mdi-graduation-cap:before {
    content: '\f174';
  }
  
  .mdi-home:before {
    content: '\f175';
  }
  
  .mdi-hospital-alt:before {
    content: '\f176';
  }
  
  .mdi-hospital:before {
    content: '\f177';
  }
  
  .mdi-hotel:before {
    content: '\f178';
  }
  
  .mdi-hourglass-alt:before {
    content: '\f179';
  }
  
  .mdi-hourglass-outline:before {
    content: '\f17a';
  }
  
  .mdi-hourglass:before {
    content: '\f17b';
  }
  
  .mdi-http:before {
    content: '\f17c';
  }
  
  .mdi-image-alt:before {
    content: '\f17d';
  }
  
  .mdi-image-o:before {
    content: '\f17e';
  }
  
  .mdi-image:before {
    content: '\f17f';
  }
  
  .mdi-inbox:before {
    content: '\f180';
  }
  
  .mdi-invert-colors-off:before {
    content: '\f181';
  }
  
  .mdi-invert-colors:before {
    content: '\f182';
  }
  
  .mdi-key:before {
    content: '\f183';
  }
  
  .mdi-label-alt-outline:before {
    content: '\f184';
  }
  
  .mdi-label-alt:before {
    content: '\f185';
  }
  
  .mdi-label-heart:before {
    content: '\f186';
  }
  
  .mdi-label:before {
    content: '\f187';
  }
  
  .mdi-labels:before {
    content: '\f188';
  }
  
  .mdi-lamp:before {
    content: '\f189';
  }
  
  .mdi-landscape:before {
    content: '\f18a';
  }
  
  .mdi-layers-off:before {
    content: '\f18b';
  }
  
  .mdi-layers:before {
    content: '\f18c';
  }
  
  .mdi-library:before {
    content: '\f18d';
  }
  
  .mdi-link:before {
    content: '\f18e';
  }
  
  .mdi-lock-open:before {
    content: '\f18f';
  }
  
  .mdi-lock-outline:before {
    content: '\f190';
  }
  
  .mdi-lock:before {
    content: '\f191';
  }
  
  .mdi-mail-reply-all:before {
    content: '\f192';
  }
  
  .mdi-mail-reply:before {
    content: '\f193';
  }
  
  .mdi-mail-send:before {
    content: '\f194';
  }
  
  .mdi-mall:before {
    content: '\f195';
  }
  
  .mdi-map:before {
    content: '\f196';
  }
  
  .mdi-menu:before {
    content: '\f197';
  }
  
  .mdi-money-box:before {
    content: '\f198';
  }
  
  .mdi-money-off:before {
    content: '\f199';
  }
  
  .mdi-money:before {
    content: '\f19a';
  }
  
  .mdi-more-vert:before {
    content: '\f19b';
  }
  
  .mdi-more:before {
    content: '\f19c';
  }
  
  .mdi-movie-alt:before {
    content: '\f19d';
  }
  
  .mdi-movie:before {
    content: '\f19e';
  }
  
  .mdi-nature-people:before {
    content: '\f19f';
  }
  
  .mdi-nature:before {
    content: '\f1a0';
  }
  
  .mdi-navigation:before {
    content: '\f1a1';
  }
  
  .mdi-open-in-browser:before {
    content: '\f1a2';
  }
  
  .mdi-open-in-new:before {
    content: '\f1a3';
  }
  
  .mdi-palette:before {
    content: '\f1a4';
  }
  
  .mdi-parking:before {
    content: '\f1a5';
  }
  
  .mdi-pin-account:before {
    content: '\f1a6';
  }
  
  .mdi-pin-assistant:before {
    content: '\f1a7';
  }
  
  .mdi-pin-drop:before {
    content: '\f1a8';
  }
  
  .mdi-pin-help:before {
    content: '\f1a9';
  }
  
  .mdi-pin-off:before {
    content: '\f1aa';
  }
  
  .mdi-pin:before {
    content: '\f1ab';
  }
  
  .mdi-pizza:before {
    content: '\f1ac';
  }
  
  .mdi-plaster:before {
    content: '\f1ad';
  }
  
  .mdi-power-setting:before {
    content: '\f1ae';
  }
  
  .mdi-power:before {
    content: '\f1af';
  }
  
  .mdi-print:before {
    content: '\f1b0';
  }
  
  .mdi-puzzle-piece:before {
    content: '\f1b1';
  }
  
  .mdi-quote:before {
    content: '\f1b2';
  }
  
  .mdi-railway:before {
    content: '\f1b3';
  }
  
  .mdi-receipt:before {
    content: '\f1b4';
  }
  
  .mdi-refresh-alt:before {
    content: '\f1b5';
  }
  
  .mdi-refresh-sync-alert:before {
    content: '\f1b6';
  }
  
  .mdi-refresh-sync-off:before {
    content: '\f1b7';
  }
  
  .mdi-refresh-sync:before {
    content: '\f1b8';
  }
  
  .mdi-refresh:before {
    content: '\f1b9';
  }
  
  .mdi-roller:before {
    content: '\f1ba';
  }
  
  .mdi-ruler:before {
    content: '\f1bb';
  }
  
  .mdi-scissors:before {
    content: '\f1bc';
  }
  
  .mdi-screen-rotation-lock:before {
    content: '\f1bd';
  }
  
  .mdi-screen-rotation:before {
    content: '\f1be';
  }
  
  .mdi-search-for:before {
    content: '\f1bf';
  }
  
  .mdi-search-in-file:before {
    content: '\f1c0';
  }
  
  .mdi-search-in-page:before {
    content: '\f1c1';
  }
  
  .mdi-search-replace:before {
    content: '\f1c2';
  }
  
  .mdi-search:before {
    content: '\f1c3';
  }
  
  .mdi-seat:before {
    content: '\f1c4';
  }
  
  .mdi-settings-square:before {
    content: '\f1c5';
  }
  
  .mdi-settings:before {
    content: '\f1c6';
  }
  
  .mdi-shield-check:before {
    content: '\f1c7';
  }
  
  .mdi-shield-security:before {
    content: '\f1c8';
  }
  
  .mdi-shopping-basket:before {
    content: '\f1c9';
  }
  
  .mdi-shopping-cart-plus:before {
    content: '\f1ca';
  }
  
  .mdi-shopping-cart:before {
    content: '\f1cb';
  }
  
  .mdi-sign-in:before {
    content: '\f1cc';
  }
  
  .mdi-sort-amount-asc:before {
    content: '\f1cd';
  }
  
  .mdi-sort-amount-desc:before {
    content: '\f1ce';
  }
  
  .mdi-sort-asc:before {
    content: '\f1cf';
  }
  
  .mdi-sort-desc:before {
    content: '\f1d0';
  }
  
  .mdi-spellcheck:before {
    content: '\f1d1';
  }
  
  .mdi-storage:before {
    content: '\f1d2';
  }
  
  .mdi-store-24:before {
    content: '\f1d3';
  }
  
  .mdi-store:before {
    content: '\f1d4';
  }
  
  .mdi-subway:before {
    content: '\f1d5';
  }
  
  .mdi-sun:before {
    content: '\f1d6';
  }
  
  .mdi-tab-unselected:before {
    content: '\f1d7';
  }
  
  .mdi-tab:before {
    content: '\f1d8';
  }
  
  .mdi-tag-close:before {
    content: '\f1d9';
  }
  
  .mdi-tag-more:before {
    content: '\f1da';
  }
  
  .mdi-tag:before {
    content: '\f1db';
  }
  
  .mdi-thumb-down:before {
    content: '\f1dc';
  }
  
  .mdi-thumb-up-down:before {
    content: '\f1dd';
  }
  
  .mdi-thumb-up:before {
    content: '\f1de';
  }
  
  .mdi-ticket-star:before {
    content: '\f1df';
  }
  
  .mdi-toll:before {
    content: '\f1e0';
  }
  
  .mdi-toys:before {
    content: '\f1e1';
  }
  
  .mdi-traffic:before {
    content: '\f1e2';
  }
  
  .mdi-translate:before {
    content: '\f1e3';
  }
  
  .mdi-triangle-down:before {
    content: '\f1e4';
  }
  
  .mdi-triangle-up:before {
    content: '\f1e5';
  }
  
  .mdi-truck:before {
    content: '\f1e6';
  }
  
  .mdi-turning-sign:before {
    content: '\f1e7';
  }
  
  .mdi-wallpaper:before {
    content: '\f1e8';
  }
  
  .mdi-washing-machine:before {
    content: '\f1e9';
  }
  
  .mdi-window-maximize:before {
    content: '\f1ea';
  }
  
  .mdi-window-minimize:before {
    content: '\f1eb';
  }
  
  .mdi-window-restore:before {
    content: '\f1ec';
  }
  
  .mdi-wrench:before {
    content: '\f1ed';
  }
  
  .mdi-zoom-in:before {
    content: '\f1ee';
  }
  
  .mdi-zoom-out:before {
    content: '\f1ef';
  }
  
  .mdi-alert-circle-o:before {
    content: '\f1f0';
  }
  
  .mdi-alert-circle:before {
    content: '\f1f1';
  }
  
  .mdi-alert-octagon:before {
    content: '\f1f2';
  }
  
  .mdi-alert-polygon:before {
    content: '\f1f3';
  }
  
  .mdi-alert-triangle:before {
    content: '\f1f4';
  }
  
  .mdi-help-outline:before {
    content: '\f1f5';
  }
  
  .mdi-help:before {
    content: '\f1f6';
  }
  
  .mdi-info-outline:before {
    content: '\f1f7';
  }
  
  .mdi-info:before {
    content: '\f1f8';
  }
  
  .mdi-notifications-active:before {
    content: '\f1f9';
  }
  
  .mdi-notifications-add:before {
    content: '\f1fa';
  }
  
  .mdi-notifications-none:before {
    content: '\f1fb';
  }
  
  .mdi-notifications-off:before {
    content: '\f1fc';
  }
  
  .mdi-notifications-paused:before {
    content: '\f1fd';
  }
  
  .mdi-notifications:before {
    content: '\f1fe';
  }
  
  .mdi-account-add:before {
    content: '\f1ff';
  }
  
  .mdi-account-box-mail:before {
    content: '\f200';
  }
  
  .mdi-account-box-o:before {
    content: '\f201';
  }
  
  .mdi-account-box-phone:before {
    content: '\f202';
  }
  
  .mdi-account-box:before {
    content: '\f203';
  }
  
  .mdi-account-calendar:before {
    content: '\f204';
  }
  
  .mdi-account-circle:before {
    content: '\f205';
  }
  
  .mdi-account-o:before {
    content: '\f206';
  }
  
  .mdi-account:before {
    content: '\f207';
  }
  
  .mdi-accounts-add:before {
    content: '\f208';
  }
  
  .mdi-accounts-alt:before {
    content: '\f209';
  }
  
  .mdi-accounts-list-alt:before {
    content: '\f20a';
  }
  
  .mdi-accounts-list:before {
    content: '\f20b';
  }
  
  .mdi-accounts-outline:before {
    content: '\f20c';
  }
  
  .mdi-accounts:before {
    content: '\f20d';
  }
  
  .mdi-face:before {
    content: '\f20e';
  }
  
  .mdi-female:before {
    content: '\f20f';
  }
  
  .mdi-male-alt:before {
    content: '\f210';
  }
  
  .mdi-male-female:before {
    content: '\f211';
  }
  
  .mdi-male:before {
    content: '\f212';
  }
  
  .mdi-mood-bad:before {
    content: '\f213';
  }
  
  .mdi-mood:before {
    content: '\f214';
  }
  
  .mdi-run:before {
    content: '\f215';
  }
  
  .mdi-walk:before {
    content: '\f216';
  }
  
  .mdi-cloud-box:before {
    content: '\f217';
  }
  
  .mdi-cloud-circle:before {
    content: '\f218';
  }
  
  .mdi-cloud-done:before {
    content: '\f219';
  }
  
  .mdi-cloud-download:before {
    content: '\f21a';
  }
  
  .mdi-cloud-off:before {
    content: '\f21b';
  }
  
  .mdi-cloud-outline-alt:before {
    content: '\f21c';
  }
  
  .mdi-cloud-outline:before {
    content: '\f21d';
  }
  
  .mdi-cloud-upload:before {
    content: '\f21e';
  }
  
  .mdi-cloud:before {
    content: '\f21f';
  }
  
  .mdi-download:before {
    content: '\f220';
  }
  
  .mdi-file-plus:before {
    content: '\f221';
  }
  
  .mdi-file-text:before {
    content: '\f222';
  }
  
  .mdi-file:before {
    content: '\f223';
  }
  
  .mdi-folder-outline:before {
    content: '\f224';
  }
  
  .mdi-folder-person:before {
    content: '\f225';
  }
  
  .mdi-folder-star-alt:before {
    content: '\f226';
  }
  
  .mdi-folder-star:before {
    content: '\f227';
  }
  
  .mdi-folder:before {
    content: '\f228';
  }
  
  .mdi-gif:before {
    content: '\f229';
  }
  
  .mdi-upload:before {
    content: '\f22a';
  }
  
  .mdi-border-all:before {
    content: '\f22b';
  }
  
  .mdi-border-bottom:before {
    content: '\f22c';
  }
  
  .mdi-border-clear:before {
    content: '\f22d';
  }
  
  .mdi-border-color:before {
    content: '\f22e';
  }
  
  .mdi-border-horizontal:before {
    content: '\f22f';
  }
  
  .mdi-border-inner:before {
    content: '\f230';
  }
  
  .mdi-border-left:before {
    content: '\f231';
  }
  
  .mdi-border-outer:before {
    content: '\f232';
  }
  
  .mdi-border-right:before {
    content: '\f233';
  }
  
  .mdi-border-style:before {
    content: '\f234';
  }
  
  .mdi-border-top:before {
    content: '\f235';
  }
  
  .mdi-border-vertical:before {
    content: '\f236';
  }
  
  .mdi-copy:before {
    content: '\f237';
  }
  
  .mdi-crop:before {
    content: '\f238';
  }
  
  .mdi-format-align-center:before {
    content: '\f239';
  }
  
  .mdi-format-align-justify:before {
    content: '\f23a';
  }
  
  .mdi-format-align-left:before {
    content: '\f23b';
  }
  
  .mdi-format-align-right:before {
    content: '\f23c';
  }
  
  .mdi-format-bold:before {
    content: '\f23d';
  }
  
  .mdi-format-clear-all:before {
    content: '\f23e';
  }
  
  .mdi-format-clear:before {
    content: '\f23f';
  }
  
  .mdi-format-color-fill:before {
    content: '\f240';
  }
  
  .mdi-format-color-reset:before {
    content: '\f241';
  }
  
  .mdi-format-color-text:before {
    content: '\f242';
  }
  
  .mdi-format-indent-decrease:before {
    content: '\f243';
  }
  
  .mdi-format-indent-increase:before {
    content: '\f244';
  }
  
  .mdi-format-italic:before {
    content: '\f245';
  }
  
  .mdi-format-line-spacing:before {
    content: '\f246';
  }
  
  .mdi-format-list-bulleted:before {
    content: '\f247';
  }
  
  .mdi-format-list-numbered:before {
    content: '\f248';
  }
  
  .mdi-format-ltr:before {
    content: '\f249';
  }
  
  .mdi-format-rtl:before {
    content: '\f24a';
  }
  
  .mdi-format-size:before {
    content: '\f24b';
  }
  
  .mdi-format-strikethrough-s:before {
    content: '\f24c';
  }
  
  .mdi-format-strikethrough:before {
    content: '\f24d';
  }
  
  .mdi-format-subject:before {
    content: '\f24e';
  }
  
  .mdi-format-underlined:before {
    content: '\f24f';
  }
  
  .mdi-format-valign-bottom:before {
    content: '\f250';
  }
  
  .mdi-format-valign-center:before {
    content: '\f251';
  }
  
  .mdi-format-valign-top:before {
    content: '\f252';
  }
  
  .mdi-redo:before {
    content: '\f253';
  }
  
  .mdi-select-all:before {
    content: '\f254';
  }
  
  .mdi-space-bar:before {
    content: '\f255';
  }
  
  .mdi-text-format:before {
    content: '\f256';
  }
  
  .mdi-transform:before {
    content: '\f257';
  }
  
  .mdi-undo:before {
    content: '\f258';
  }
  
  .mdi-wrap-text:before {
    content: '\f259';
  }
  
  .mdi-comment-alert:before {
    content: '\f25a';
  }
  
  .mdi-comment-alt-text:before {
    content: '\f25b';
  }
  
  .mdi-comment-alt:before {
    content: '\f25c';
  }
  
  .mdi-comment-edit:before {
    content: '\f25d';
  }
  
  .mdi-comment-image:before {
    content: '\f25e';
  }
  
  .mdi-comment-list:before {
    content: '\f25f';
  }
  
  .mdi-comment-more:before {
    content: '\f260';
  }
  
  .mdi-comment-outline:before {
    content: '\f261';
  }
  
  .mdi-comment-text-alt:before {
    content: '\f262';
  }
  
  .mdi-comment-text:before {
    content: '\f263';
  }
  
  .mdi-comment-video:before {
    content: '\f264';
  }
  
  .mdi-comment:before {
    content: '\f265';
  }
  
  .mdi-comments:before {
    content: '\f266';
  }
  
  .mdi-check-all:before {
    content: '\f267';
  }
  
  .mdi-check-circle-u:before {
    content: '\f268';
  }
  
  .mdi-check-circle:before {
    content: '\f269';
  }
  
  .mdi-check-square:before {
    content: '\f26a';
  }
  
  .mdi-check:before {
    content: '\f26b';
  }
  
  .mdi-circle-o:before {
    content: '\f26c';
  }
  
  .mdi-circle:before {
    content: '\f26d';
  }
  
  .mdi-dot-circle-alt:before {
    content: '\f26e';
  }
  
  .mdi-dot-circle:before {
    content: '\f26f';
  }
  
  .mdi-minus-circle-outline:before {
    content: '\f270';
  }
  
  .mdi-minus-circle:before {
    content: '\f271';
  }
  
  .mdi-minus-square:before {
    content: '\f272';
  }
  
  .mdi-minus:before {
    content: '\f273';
  }
  
  .mdi-plus-circle-o-duplicate:before {
    content: '\f274';
  }
  
  .mdi-plus-circle-o:before {
    content: '\f275';
  }
  
  .mdi-plus-circle:before {
    content: '\f276';
  }
  
  .mdi-plus-square:before {
    content: '\f277';
  }
  
  .mdi-plus:before {
    content: '\f278';
  }
  
  .mdi-square-o:before {
    content: '\f279';
  }
  
  .mdi-star-circle:before {
    content: '\f27a';
  }
  
  .mdi-star-half:before {
    content: '\f27b';
  }
  
  .mdi-star-outline:before {
    content: '\f27c';
  }
  
  .mdi-star:before {
    content: '\f27d';
  }
  
  .mdi-bluetooth-connected:before {
    content: '\f27e';
  }
  
  .mdi-bluetooth-off:before {
    content: '\f27f';
  }
  
  .mdi-bluetooth-search:before {
    content: '\f280';
  }
  
  .mdi-bluetooth-setting:before {
    content: '\f281';
  }
  
  .mdi-bluetooth:before {
    content: '\f282';
  }
  
  .mdi-camera-add:before {
    content: '\f283';
  }
  
  .mdi-camera-alt:before {
    content: '\f284';
  }
  
  .mdi-camera-bw:before {
    content: '\f285';
  }
  
  .mdi-camera-front:before {
    content: '\f286';
  }
  
  .mdi-camera-mic:before {
    content: '\f287';
  }
  
  .mdi-camera-party-mode:before {
    content: '\f288';
  }
  
  .mdi-camera-rear:before {
    content: '\f289';
  }
  
  .mdi-camera-roll:before {
    content: '\f28a';
  }
  
  .mdi-camera-switch:before {
    content: '\f28b';
  }
  
  .mdi-camera:before {
    content: '\f28c';
  }
  
  .mdi-card-alert:before {
    content: '\f28d';
  }
  
  .mdi-card-off:before {
    content: '\f28e';
  }
  
  .mdi-card-sd:before {
    content: '\f28f';
  }
  
  .mdi-card-sim:before {
    content: '\f290';
  }
  
  .mdi-desktop-mac:before {
    content: '\f291';
  }
  
  .mdi-desktop-windows:before {
    content: '\f292';
  }
  
  .mdi-device-hub:before {
    content: '\f293';
  }
  
  .mdi-devices-off:before {
    content: '\f294';
  }
  
  .mdi-devices:before {
    content: '\f295';
  }
  
  .mdi-dock:before {
    content: '\f296';
  }
  
  .mdi-floppy:before {
    content: '\f297';
  }
  
  .mdi-gamepad:before {
    content: '\f298';
  }
  
  .mdi-gps-dot:before {
    content: '\f299';
  }
  
  .mdi-gps-off:before {
    content: '\f29a';
  }
  
  .mdi-gps:before {
    content: '\f29b';
  }
  
  .mdi-headset-mic:before {
    content: '\f29c';
  }
  
  .mdi-headset:before {
    content: '\f29d';
  }
  
  .mdi-input-antenna:before {
    content: '\f29e';
  }
  
  .mdi-input-composite:before {
    content: '\f29f';
  }
  
  .mdi-input-hdmi:before {
    content: '\f2a0';
  }
  
  .mdi-input-power:before {
    content: '\f2a1';
  }
  
  .mdi-input-svideo:before {
    content: '\f2a2';
  }
  
  .mdi-keyboard-hide:before {
    content: '\f2a3';
  }
  
  .mdi-keyboard:before {
    content: '\f2a4';
  }
  
  .mdi-laptop-chromebook:before {
    content: '\f2a5';
  }
  
  .mdi-laptop-mac:before {
    content: '\f2a6';
  }
  
  .mdi-laptop:before {
    content: '\f2a7';
  }
  
  .mdi-mic-off:before {
    content: '\f2a8';
  }
  
  .mdi-mic-outline:before {
    content: '\f2a9';
  }
  
  .mdi-mic-setting:before {
    content: '\f2aa';
  }
  
  .mdi-mic:before {
    content: '\f2ab';
  }
  
  .mdi-mouse:before {
    content: '\f2ac';
  }
  
  .mdi-network-alert:before {
    content: '\f2ad';
  }
  
  .mdi-network-locked:before {
    content: '\f2ae';
  }
  
  .mdi-network-off:before {
    content: '\f2af';
  }
  
  .mdi-network-outline:before {
    content: '\f2b0';
  }
  
  .mdi-network-setting:before {
    content: '\f2b1';
  }
  
  .mdi-network:before {
    content: '\f2b2';
  }
  
  .mdi-phone-bluetooth:before {
    content: '\f2b3';
  }
  
  .mdi-phone-end:before {
    content: '\f2b4';
  }
  
  .mdi-phone-forwarded:before {
    content: '\f2b5';
  }
  
  .mdi-phone-in-talk:before {
    content: '\f2b6';
  }
  
  .mdi-phone-locked:before {
    content: '\f2b7';
  }
  
  .mdi-phone-missed:before {
    content: '\f2b8';
  }
  
  .mdi-phone-msg:before {
    content: '\f2b9';
  }
  
  .mdi-phone-paused:before {
    content: '\f2ba';
  }
  
  .mdi-phone-ring:before {
    content: '\f2bb';
  }
  
  .mdi-phone-setting:before {
    content: '\f2bc';
  }
  
  .mdi-phone-sip:before {
    content: '\f2bd';
  }
  
  .mdi-phone:before {
    content: '\f2be';
  }
  
  .mdi-portable-wifi-changes:before {
    content: '\f2bf';
  }
  
  .mdi-portable-wifi-off:before {
    content: '\f2c0';
  }
  
  .mdi-portable-wifi:before {
    content: '\f2c1';
  }
  
  .mdi-radio:before {
    content: '\f2c2';
  }
  
  .mdi-reader:before {
    content: '\f2c3';
  }
  
  .mdi-remote-control-alt:before {
    content: '\f2c4';
  }
  
  .mdi-remote-control:before {
    content: '\f2c5';
  }
  
  .mdi-router:before {
    content: '\f2c6';
  }
  
  .mdi-scanner:before {
    content: '\f2c7';
  }
  
  .mdi-smartphone-android:before {
    content: '\f2c8';
  }
  
  .mdi-smartphone-download:before {
    content: '\f2c9';
  }
  
  .mdi-smartphone-erase:before {
    content: '\f2ca';
  }
  
  .mdi-smartphone-info:before {
    content: '\f2cb';
  }
  
  .mdi-smartphone-iphone:before {
    content: '\f2cc';
  }
  
  .mdi-smartphone-landscape-lock:before {
    content: '\f2cd';
  }
  
  .mdi-smartphone-landscape:before {
    content: '\f2ce';
  }
  
  .mdi-smartphone-lock:before {
    content: '\f2cf';
  }
  
  .mdi-smartphone-portrait-lock:before {
    content: '\f2d0';
  }
  
  .mdi-smartphone-ring:before {
    content: '\f2d1';
  }
  
  .mdi-smartphone-setting:before {
    content: '\f2d2';
  }
  
  .mdi-smartphone-setup:before {
    content: '\f2d3';
  }
  
  .mdi-smartphone:before {
    content: '\f2d4';
  }
  
  .mdi-speaker:before {
    content: '\f2d5';
  }
  
  .mdi-tablet-android:before {
    content: '\f2d6';
  }
  
  .mdi-tablet-mac:before {
    content: '\f2d7';
  }
  
  .mdi-tablet:before {
    content: '\f2d8';
  }
  
  .mdi-tv-alt-play:before {
    content: '\f2d9';
  }
  
  .mdi-tv-list:before {
    content: '\f2da';
  }
  
  .mdi-tv-play:before {
    content: '\f2db';
  }
  
  .mdi-tv:before {
    content: '\f2dc';
  }
  
  .mdi-usb:before {
    content: '\f2dd';
  }
  
  .mdi-videocam-off:before {
    content: '\f2de';
  }
  
  .mdi-videocam-switch:before {
    content: '\f2df';
  }
  
  .mdi-videocam:before {
    content: '\f2e0';
  }
  
  .mdi-watch:before {
    content: '\f2e1';
  }
  
  .mdi-wifi-alt-2:before {
    content: '\f2e2';
  }
  
  .mdi-wifi-alt:before {
    content: '\f2e3';
  }
  
  .mdi-wifi-info:before {
    content: '\f2e4';
  }
  
  .mdi-wifi-lock:before {
    content: '\f2e5';
  }
  
  .mdi-wifi-off:before {
    content: '\f2e6';
  }
  
  .mdi-wifi-outline:before {
    content: '\f2e7';
  }
  
  .mdi-wifi:before {
    content: '\f2e8';
  }
  
  .mdi-arrow-left-bottom:before {
    content: '\f2e9';
  }
  
  .mdi-arrow-left:before {
    content: '\f2ea';
  }
  
  .mdi-arrow-merge:before {
    content: '\f2eb';
  }
  
  .mdi-arrow-missed:before {
    content: '\f2ec';
  }
  
  .mdi-arrow-right-top:before {
    content: '\f2ed';
  }
  
  .mdi-arrow-right:before {
    content: '\f2ee';
  }
  
  .mdi-arrow-split:before {
    content: '\f2ef';
  }
  
  .mdi-arrows:before {
    content: '\f2f0';
  }
  
  .mdi-caret-down-circle:before {
    content: '\f2f1';
  }
  
  .mdi-caret-down:before {
    content: '\f2f2';
  }
  
  .mdi-caret-left-circle:before {
    content: '\f2f3';
  }
  
  .mdi-caret-left:before {
    content: '\f2f4';
  }
  
  .mdi-caret-right-circle:before {
    content: '\f2f5';
  }
  
  .mdi-caret-right:before {
    content: '\f2f6';
  }
  
  .mdi-caret-up-circle:before {
    content: '\f2f7';
  }
  
  .mdi-caret-up:before {
    content: '\f2f8';
  }
  
  .mdi-chevron-down:before {
    content: '\f2f9';
  }
  
  .mdi-chevron-left:before {
    content: '\f2fa';
  }
  
  .mdi-chevron-right:before {
    content: '\f2fb';
  }
  
  .mdi-chevron-up:before {
    content: '\f2fc';
  }
  
  .mdi-forward:before {
    content: '\f2fd';
  }
  
  .mdi-long-arrow-down:before {
    content: '\f2fe';
  }
  
  .mdi-long-arrow-left:before {
    content: '\f2ff';
  }
  
  .mdi-long-arrow-return:before {
    content: '\f300';
  }
  
  .mdi-long-arrow-right:before {
    content: '\f301';
  }
  
  .mdi-long-arrow-tab:before {
    content: '\f302';
  }
  
  .mdi-long-arrow-up:before {
    content: '\f303';
  }
  
  .mdi-rotate-ccw:before {
    content: '\f304';
  }
  
  .mdi-rotate-cw:before {
    content: '\f305';
  }
  
  .mdi-rotate-left:before {
    content: '\f306';
  }
  
  .mdi-rotate-right:before {
    content: '\f307';
  }
  
  .mdi-square-down:before {
    content: '\f308';
  }
  
  .mdi-square-right:before {
    content: '\f309';
  }
  
  .mdi-swap-alt:before {
    content: '\f30a';
  }
  
  .mdi-swap-vertical-circle:before {
    content: '\f30b';
  }
  
  .mdi-swap-vertical:before {
    content: '\f30c';
  }
  
  .mdi-swap:before {
    content: '\f30d';
  }
  
  .mdi-trending-down:before {
    content: '\f30e';
  }
  
  .mdi-trending-flat:before {
    content: '\f30f';
  }
  
  .mdi-trending-up:before {
    content: '\f310';
  }
  
  .mdi-unfold-less:before {
    content: '\f311';
  }
  
  .mdi-unfold-more:before {
    content: '\f312';
  }
  
  .mdi-apps:before {
    content: '\f313';
  }
  
  .mdi-grid-off:before {
    content: '\f314';
  }
  
  .mdi-grid:before {
    content: '\f315';
  }
  
  .mdi-view-agenda:before {
    content: '\f316';
  }
  
  .mdi-view-array:before {
    content: '\f317';
  }
  
  .mdi-view-carousel:before {
    content: '\f318';
  }
  
  .mdi-view-column:before {
    content: '\f319';
  }
  
  .mdi-view-comfy:before {
    content: '\f31a';
  }
  
  .mdi-view-compact:before {
    content: '\f31b';
  }
  
  .mdi-view-dashboard:before {
    content: '\f31c';
  }
  
  .mdi-view-day:before {
    content: '\f31d';
  }
  
  .mdi-view-headline:before {
    content: '\f31e';
  }
  
  .mdi-view-list-alt:before {
    content: '\f31f';
  }
  
  .mdi-view-list:before {
    content: '\f320';
  }
  
  .mdi-view-module:before {
    content: '\f321';
  }
  
  .mdi-view-quilt:before {
    content: '\f322';
  }
  
  .mdi-view-stream:before {
    content: '\f323';
  }
  
  .mdi-view-subtitles:before {
    content: '\f324';
  }
  
  .mdi-view-toc:before {
    content: '\f325';
  }
  
  .mdi-view-web:before {
    content: '\f326';
  }
  
  .mdi-view-week:before {
    content: '\f327';
  }
  
  .mdi-widgets:before {
    content: '\f328';
  }
  
  .mdi-alarm-check:before {
    content: '\f329';
  }
  
  .mdi-alarm-off:before {
    content: '\f32a';
  }
  
  .mdi-alarm-plus:before {
    content: '\f32b';
  }
  
  .mdi-alarm-snooze:before {
    content: '\f32c';
  }
  
  .mdi-alarm:before {
    content: '\f32d';
  }
  
  .mdi-calendar-alt:before {
    content: '\f32e';
  }
  
  .mdi-calendar-check:before {
    content: '\f32f';
  }
  
  .mdi-calendar-close:before {
    content: '\f330';
  }
  
  .mdi-calendar-note:before {
    content: '\f331';
  }
  
  .mdi-calendar:before {
    content: '\f332';
  }
  
  .mdi-time-countdown:before {
    content: '\f333';
  }
  
  .mdi-time-interval:before {
    content: '\f334';
  }
  
  .mdi-time-restore-setting:before {
    content: '\f335';
  }
  
  .mdi-time-restore:before {
    content: '\f336';
  }
  
  .mdi-time:before {
    content: '\f337';
  }
  
  .mdi-timer-off:before {
    content: '\f338';
  }
  
  .mdi-timer:before {
    content: '\f339';
  }
  
  .mdi-android-alt:before {
    content: '\f33a';
  }
  
  .mdi-android:before {
    content: '\f33b';
  }
  
  .mdi-apple:before {
    content: '\f33c';
  }
  
  .mdi-behance:before {
    content: '\f33d';
  }
  
  .mdi-codepen:before {
    content: '\f33e';
  }
  
  .mdi-dribbble:before {
    content: '\f33f';
  }
  
  .mdi-dropbox:before {
    content: '\f340';
  }
  
  .mdi-evernote:before {
    content: '\f341';
  }
  
  .mdi-facebook-box:before {
    content: '\f342';
  }
  
  .mdi-facebook:before {
    content: '\f343';
  }
  
  .mdi-github-box:before {
    content: '\f344';
  }
  
  .mdi-github:before {
    content: '\f345';
  }
  
  .mdi-google-drive:before {
    content: '\f346';
  }
  
  .mdi-google-earth:before {
    content: '\f347';
  }
  
  .mdi-google-glass:before {
    content: '\f348';
  }
  
  .mdi-google-maps:before {
    content: '\f349';
  }
  
  .mdi-google-pages:before {
    content: '\f34a';
  }
  
  .mdi-google-play:before {
    content: '\f34b';
  }
  
  .mdi-google-plus-box:before {
    content: '\f34c';
  }
  
  .mdi-google-plus:before {
    content: '\f34d';
  }
  
  .mdi-google:before {
    content: '\f34e';
  }
  
  .mdi-instagram:before {
    content: '\f34f';
  }
  
  .mdi-language-css3:before {
    content: '\f350';
  }
  
  .mdi-language-html5:before {
    content: '\f351';
  }
  
  .mdi-language-javascript:before {
    content: '\f352';
  }
  
  .mdi-language-python-alt:before {
    content: '\f353';
  }
  
  .mdi-language-python:before {
    content: '\f354';
  }
  
  .mdi-lastfm:before {
    content: '\f355';
  }
  
  .mdi-linkedin-box:before {
    content: '\f356';
  }
  
  .mdi-paypal:before {
    content: '\f357';
  }
  
  .mdi-pinterest-box:before {
    content: '\f358';
  }
  
  .mdi-pocket:before {
    content: '\f359';
  }
  
  .mdi-polymer:before {
    content: '\f35a';
  }
  
  .mdi-share:before {
    content: '\f35b';
  }
  
  .mdi-stackoverflow:before {
    content: '\f35c';
  }
  
  .mdi-steam-square:before {
    content: '\f35d';
  }
  
  .mdi-steam:before {
    content: '\f35e';
  }
  
  .mdi-twitter-box:before {
    content: '\f35f';
  }
  
  .mdi-twitter:before {
    content: '\f360';
  }
  
  .mdi-vk:before {
    content: '\f361';
  }
  
  .mdi-wikipedia:before {
    content: '\f362';
  }
  
  .mdi-windows:before {
    content: '\f363';
  }
  
  .mdi-aspect-ratio-alt:before {
    content: '\f364';
  }
  
  .mdi-aspect-ratio:before {
    content: '\f365';
  }
  
  .mdi-blur-circular:before {
    content: '\f366';
  }
  
  .mdi-blur-linear:before {
    content: '\f367';
  }
  
  .mdi-blur-off:before {
    content: '\f368';
  }
  
  .mdi-blur:before {
    content: '\f369';
  }
  
  .mdi-brightness-2:before {
    content: '\f36a';
  }
  
  .mdi-brightness-3:before {
    content: '\f36b';
  }
  
  .mdi-brightness-4:before {
    content: '\f36c';
  }
  
  .mdi-brightness-5:before {
    content: '\f36d';
  }
  
  .mdi-brightness-6:before {
    content: '\f36e';
  }
  
  .mdi-brightness-7:before {
    content: '\f36f';
  }
  
  .mdi-brightness-auto:before {
    content: '\f370';
  }
  
  .mdi-brightness-setting:before {
    content: '\f371';
  }
  
  .mdi-broken-image:before {
    content: '\f372';
  }
  
  .mdi-center-focus-strong:before {
    content: '\f373';
  }
  
  .mdi-center-focus-weak:before {
    content: '\f374';
  }
  
  .mdi-compare:before {
    content: '\f375';
  }
  
  .mdi-crop-16-9:before {
    content: '\f376';
  }
  
  .mdi-crop-3-2:before {
    content: '\f377';
  }
  
  .mdi-crop-5-4:before {
    content: '\f378';
  }
  
  .mdi-crop-7-5:before {
    content: '\f379';
  }
  
  .mdi-crop-din:before {
    content: '\f37a';
  }
  
  .mdi-crop-free:before {
    content: '\f37b';
  }
  
  .mdi-crop-landscape:before {
    content: '\f37c';
  }
  
  .mdi-crop-portrait:before {
    content: '\f37d';
  }
  
  .mdi-crop-square:before {
    content: '\f37e';
  }
  
  .mdi-exposure-alt:before {
    content: '\f37f';
  }
  
  .mdi-exposure:before {
    content: '\f380';
  }
  
  .mdi-filter-b-and-w:before {
    content: '\f381';
  }
  
  .mdi-filter-center-focus:before {
    content: '\f382';
  }
  
  .mdi-filter-frames:before {
    content: '\f383';
  }
  
  .mdi-filter-tilt-shift:before {
    content: '\f384';
  }
  
  .mdi-gradient:before {
    content: '\f385';
  }
  
  .mdi-grain:before {
    content: '\f386';
  }
  
  .mdi-graphic-eq:before {
    content: '\f387';
  }
  
  .mdi-hdr-off:before {
    content: '\f388';
  }
  
  .mdi-hdr-strong:before {
    content: '\f389';
  }
  
  .mdi-hdr-weak:before {
    content: '\f38a';
  }
  
  .mdi-hdr:before {
    content: '\f38b';
  }
  
  .mdi-iridescent:before {
    content: '\f38c';
  }
  
  .mdi-leak-off:before {
    content: '\f38d';
  }
  
  .mdi-leak:before {
    content: '\f38e';
  }
  
  .mdi-looks:before {
    content: '\f38f';
  }
  
  .mdi-loupe:before {
    content: '\f390';
  }
  
  .mdi-panorama-horizontal:before {
    content: '\f391';
  }
  
  .mdi-panorama-vertical:before {
    content: '\f392';
  }
  
  .mdi-panorama-wide-angle:before {
    content: '\f393';
  }
  
  .mdi-photo-size-select-large:before {
    content: '\f394';
  }
  
  .mdi-photo-size-select-small:before {
    content: '\f395';
  }
  
  .mdi-picture-in-picture:before {
    content: '\f396';
  }
  
  .mdi-slideshow:before {
    content: '\f397';
  }
  
  .mdi-texture:before {
    content: '\f398';
  }
  
  .mdi-tonality:before {
    content: '\f399';
  }
  
  .mdi-vignette:before {
    content: '\f39a';
  }
  
  .mdi-wb-auto:before {
    content: '\f39b';
  }
  
  .mdi-eject-alt:before {
    content: '\f39c';
  }
  
  .mdi-eject:before {
    content: '\f39d';
  }
  
  .mdi-equalizer:before {
    content: '\f39e';
  }
  
  .mdi-fast-forward:before {
    content: '\f39f';
  }
  
  .mdi-fast-rewind:before {
    content: '\f3a0';
  }
  
  .mdi-forward-10:before {
    content: '\f3a1';
  }
  
  .mdi-forward-30:before {
    content: '\f3a2';
  }
  
  .mdi-forward-5:before {
    content: '\f3a3';
  }
  
  .mdi-hearing:before {
    content: '\f3a4';
  }
  
  .mdi-pause-circle-outline:before {
    content: '\f3a5';
  }
  
  .mdi-pause-circle:before {
    content: '\f3a6';
  }
  
  .mdi-pause:before {
    content: '\f3a7';
  }
  
  .mdi-play-circle-outline:before {
    content: '\f3a8';
  }
  
  .mdi-play-circle:before {
    content: '\f3a9';
  }
  
  .mdi-play:before {
    content: '\f3aa';
  }
  
  .mdi-playlist-audio:before {
    content: '\f3ab';
  }
  
  .mdi-playlist-plus:before {
    content: '\f3ac';
  }
  
  .mdi-repeat-one:before {
    content: '\f3ad';
  }
  
  .mdi-repeat:before {
    content: '\f3ae';
  }
  
  .mdi-replay-10:before {
    content: '\f3af';
  }
  
  .mdi-replay-30:before {
    content: '\f3b0';
  }
  
  .mdi-replay-5:before {
    content: '\f3b1';
  }
  
  .mdi-replay:before {
    content: '\f3b2';
  }
  
  .mdi-shuffle:before {
    content: '\f3b3';
  }
  
  .mdi-skip-next:before {
    content: '\f3b4';
  }
  
  .mdi-skip-previous:before {
    content: '\f3b5';
  }
  
  .mdi-stop:before {
    content: '\f3b6';
  }
  
  .mdi-surround-sound:before {
    content: '\f3b7';
  }
  
  .mdi-tune:before {
    content: '\f3b8';
  }
  
  .mdi-volume-down:before {
    content: '\f3b9';
  }
  
  .mdi-volume-mute:before {
    content: '\f3ba';
  }
  
  .mdi-volume-off:before {
    content: '\f3bb';
  }
  
  .mdi-volume-up:before {
    content: '\f3bc';
  }
  
  .mdi-n-1-square:before {
    content: '\f3bd';
  }
  
  .mdi-n-2-square:before {
    content: '\f3be';
  }
  
  .mdi-n-3-square:before {
    content: '\f3bf';
  }
  
  .mdi-n-4-square:before {
    content: '\f3c0';
  }
  
  .mdi-n-5-square:before {
    content: '\f3c1';
  }
  
  .mdi-n-6-square:before {
    content: '\f3c2';
  }
  
  .mdi-neg-1:before {
    content: '\f3c3';
  }
  
  .mdi-neg-2:before {
    content: '\f3c4';
  }
  
  .mdi-plus-1:before {
    content: '\f3c5';
  }
  
  .mdi-plus-2:before {
    content: '\f3c6';
  }
  
  .mdi-sec-10:before {
    content: '\f3c7';
  }
  
  .mdi-sec-3:before {
    content: '\f3c8';
  }
  
  .mdi-zero:before {
    content: '\f3c9';
  }
  
  .mdi-airline-seat-flat-angled:before {
    content: '\f3ca';
  }
  
  .mdi-airline-seat-flat:before {
    content: '\f3cb';
  }
  
  .mdi-airline-seat-individual-suite:before {
    content: '\f3cc';
  }
  
  .mdi-airline-seat-legroom-extra:before {
    content: '\f3cd';
  }
  
  .mdi-airline-seat-legroom-normal:before {
    content: '\f3ce';
  }
  
  .mdi-airline-seat-legroom-reduced:before {
    content: '\f3cf';
  }
  
  .mdi-airline-seat-recline-extra:before {
    content: '\f3d0';
  }
  
  .mdi-airline-seat-recline-normal:before {
    content: '\f3d1';
  }
  
  .mdi-airplay:before {
    content: '\f3d2';
  }
  
  .mdi-closed-caption:before {
    content: '\f3d3';
  }
  
  .mdi-confirmation-number:before {
    content: '\f3d4';
  }
  
  .mdi-developer-board:before {
    content: '\f3d5';
  }
  
  .mdi-disc-full:before {
    content: '\f3d6';
  }
  
  .mdi-explicit:before {
    content: '\f3d7';
  }
  
  .mdi-flight-land:before {
    content: '\f3d8';
  }
  
  .mdi-flight-takeoff:before {
    content: '\f3d9';
  }
  
  .mdi-flip-to-back:before {
    content: '\f3da';
  }
  
  .mdi-flip-to-front:before {
    content: '\f3db';
  }
  
  .mdi-group-work:before {
    content: '\f3dc';
  }
  
  .mdi-hd:before {
    content: '\f3dd';
  }
  
  .mdi-hq:before {
    content: '\f3de';
  }
  
  .mdi-markunread-mailbox:before {
    content: '\f3df';
  }
  
  .mdi-memory:before {
    content: '\f3e0';
  }
  
  .mdi-nfc:before {
    content: '\f3e1';
  }
  
  .mdi-play-for-work:before {
    content: '\f3e2';
  }
  
  .mdi-power-input:before {
    content: '\f3e3';
  }
  
  .mdi-present-to-all:before {
    content: '\f3e4';
  }
  
  .mdi-satellite:before {
    content: '\f3e5';
  }
  
  .mdi-tap-and-play:before {
    content: '\f3e6';
  }
  
  .mdi-vibration:before {
    content: '\f3e7';
  }
  
  .mdi-voicemail:before {
    content: '\f3e8';
  }
  
  .mdi-group:before {
    content: '\f3e9';
  }
  
  .mdi-rss:before {
    content: '\f3ea';
  }
  
  .mdi-shape:before {
    content: '\f3eb';
  }
  
  .mdi-spinner:before {
    content: '\f3ec';
  }
  
  .mdi-ungroup:before {
    content: '\f3ed';
  }
  
  .mdi-500px:before {
    content: '\f3ee';
  }
  
  .mdi-8tracks:before {
    content: '\f3ef';
  }
  
  .mdi-amazon:before {
    content: '\f3f0';
  }
  
  .mdi-blogger:before {
    content: '\f3f1';
  }
  
  .mdi-delicious:before {
    content: '\f3f2';
  }
  
  .mdi-disqus:before {
    content: '\f3f3';
  }
  
  .mdi-flattr:before {
    content: '\f3f4';
  }
  
  .mdi-flickr:before {
    content: '\f3f5';
  }
  
  .mdi-github-alt:before {
    content: '\f3f6';
  }
  
  .mdi-google-old:before {
    content: '\f3f7';
  }
  
  .mdi-linkedin:before {
    content: '\f3f8';
  }
  
  .mdi-odnoklassniki:before {
    content: '\f3f9';
  }
  
  .mdi-outlook:before {
    content: '\f3fa';
  }
  
  .mdi-paypal-alt:before {
    content: '\f3fb';
  }
  
  .mdi-pinterest:before {
    content: '\f3fc';
  }
  
  .mdi-playstation:before {
    content: '\f3fd';
  }
  
  .mdi-reddit:before {
    content: '\f3fe';
  }
  
  .mdi-skype:before {
    content: '\f3ff';
  }
  
  .mdi-slideshare:before {
    content: '\f400';
  }
  
  .mdi-soundcloud:before {
    content: '\f401';
  }
  
  .mdi-tumblr:before {
    content: '\f402';
  }
  
  .mdi-twitch:before {
    content: '\f403';
  }
  
  .mdi-vimeo:before {
    content: '\f404';
  }
  
  .mdi-whatsapp:before {
    content: '\f405';
  }
  
  .mdi-xbox:before {
    content: '\f406';
  }
  
  .mdi-yahoo:before {
    content: '\f407';
  }
  
  .mdi-youtube-play:before {
    content: '\f408';
  }
  
  .mdi-youtube:before {
    content: '\f409';
  }
  
  .mdi-import-export:before {
    content: '\f30c';
  }
  
  .mdi-swap-vertical-:before {
    content: '\f30c';
  }
  
  .mdi-airplanemode-inactive:before {
    content: '\f102';
  }
  
  .mdi-airplanemode-active:before {
    content: '\f103';
  }
  
  .mdi-rate-review:before {
    content: '\f103';
  }
  
  .mdi-comment-sign:before {
    content: '\f25a';
  }
  
  .mdi-network-warning:before {
    content: '\f2ad';
  }
  
  .mdi-shopping-cart-add:before {
    content: '\f1ca';
  }
  
  .mdi-file-add:before {
    content: '\f221';
  }
  
  .mdi-network-wifi-scan:before {
    content: '\f2e4';
  }
  
  .mdi-collection-add:before {
    content: '\f14e';
  }
  
  .mdi-format-playlist-add:before {
    content: '\f3ac';
  }
  
  .mdi-format-queue-music:before {
    content: '\f3ab';
  }
  
  .mdi-plus-box:before {
    content: '\f277';
  }
  
  .mdi-tag-backspace:before {
    content: '\f1d9';
  }
  
  .mdi-alarm-add:before {
    content: '\f32b';
  }
  
  .mdi-battery-charging:before {
    content: '\f114';
  }
  
  .mdi-daydream-setting:before {
    content: '\f217';
  }
  
  .mdi-more-horiz:before {
    content: '\f19c';
  }
  
  .mdi-book-photo:before {
    content: '\f11b';
  }
  
  .mdi-incandescent:before {
    content: '\f189';
  }
  
  .mdi-wb-iridescent:before {
    content: '\f38c';
  }
  
  .mdi-calendar-remove:before {
    content: '\f330';
  }
  
  .mdi-refresh-sync-disabled:before {
    content: '\f1b7';
  }
  
  .mdi-refresh-sync-problem:before {
    content: '\f1b6';
  }
  
  .mdi-crop-original:before {
    content: '\f17e';
  }
  
  .mdi-power-off:before {
    content: '\f1af';
  }
  
  .mdi-power-off-setting:before {
    content: '\f1ae';
  }
  
  .mdi-leak-remove:before {
    content: '\f38d';
  }
  
  .mdi-star-border:before {
    content: '\f27c';
  }
  
  .mdi-brightness-low:before {
    content: '\f36d';
  }
  
  .mdi-brightness-medium:before {
    content: '\f36e';
  }
  
  .mdi-brightness-high:before {
    content: '\f36f';
  }
  
  .mdi-smartphone-portrait:before {
    content: '\f2d4';
  }
  
  .mdi-live-tv:before {
    content: '\f2d9';
  }
  
  .mdi-format-textdirection-l-to-r:before {
    content: '\f249';
  }
  
  .mdi-format-textdirection-r-to-l:before {
    content: '\f24a';
  }
  
  .mdi-arrow-back:before {
    content: '\f2ea';
  }
  
  .mdi-arrow-forward:before {
    content: '\f2ee';
  }
  
  .mdi-arrow-in:before {
    content: '\f2e9';
  }
  
  .mdi-arrow-out:before {
    content: '\f2ed';
  }
  
  .mdi-rotate-90-degrees-ccw:before {
    content: '\f304';
  }
  
  .mdi-adb:before {
    content: '\f33a';
  }
  
  .mdi-network-wifi:before {
    content: '\f2e8';
  }
  
  .mdi-network-wifi-alt:before {
    content: '\f2e3';
  }
  
  .mdi-network-wifi-lock:before {
    content: '\f2e5';
  }
  
  .mdi-network-wifi-off:before {
    content: '\f2e6';
  }
  
  .mdi-network-wifi-outline:before {
    content: '\f2e7';
  }
  
  .mdi-network-wifi-info:before {
    content: '\f2e4';
  }
  
  .mdi-layers-clear:before {
    content: '\f18b';
  }
  
  .mdi-colorize:before {
    content: '\f15d';
  }
  
  .mdi-format-paint:before {
    content: '\f1ba';
  }
  
  .mdi-format-quote:before {
    content: '\f1b2';
  }
  
  .mdi-camera-monochrome-photos:before {
    content: '\f285';
  }
  
  .mdi-sort-by-alpha:before {
    content: '\f1cf';
  }
  
  .mdi-folder-shared:before {
    content: '\f225';
  }
  
  .mdi-folder-special:before {
    content: '\f226';
  }
  
  .mdi-comment-dots:before {
    content: '\f260';
  }
  
  .mdi-reorder:before {
    content: '\f31e';
  }
  
  .mdi-dehaze:before {
    content: '\f197';
  }
  
  .mdi-sort:before {
    content: '\f1ce';
  }
  
  .mdi-pages:before {
    content: '\f34a';
  }
  
  .mdi-stack-overflow:before {
    content: '\f35c';
  }
  
  .mdi-calendar-account:before {
    content: '\f204';
  }
  
  .mdi-paste:before {
    content: '\f109';
  }
  
  .mdi-cut:before {
    content: '\f1bc';
  }
  
  .mdi-save:before {
    content: '\f297';
  }
  
  .mdi-smartphone-code:before {
    content: '\f139';
  }
  
  .mdi-directions-bike:before {
    content: '\f117';
  }
  
  .mdi-directions-boat:before {
    content: '\f11a';
  }
  
  .mdi-directions-bus:before {
    content: '\f121';
  }
  
  .mdi-directions-car:before {
    content: '\f125';
  }
  
  .mdi-directions-railway:before {
    content: '\f1b3';
  }
  
  .mdi-directions-run:before {
    content: '\f215';
  }
  
  .mdi-directions-subway:before {
    content: '\f1d5';
  }
  
  .mdi-directions-walk:before {
    content: '\f216';
  }
  
  .mdi-local-hotel:before {
    content: '\f178';
  }
  
  .mdi-local-activity:before {
    content: '\f1df';
  }
  
  .mdi-local-play:before {
    content: '\f1df';
  }
  
  .mdi-local-airport:before {
    content: '\f103';
  }
  
  .mdi-local-atm:before {
    content: '\f198';
  }
  
  .mdi-local-bar:before {
    content: '\f137';
  }
  
  .mdi-local-cafe:before {
    content: '\f13b';
  }
  
  .mdi-local-car-wash:before {
    content: '\f124';
  }
  
  .mdi-local-convenience-store:before {
    content: '\f1d3';
  }
  
  .mdi-local-dining:before {
    content: '\f153';
  }
  
  .mdi-local-drink:before {
    content: '\f157';
  }
  
  .mdi-local-florist:before {
    content: '\f168';
  }
  
  .mdi-local-gas-station:before {
    content: '\f16f';
  }
  
  .mdi-local-grocery-store:before {
    content: '\f1cb';
  }
  
  .mdi-local-hospital:before {
    content: '\f177';
  }
  
  .mdi-local-laundry-service:before {
    content: '\f1e9';
  }
  
  .mdi-local-library:before {
    content: '\f18d';
  }
  
  .mdi-local-mall:before {
    content: '\f195';
  }
  
  .mdi-local-movies:before {
    content: '\f19d';
  }
  
  .mdi-local-offer:before {
    content: '\f187';
  }
  
  .mdi-local-parking:before {
    content: '\f1a5';
  }
  
  .mdi-local-parking:before {
    content: '\f1a5';
  }
  
  .mdi-local-pharmacy:before {
    content: '\f176';
  }
  
  .mdi-local-phone:before {
    content: '\f2be';
  }
  
  .mdi-local-pizza:before {
    content: '\f1ac';
  }
  
  .mdi-local-post-office:before {
    content: '\f15a';
  }
  
  .mdi-local-printshop:before {
    content: '\f1b0';
  }
  
  .mdi-local-see:before {
    content: '\f28c';
  }
  
  .mdi-local-shipping:before {
    content: '\f1e6';
  }
  
  .mdi-local-store:before {
    content: '\f1d4';
  }
  
  .mdi-local-taxi:before {
    content: '\f123';
  }
  
  .mdi-local-wc:before {
    content: '\f211';
  }
  
  .mdi-my-location:before {
    content: '\f299';
  }
  
  .mdi-directions:before {
    content: '\f1e7';
  }
  