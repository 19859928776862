@font-face {
  font-family: Icons;
  src: url(https://cdn.jsdelivr.net/npm/semantic-ui-css@2.4.0/themes/default/assets/fonts/icons.woff2)
      format("woff2"),
    url(https://cdn.jsdelivr.net/npm/semantic-ui-css@2.4.0/themes/default/assets/fonts/icons.woff)
      format("woff"),
    url(https://cdn.jsdelivr.net/npm/semantic-ui-css@2.4.0/themes/default/assets/fonts/icons.ttf)
      format("truetype");
}
html,
body {
  height: 100%;
}
.url {
  color: #0061da;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
}

.url:hover {
  color: #0056b3;
}

li.nav-item.dropdown:hover > .dropdown-menu {
  display: block;
  margin-top: 0;
}

.fixed-top .navbar-collapse {
  border-left: 1px solid #e2efd4;
}

.main-content {
  padding-top: 0;
}

.page-head-title {
  font-weight: 400;
  font-size: 26px;
}

h1.navbar-brand {
  background: none !important;
  line-height: 41px !important;
  padding-left: 0 !important;
  font-size: 22px !important;
  width: auto !important;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1230px;
  }
}

label {
  font-size: 14px;
  font-weight: 600;
}

.form-control {
  height: 35px;
}

.clearfix {
  clear: both;
  display: block;
  width: 100%;
}

.form_cover {
  padding: 20px 10px;
  border: 1px solid #c1c1c1;
  background: #fff;
  border-radius: 5px;
  margin: 0;
  box-shadow: 2px 3px 0px #c1c1c1;
}

.btn {
  margin-right: 5px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0px 0px 5px #000;
}

input:checked + .slider {
  background-color: #66c500;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

select.form-control:not([size]):not([multiple]) {
  height: 40px;
  padding-top: 5px;
}

.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__input-container {
  width: 100%;
}

.require_field {
  color: #ff0000;
  font-size: 11px;
  margin-top: 5px;
  display: inline-block;
}

label.required::after {
  color: #ff0000;
  font-size: 14px;
  content: " *";
}

.cell-wrap-text {
  white-space: normal !important;
}

.pivotHeader th {
  background-color: darkmagenta;
  color: #fff;
}

.ui.table td.positive,
.ui.table tr.positive {
  background: transparent !important;
  color: #0061da !important;
}

.ui.segment {
  position: relative;
  background: transparent;
  -webkit-box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  margin: 1rem 0;
  border-radius: 0.28571429rem;
  /* border: 1px solid rgba(34,36,38,.15); */
  padding: 0em;
  padding-left: 0.5em;
}

.grid_height_fix {
  height: 170px !important;
}
.grid_height_200 {
  height: 200px !important;
}
.grid_height_250 {
  height: 250px !important;
}
.grid_height_500 {
  height: 500px !important;
}
.grid_height_600 {
  height: 600px !important;
}
.grid_height_750 {
  height: 750px !important;
}

.ui.table td.positive,
.ui.table tr.positive {
  background: transparent !important;
  color: #0061da !important;
  /* font-weight: bold; */
}

.ui.table td.negative,
.ui.table tr.negative {
  background: transparent !important;
  color: red !important;
  /* font-weight: bold; */
}

.ag-bl-full-height {
  height: 100% !important;
  overflow: auto;
  position: relative;
}

@media (max-width: 1366px) {
  .width700 {
    max-width: 578px;
  }
}
@media (max-width: 1359px) {
  .width700 {
    max-width: 578px;
  }
}

.width700 {
  width: 675px;
}
.fullwidth {
  width: 100%;
}
.height240 {
  height: 240px !important;
}
.height400 {
  height: 400px !important;
}
.height700 {
  height: 700px !important;
}
.height250 {
  height: 250px !important;
}
.row {
  padding-bottom: 0.3em !important;
  margin-bottom: 0rem !important;
}
.modal {
  bottom: unset !important;
}
.negative {
  color: red;
  /* font-weight: bold; */
}
.positive {
  color: #0061da !important;
  /* font-weight: bold; */
}

.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.panel-default {
  border-color: #ddd;
}
.panel-default > .panel-heading {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;
}
.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.panel-body {
  padding: unset;
}
.panel-body-2 {
  padding: 15px;
}

@media (min-width: 768px) {
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: unset;
  }
}

.ui.table td.positive,
.ui.table tr.positive {
  background: transparent !important;
  color: #0061da !important;
}
.customHeaderTable {
  padding: 0.8em 0.7em !important;
}

.ui.modal > .close {
  color: #000 !important;
}

.ui.positive.button,
.ui.positive.buttons .button {
  color: #fff !important;
}
th,
.capitalizetext {
  text-transform: uppercase !important;
}

.ui.compact.table th {
  padding-left: 0.7em;
  padding-right: 0.7em;
  text-align: center;
}

.positivetableheader th {
  background-color: #0061da !important;
  color: #fff !important;
}
.negativetableheader th {
  /* background-color: #db2828 !important; */
  background-color: #dc3545 !important;
  color: #fff !important;
}

.negativeborder {
  border-color: #dc3545 !important;
}
.positiveborder {
  border-color: #0061da !important;
}
.positivecell {
  background-color: #0061da !important;
  color: #fff !important;
}

.negativecell {
  /* background-color: #db2828 !important; */
  background-color: #dc3545 !important;
  color: #fff !important;
}

.pivotsubheader {
  text-transform: uppercase;
  text-align: center !important;
  font-weight: bold;
}
.zindex9999 {
  z-index: -9999;
}

.nocontainer {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .nocontainer {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .nocontainer {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .nocontainer {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .nocontainer {
    max-width: 1140px;
  }
}

.norow {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -ms-flex-order: -1;
  order: -1;
}

.order-last {
  -ms-flex-order: 13;
  order: 13;
}

.order-0 {
  -ms-flex-order: 0;
  order: 0;
}

.order-1 {
  -ms-flex-order: 1;
  order: 1;
}

.order-2 {
  -ms-flex-order: 2;
  order: 2;
}

.order-3 {
  -ms-flex-order: 3;
  order: 3;
}

.order-4 {
  -ms-flex-order: 4;
  order: 4;
}

.order-5 {
  -ms-flex-order: 5;
  order: 5;
}

.order-6 {
  -ms-flex-order: 6;
  order: 6;
}

.order-7 {
  -ms-flex-order: 7;
  order: 7;
}

.order-8 {
  -ms-flex-order: 8;
  order: 8;
}

.order-9 {
  -ms-flex-order: 9;
  order: 9;
}

.order-10 {
  -ms-flex-order: 10;
  order: 10;
}

.order-11 {
  -ms-flex-order: 11;
  order: 11;
}

.order-12 {
  -ms-flex-order: 12;
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-sm-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-sm-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-sm-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-sm-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-sm-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-sm-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-sm-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-sm-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-sm-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-sm-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-sm-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-sm-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-sm-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-sm-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-md-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-md-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-md-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-md-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-md-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-md-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-md-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-md-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-md-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-md-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-md-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-md-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-md-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-md-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-lg-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-lg-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-lg-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-lg-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-lg-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-lg-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-lg-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-lg-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-lg-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-lg-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-lg-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-lg-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -ms-flex-order: -1;
    order: -1;
  }
  .order-xl-last {
    -ms-flex-order: 13;
    order: 13;
  }
  .order-xl-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

.ui.modal > .header {
  background: #005689;
  color: #fff;
}

.disabledOpacity {
  opacity: unset !important;
}

.segmentheader {
  background: #005689 !important;
  color: #fff !important;
}
.alignright > input {
  text-align: right;
}
.alignright input[type="text"] {
  text-align: right;
}
.menulogo {
  background-color: #9cc8ea !important;
  color: #005689 !important;
  font-weight: bolder !important;
  font-size: smaller !important;
}

.support {
  color: #0061da;
}
.resistance {
  color: red;
}
.boldletters {
  font-weight: bold;
}
.bolditalicgreen {
  font-weight: bold;
  font-style: italic;
  color: green;
}

.ui.inverted.blue.menu,
.ui.inverted.menu .blue.active.item {
  background-color: #0061da;
}

.ui.blue.button,
.ui.blue.buttons .button {
  background-color: #0061da;
}

/* .ui.red.button, .ui.red.buttons .button {
    background-color: #db2828;
    color: #fff;
    text-shadow: none;
    background-image: none;
} */

.ui.basic.table {
  background-color: white;

  border: 1px solid navy;
}
.width500 {
  width: 500px !important;
}

img.bg {
  background-image: url("../asset/images/12.png");
  background-position: 100;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.4;
}
/* .bg > img{
 
} */

.padding10 {
  padding: 10px;
}

.width0 {
  width: 0px !important;
}
.lightgray {
  background-color: #89cff0 !important;
  color: black !important;
}

.colorwhite {
  color: white !important;
}

.ui.form .required.field > .checkbox:after,
.ui.form .required.field > label:after,
.ui.form .required.fields.grouped > label:after,
.ui.form .required.fields:not(.grouped) > .field > .checkbox:after,
.ui.form .required.fields:not(.grouped) > .field > label:after {
  margin: -0.2em 0 0 0.2em;
  content: "*";
  color: #db2828;
  display: none !important;
}

.whitebg {
  background-color: white !important;
}
.margintop0 {
  margin-top: 0 !important;
}
.padding5 {
  padding: 5px !important;
}
.colorblack {
  color: black !important;
}
.react-stockcharts-tooltip,
.react-stockcharts-y-axis {
  /* pointer-events: all;
    cursor: pointer; */
  font-size: small;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.menubg {
  background-color: #005689 !important;
}

.nomargin {
  margin: 0 !important;
}

.ui.grid {
  margin-top: -0.5rem !important;
}

@media (min-width: 1200px) {
  .ui.container {
    margin-top: 5px;
  }
}
.ui.grid > .row {
  padding-top: 0.3rem;
  /* margin-top: 0.7rem !important; */
}

.paddingtop {
  padding-top: 1rem !important;
}
/* .paddingtop>.row{

padding-top: 0.3rem !important;
} */

.maindata {
  font-size: 12px;
  /* padding-right: 4px;
    color: black; */
}
.inr {
  font-size: 10px;
  color: rgba(0, 0, 0, 0.62);
}
.datachangenegative {
  font-size: 10px;
  color: #d23f31;
}
.datachangepositive {
  font-size: 10px;
  color: #0f9d58;
}

.ui.placeholder.segment .field,
.ui.placeholder.segment .button {
  max-width: unset !important;
}

.cell-wrap-text {
  white-space: normal !important;
}

.alignright {
  text-align: right;
}

#chart-container {
  width: 100%;
  height: 450px;
  min-height: 450px;
  min-width: 850px;
}

.demo-editor {
  height: 275px !important;
  border: 1px solid #f1f1f1 !important;
  padding: 5px !important;
  border-radius: 2px !important;
}

.marginbottom0 {
  margin-bottom: 0rem !important ;
}
.nseoptionmargin {
  margin: 0.1em 0 !important;
}

.ui.fullscreen.modal {
  width: 95% !important;
  left: auto !important;
  /* margin: 1em auto; */
}

.ui.fullscreen.modal > .close {
  color: white !important;
}

.fullwidth {
  margin-top: 0.2rem;
}

/* .ag-header-cell-label .ag-header-cell-text{
    word-wrap: break-word;
    word-break: break-all;
    overflow-wrap: break-word;
    white-space: normal !important;
} */

.ag-cell-label-container {
  /*Necessary to allow for text to grow vertically*/
  height: 100%;
}

.ag-header-cell-label {
  /*Necessary to allow for text to grow vertically*/
  height: 100%;
  padding: 0 !important;
}

.ag-header-cell-label .ag-header-cell-text {
  /*Force the width corresponding at how much width
    we need once the text is layed out vertically*/
  /* writing-mode: vertical-lr; */
  line-height: 3em;
  white-space: normal !important;
}

.ui.feed > .event > .content .extra.text {
  max-width: none !important;
}

.scrollingdiv {
  overflow: scroll;
  height: 800px;
}
.ui.dimmer {
  background-color: rgba(0, 0, 0, -0.1) !important;
  /* background-color: #66c500 !important; */
}
.ui.dimmer.dimdim {
  background-color: #89cff0 !important;
}

.block-ui-overlay {
  width: 100%;
  height: 100%;
  opacity: 0.9;
  filter: alpha(opacity=50);
  background-color: white;
}

.colorwhite {
  color: white !important;
}

.padding30 {
  padding: 15px !important;
}

.noborder {
  border: none !important;
}
.inputborder input[type="text"] {
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
}
.padding2 {
  padding-right: 15px !important;
  padding-left: 15px !important;
  padding-bottom: 15px !important;
}
