.ui.segment {
  position: relative;
  background: transparent;
  -webkit-box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  margin: 1rem 0;
  border-radius: 0.28571429rem;
  /* border: 1px solid rgba(34,36,38,.15); */
  padding: 0em;
  padding-left: 0.5em;
}

.grid_height_fix {
  height: 170px !important;
}

.ui.table td.positive,
.ui.table tr.positive {
  background: transparent !important;
  color: #007bff !important;
  /* font-weight: bold; */
}

.ui.table td.negative,
.ui.table tr.negative {
  background: transparent !important;
  color: red !important;
  /* font-weight: bold; */
}

.ag-bl-full-height {
  height: 100% !important;
  overflow: auto;
  position: relative;
}
.width700{
  width:675px
}
.height240 {
  height: 240px !important;
}
.height400 {
  height: 400px !important;
}
.height250 {
  height: 250px !important;
}
.row {
  padding-bottom: 0.3em !important;
  margin-bottom: 0rem !important;
}
.modal {
  bottom: unset !important;
}
.negative {
  color: red;
  /* font-weight: bold; */
}
.positive {
  color: #007bff !important;
  /* font-weight: bold; */
}

.panel {
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
}
.panel-default {
    border-color: #ddd;
}
.panel-default>.panel-heading {
    color: #333;
    background-color: #f5f5f5;
    border-color: #ddd;
}
.panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.panel-body {
    padding: unset;
}
.panel-body-2 {
    padding: 15px;
}

@media (min-width: 768px) {
  .col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: unset;
  }
}

.ui.dimmer {
background-color: rgba(0,0,0,-0.10) !important;
}

.ui.table td.positive, .ui.table tr.positive {
    background: transparent !important;
    color: #007bff !important;
}