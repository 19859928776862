.ui segment{
    border-radius:0%;
}
.ui.segment {
  position: relative;
  background: transparent;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  margin: 1rem 0;
  border-radius: 0.28571429rem;
  /* border: 1px solid rgba(34,36,38,.15); */
  padding: 0em;
  padding-left: 0.5em;
}

.grid_height_fix {
  height: 170px !important;
}

.ui.table td.positive,
.ui.table tr.positive {
  background: transparent !important;
  color: #007bff !important;
  /* font-weight: bold; */
}

.ui.table td.negative,
.ui.table tr.negative {
  background: transparent !important;
  color: red !important;
  /* font-weight: bold; */
}

.ag-bl-full-height {
  height: 100% !important;
  overflow: auto;
  position: relative;
}
.width700{
  width:675px
}
.height240 {
  height: 240px !important;
}
.height400 {
  height: 400px !important;
}
.height250 {
  height: 250px !important;
}
.row {
  padding-bottom: 0.3em !important;
  margin-bottom: 0rem !important;
}
.modal {
  bottom: unset !important;
}
.negative {
  color: red;
  /* font-weight: bold; */
}
.positive {
  color: #007bff !important;
  /* font-weight: bold; */
}

.panel {
    margin-bottom: 20px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(0,0,0,.05);
}
.panel-default {
    border-color: #ddd;
}
.panel-default>.panel-heading {
    color: #333;
    background-color: #f5f5f5;
    border-color: #ddd;
}
.panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.panel-body {
    padding: unset;
}
.panel-body-2 {
    padding: 15px;
}

@media (min-width: 768px) {
  .col-md-3 {
    flex: 0 0 25%;
    max-width: unset;
  }
}

.ui.dimmer {
background-color: rgba(0,0,0,-0.10) !important;
}

.ui.table td.positive, .ui.table tr.positive {
    background: transparent !important;
    color: #007bff !important;
}
html,
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  /* background-color: lightgray !important; */
  background-color: whitesmoke !important;
  font-size: 13px !important;
  line-height: 1.2285em !important;
  min-height: 680px;
}

.main {
  padding-right: 3px;
  padding-left: 5px;
  height: auto !important; /* real browsers */
  min-height: 785px; /* real browsers */
}

@font-face {
  font-family: Icons;
  src: url(https://cdn.jsdelivr.net/npm/semantic-ui-css@2.4.0/themes/default/assets/fonts/icons.woff2)
      format("woff2"),
    url(https://cdn.jsdelivr.net/npm/semantic-ui-css@2.4.0/themes/default/assets/fonts/icons.woff)
      format("woff"),
    url(https://cdn.jsdelivr.net/npm/semantic-ui-css@2.4.0/themes/default/assets/fonts/icons.ttf)
      format("truetype");
}
html,
body {
  height: 100%;
}
.url {
  color: #0061da;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
}

.url:hover {
  color: #0056b3;
}

li.nav-item.dropdown:hover > .dropdown-menu {
  display: block;
  margin-top: 0;
}

.fixed-top .navbar-collapse {
  border-left: 1px solid #e2efd4;
}

.main-content {
  padding-top: 0;
}

.page-head-title {
  font-weight: 400;
  font-size: 26px;
}

h1.navbar-brand {
  background: none !important;
  line-height: 41px !important;
  padding-left: 0 !important;
  font-size: 22px !important;
  width: auto !important;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1230px;
  }
}

label {
  font-size: 14px;
  font-weight: 600;
}

.form-control {
  height: 35px;
}

.clearfix {
  clear: both;
  display: block;
  width: 100%;
}

.form_cover {
  padding: 20px 10px;
  border: 1px solid #c1c1c1;
  background: #fff;
  border-radius: 5px;
  margin: 0;
  box-shadow: 2px 3px 0px #c1c1c1;
}

.btn {
  margin-right: 5px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0px 0px 5px #000;
}

input:checked + .slider {
  background-color: #66c500;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

select.form-control:not([size]):not([multiple]) {
  height: 40px;
  padding-top: 5px;
}

.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker__input-container {
  width: 100%;
}

.require_field {
  color: #ff0000;
  font-size: 11px;
  margin-top: 5px;
  display: inline-block;
}

label.required::after {
  color: #ff0000;
  font-size: 14px;
  content: " *";
}

.cell-wrap-text {
  white-space: normal !important;
}

.pivotHeader th {
  background-color: darkmagenta;
  color: #fff;
}

.ui.table td.positive,
.ui.table tr.positive {
  background: transparent !important;
  color: #0061da !important;
}

.ui.segment {
  position: relative;
  background: transparent;
  box-shadow: 0 1px 2px 0 rgba(34, 36, 38, 0.15);
  margin: 1rem 0;
  border-radius: 0.28571429rem;
  /* border: 1px solid rgba(34,36,38,.15); */
  padding: 0em;
  padding-left: 0.5em;
}

.grid_height_fix {
  height: 170px !important;
}
.grid_height_200 {
  height: 200px !important;
}
.grid_height_250 {
  height: 250px !important;
}
.grid_height_500 {
  height: 500px !important;
}
.grid_height_600 {
  height: 600px !important;
}
.grid_height_750 {
  height: 750px !important;
}

.ui.table td.positive,
.ui.table tr.positive {
  background: transparent !important;
  color: #0061da !important;
  /* font-weight: bold; */
}

.ui.table td.negative,
.ui.table tr.negative {
  background: transparent !important;
  color: red !important;
  /* font-weight: bold; */
}

.ag-bl-full-height {
  height: 100% !important;
  overflow: auto;
  position: relative;
}

@media (max-width: 1366px) {
  .width700 {
    max-width: 578px;
  }
}
@media (max-width: 1359px) {
  .width700 {
    max-width: 578px;
  }
}

.width700 {
  width: 675px;
}
.fullwidth {
  width: 100%;
}
.height240 {
  height: 240px !important;
}
.height400 {
  height: 400px !important;
}
.height700 {
  height: 700px !important;
}
.height250 {
  height: 250px !important;
}
.row {
  padding-bottom: 0.3em !important;
  margin-bottom: 0rem !important;
}
.modal {
  bottom: unset !important;
}
.negative {
  color: red;
  /* font-weight: bold; */
}
.positive {
  color: #0061da !important;
  /* font-weight: bold; */
}

.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}
.panel-default {
  border-color: #ddd;
}
.panel-default > .panel-heading {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;
}
.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.panel-body {
  padding: unset;
}
.panel-body-2 {
  padding: 15px;
}

@media (min-width: 768px) {
  .col-md-3 {
    flex: 0 0 25%;
    max-width: unset;
  }
}

.ui.table td.positive,
.ui.table tr.positive {
  background: transparent !important;
  color: #0061da !important;
}
.customHeaderTable {
  padding: 0.8em 0.7em !important;
}

.ui.modal > .close {
  color: #000 !important;
}

.ui.positive.button,
.ui.positive.buttons .button {
  color: #fff !important;
}
th,
.capitalizetext {
  text-transform: uppercase !important;
}

.ui.compact.table th {
  padding-left: 0.7em;
  padding-right: 0.7em;
  text-align: center;
}

.positivetableheader th {
  background-color: #0061da !important;
  color: #fff !important;
}
.negativetableheader th {
  /* background-color: #db2828 !important; */
  background-color: #dc3545 !important;
  color: #fff !important;
}

.negativeborder {
  border-color: #dc3545 !important;
}
.positiveborder {
  border-color: #0061da !important;
}
.positivecell {
  background-color: #0061da !important;
  color: #fff !important;
}

.negativecell {
  /* background-color: #db2828 !important; */
  background-color: #dc3545 !important;
  color: #fff !important;
}

.pivotsubheader {
  text-transform: uppercase;
  text-align: center !important;
  font-weight: bold;
}
.zindex9999 {
  z-index: -9999;
}

.nocontainer {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .nocontainer {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .nocontainer {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .nocontainer {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .nocontainer {
    max-width: 1140px;
  }
}

.norow {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

.ui.modal > .header {
  background: #005689;
  color: #fff;
}

.disabledOpacity {
  opacity: unset !important;
}

.segmentheader {
  background: #005689 !important;
  color: #fff !important;
}
.alignright > input {
  text-align: right;
}
.alignright input[type="text"] {
  text-align: right;
}
.menulogo {
  background-color: #9cc8ea !important;
  color: #005689 !important;
  font-weight: bolder !important;
  font-size: smaller !important;
}

.support {
  color: #0061da;
}
.resistance {
  color: red;
}
.boldletters {
  font-weight: bold;
}
.bolditalicgreen {
  font-weight: bold;
  font-style: italic;
  color: green;
}

.ui.inverted.blue.menu,
.ui.inverted.menu .blue.active.item {
  background-color: #0061da;
}

.ui.blue.button,
.ui.blue.buttons .button {
  background-color: #0061da;
}

/* .ui.red.button, .ui.red.buttons .button {
    background-color: #db2828;
    color: #fff;
    text-shadow: none;
    background-image: none;
} */

.ui.basic.table {
  background-color: white;

  border: 1px solid navy;
}
.width500 {
  width: 500px !important;
}

img.bg {
  background-image: url(/static/media/12.939d83f6.png);
  background-position: 100;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.4;
}
/* .bg > img{
 
} */

.padding10 {
  padding: 10px;
}

.width0 {
  width: 0px !important;
}
.lightgray {
  background-color: #89cff0 !important;
  color: black !important;
}

.colorwhite {
  color: white !important;
}

.ui.form .required.field > .checkbox:after,
.ui.form .required.field > label:after,
.ui.form .required.fields.grouped > label:after,
.ui.form .required.fields:not(.grouped) > .field > .checkbox:after,
.ui.form .required.fields:not(.grouped) > .field > label:after {
  margin: -0.2em 0 0 0.2em;
  content: "*";
  color: #db2828;
  display: none !important;
}

.whitebg {
  background-color: white !important;
}
.margintop0 {
  margin-top: 0 !important;
}
.padding5 {
  padding: 5px !important;
}
.colorblack {
  color: black !important;
}
.react-stockcharts-tooltip,
.react-stockcharts-y-axis {
  /* pointer-events: all;
    cursor: pointer; */
  font-size: small;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
}

.menubg {
  background-color: #005689 !important;
}

.nomargin {
  margin: 0 !important;
}

.ui.grid {
  margin-top: -0.5rem !important;
}

@media (min-width: 1200px) {
  .ui.container {
    margin-top: 5px;
  }
}
.ui.grid > .row {
  padding-top: 0.3rem;
  /* margin-top: 0.7rem !important; */
}

.paddingtop {
  padding-top: 1rem !important;
}
/* .paddingtop>.row{

padding-top: 0.3rem !important;
} */

.maindata {
  font-size: 12px;
  /* padding-right: 4px;
    color: black; */
}
.inr {
  font-size: 10px;
  color: rgba(0, 0, 0, 0.62);
}
.datachangenegative {
  font-size: 10px;
  color: #d23f31;
}
.datachangepositive {
  font-size: 10px;
  color: #0f9d58;
}

.ui.placeholder.segment .field,
.ui.placeholder.segment .button {
  max-width: unset !important;
}

.cell-wrap-text {
  white-space: normal !important;
}

.alignright {
  text-align: right;
}

#chart-container {
  width: 100%;
  height: 450px;
  min-height: 450px;
  min-width: 850px;
}

.demo-editor {
  height: 275px !important;
  border: 1px solid #f1f1f1 !important;
  padding: 5px !important;
  border-radius: 2px !important;
}

.marginbottom0 {
  margin-bottom: 0rem !important ;
}
.nseoptionmargin {
  margin: 0.1em 0 !important;
}

.ui.fullscreen.modal {
  width: 95% !important;
  left: auto !important;
  /* margin: 1em auto; */
}

.ui.fullscreen.modal > .close {
  color: white !important;
}

.fullwidth {
  margin-top: 0.2rem;
}

/* .ag-header-cell-label .ag-header-cell-text{
    word-wrap: break-word;
    word-break: break-all;
    overflow-wrap: break-word;
    white-space: normal !important;
} */

.ag-cell-label-container {
  /*Necessary to allow for text to grow vertically*/
  height: 100%;
}

.ag-header-cell-label {
  /*Necessary to allow for text to grow vertically*/
  height: 100%;
  padding: 0 !important;
}

.ag-header-cell-label .ag-header-cell-text {
  /*Force the width corresponding at how much width
    we need once the text is layed out vertically*/
  /* writing-mode: vertical-lr; */
  line-height: 3em;
  white-space: normal !important;
}

.ui.feed > .event > .content .extra.text {
  max-width: none !important;
}

.scrollingdiv {
  overflow: scroll;
  height: 800px;
}
.ui.dimmer {
  background-color: rgba(0, 0, 0, -0.1) !important;
  /* background-color: #66c500 !important; */
}
.ui.dimmer.dimdim {
  background-color: #89cff0 !important;
}

.block-ui-overlay {
  width: 100%;
  height: 100%;
  opacity: 0.9;
  filter: alpha(opacity=50);
  background-color: white;
}

.colorwhite {
  color: white !important;
}

.padding30 {
  padding: 15px !important;
}

.noborder {
  border: none !important;
}
.inputborder input[type="text"] {
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
}
.padding2 {
  padding-right: 15px !important;
  padding-left: 15px !important;
  padding-bottom: 15px !important;
}

@font-face {
    font-family: Material Icons;
    src: url(https://cdnjs.cloudflare.com/ajax/libs/material-design-iconic-font/2.2.0/fonts/Material-Design-Iconic-Font.woff2) format('woff2'),url(https://cdnjs.cloudflare.com/ajax/libs/material-design-iconic-font/2.2.0/Material-Design-Iconic-Font.woff) format('woff'),url(https://cdnjs.cloudflare.com/ajax/libs/material-design-iconic-font/2.2.0/Material-Design-Iconic-Font.ttf) format('truetype');
  }
  
  .mdi {
    display: inline-block;
    font: normal normal normal 14px/1 'Material Icons';
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .mdi-hc-lg {
    font-size: 1.33333333em;
    line-height: .75em;
    vertical-align: -15%;
  }
  
  .mdi-hc-2x {
    font-size: 2em;
  }
  
  .mdi-hc-3x {
    font-size: 3em;
  }
  
  .mdi-hc-4x {
    font-size: 4em;
  }
  
  .mdi-hc-5x {
    font-size: 5em;
  }
  
  .mdi-hc-fw {
    width: 1.28571429em;
    text-align: center;
  }
  
  .mdi-hc-ul {
    padding-left: 0;
    margin-left: 2.14285714em;
    list-style-type: none;
  }
  
  .mdi-hc-ul>li {
    position: relative;
  }
  
  .mdi-hc-li {
    position: absolute;
    left: -2.14285714em;
    width: 2.14285714em;
    top: .14285714em;
    text-align: center;
  }
  
  .mdi-hc-li.mdi-hc-lg {
    left: -1.85714286em;
  }
  
  .mdi-hc-border {
    padding: .1em .25em;
    border: solid .1em #9e9e9e;
    border-radius: 2px;
  }
  
  .mdi-hc-border-circle {
    padding: .1em .25em;
    border: solid .1em #9e9e9e;
    border-radius: 50%;
  }
  
  .mdi.pull-left {
    float: left;
    margin-right: .15em;
  }
  
  .mdi.pull-right {
    float: right;
    margin-left: .15em;
  }
  
  .mdi-hc-spin {
    -webkit-animation: zmdi-spin 1.5s infinite linear;
    animation: zmdi-spin 1.5s infinite linear;
  }
  
  .mdi-hc-spin-reverse {
    -webkit-animation: zmdi-spin-reverse 1.5s infinite linear;
    animation: zmdi-spin-reverse 1.5s infinite linear;
  }
  
  @-webkit-keyframes zmdi-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
  
  @keyframes zmdi-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
    }
  }
  
  @-webkit-keyframes zmdi-spin-reverse {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(-359deg);
      transform: rotate(-359deg);
    }
  }
  
  @keyframes zmdi-spin-reverse {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
  
    100% {
      -webkit-transform: rotate(-359deg);
      transform: rotate(-359deg);
    }
  }
  
  .mdi-hc-rotate-90 {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  
  .mdi-hc-rotate-180 {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  
  .mdi-hc-rotate-270 {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  
  .mdi-hc-flip-horizontal {
    -webkit-transform: scale(-1,1);
    transform: scale(-1,1);
  }
  
  .mdi-hc-flip-vertical {
    -webkit-transform: scale(1,-1);
    transform: scale(1,-1);
  }
  
  .mdi-hc-stack {
    position: relative;
    display: inline-block;
    width: 2em;
    height: 2em;
    line-height: 2em;
    vertical-align: middle;
  }
  
  .mdi-hc-stack-1x,.mdi-hc-stack-2x {
    position: absolute;
    left: 0;
    width: 100%;
    text-align: center;
  }
  
  .mdi-hc-stack-1x {
    line-height: inherit;
  }
  
  .mdi-hc-stack-2x {
    font-size: 2em;
  }
  
  .mdi-hc-inverse {
    color: #fff;
  }
  
  .mdi-3d-rotation:before {
    content: '\F101';
  }
  
  .mdi-airplane-off:before {
    content: '\F102';
  }
  
  .mdi-airplane:before {
    content: '\F103';
  }
  
  .mdi-album:before {
    content: '\F104';
  }
  
  .mdi-archive:before {
    content: '\F105';
  }
  
  .mdi-assignment-account:before {
    content: '\F106';
  }
  
  .mdi-assignment-alert:before {
    content: '\F107';
  }
  
  .mdi-assignment-check:before {
    content: '\F108';
  }
  
  .mdi-assignment-o:before {
    content: '\F109';
  }
  
  .mdi-assignment-return:before {
    content: '\F10A';
  }
  
  .mdi-assignment-returned:before {
    content: '\F10B';
  }
  
  .mdi-assignment:before {
    content: '\F10C';
  }
  
  .mdi-attachment-alt:before {
    content: '\F10D';
  }
  
  .mdi-attachment:before {
    content: '\F10E';
  }
  
  .mdi-audio:before {
    content: '\F10F';
  }
  
  .mdi-badge-check:before {
    content: '\F110';
  }
  
  .mdi-balance-wallet:before {
    content: '\F111';
  }
  
  .mdi-balance:before {
    content: '\F112';
  }
  
  .mdi-battery-alert:before {
    content: '\F113';
  }
  
  .mdi-battery-flash:before {
    content: '\F114';
  }
  
  .mdi-battery-unknown:before {
    content: '\F115';
  }
  
  .mdi-battery:before {
    content: '\F116';
  }
  
  .mdi-bike:before {
    content: '\F117';
  }
  
  .mdi-block-alt:before {
    content: '\F118';
  }
  
  .mdi-block:before {
    content: '\F119';
  }
  
  .mdi-boat:before {
    content: '\F11A';
  }
  
  .mdi-book-image:before {
    content: '\F11B';
  }
  
  .mdi-book:before {
    content: '\F11C';
  }
  
  .mdi-bookmark-outline:before {
    content: '\F11D';
  }
  
  .mdi-bookmark:before {
    content: '\F11E';
  }
  
  .mdi-brush:before {
    content: '\F11F';
  }
  
  .mdi-bug:before {
    content: '\F120';
  }
  
  .mdi-bus:before {
    content: '\F121';
  }
  
  .mdi-cake:before {
    content: '\F122';
  }
  
  .mdi-car-taxi:before {
    content: '\F123';
  }
  
  .mdi-car-wash:before {
    content: '\F124';
  }
  
  .mdi-car:before {
    content: '\F125';
  }
  
  .mdi-card-giftcard:before {
    content: '\F126';
  }
  
  .mdi-card-membership:before {
    content: '\F127';
  }
  
  .mdi-card-travel:before {
    content: '\F128';
  }
  
  .mdi-card:before {
    content: '\F129';
  }
  
  .mdi-case-check:before {
    content: '\F12A';
  }
  
  .mdi-case-download:before {
    content: '\F12B';
  }
  
  .mdi-case-play:before {
    content: '\F12C';
  }
  
  .mdi-case:before {
    content: '\F12D';
  }
  
  .mdi-cast-connected:before {
    content: '\F12E';
  }
  
  .mdi-cast:before {
    content: '\F12F';
  }
  
  .mdi-chart-donut:before {
    content: '\F130';
  }
  
  .mdi-chart:before {
    content: '\F131';
  }
  
  .mdi-city-alt:before {
    content: '\F132';
  }
  
  .mdi-city:before {
    content: '\F133';
  }
  
  .mdi-close-circle-o:before {
    content: '\F134';
  }
  
  .mdi-close-circle:before {
    content: '\F135';
  }
  
  .mdi-close:before {
    content: '\F136';
  }
  
  .mdi-cocktail:before {
    content: '\F137';
  }
  
  .mdi-code-setting:before {
    content: '\F138';
  }
  
  .mdi-code-smartphone:before {
    content: '\F139';
  }
  
  .mdi-code:before {
    content: '\F13A';
  }
  
  .mdi-coffee:before {
    content: '\F13B';
  }
  
  .mdi-collection-bookmark:before {
    content: '\F13C';
  }
  
  .mdi-collection-case-play:before {
    content: '\F13D';
  }
  
  .mdi-collection-folder-image:before {
    content: '\F13E';
  }
  
  .mdi-collection-image-o:before {
    content: '\F13F';
  }
  
  .mdi-collection-image:before {
    content: '\F140';
  }
  
  .mdi-collection-item-1:before {
    content: '\F141';
  }
  
  .mdi-collection-item-2:before {
    content: '\F142';
  }
  
  .mdi-collection-item-3:before {
    content: '\F143';
  }
  
  .mdi-collection-item-4:before {
    content: '\F144';
  }
  
  .mdi-collection-item-5:before {
    content: '\F145';
  }
  
  .mdi-collection-item-6:before {
    content: '\F146';
  }
  
  .mdi-collection-item-7:before {
    content: '\F147';
  }
  
  .mdi-collection-item-8:before {
    content: '\F148';
  }
  
  .mdi-collection-item-9-plus:before {
    content: '\F149';
  }
  
  .mdi-collection-item-9:before {
    content: '\F14A';
  }
  
  .mdi-collection-item:before {
    content: '\F14B';
  }
  
  .mdi-collection-music:before {
    content: '\F14C';
  }
  
  .mdi-collection-pdf:before {
    content: '\F14D';
  }
  
  .mdi-collection-plus:before {
    content: '\F14E';
  }
  
  .mdi-collection-speaker:before {
    content: '\F14F';
  }
  
  .mdi-collection-text:before {
    content: '\F150';
  }
  
  .mdi-collection-video:before {
    content: '\F151';
  }
  
  .mdi-compass:before {
    content: '\F152';
  }
  
  .mdi-cutlery:before {
    content: '\F153';
  }
  
  .mdi-delete:before {
    content: '\F154';
  }
  
  .mdi-dialpad:before {
    content: '\F155';
  }
  
  .mdi-dns:before {
    content: '\F156';
  }
  
  .mdi-drink:before {
    content: '\F157';
  }
  
  .mdi-edit:before {
    content: '\F158';
  }
  
  .mdi-email-open:before {
    content: '\F159';
  }
  
  .mdi-email:before {
    content: '\F15A';
  }
  
  .mdi-eye-off:before {
    content: '\F15B';
  }
  
  .mdi-eye:before {
    content: '\F15C';
  }
  
  .mdi-eyedropper:before {
    content: '\F15D';
  }
  
  .mdi-favorite-outline:before {
    content: '\F15E';
  }
  
  .mdi-favorite:before {
    content: '\F15F';
  }
  
  .mdi-filter-list:before {
    content: '\F160';
  }
  
  .mdi-fire:before {
    content: '\F161';
  }
  
  .mdi-flag:before {
    content: '\F162';
  }
  
  .mdi-flare:before {
    content: '\F163';
  }
  
  .mdi-flash-auto:before {
    content: '\F164';
  }
  
  .mdi-flash-off:before {
    content: '\F165';
  }
  
  .mdi-flash:before {
    content: '\F166';
  }
  
  .mdi-flip:before {
    content: '\F167';
  }
  
  .mdi-flower-alt:before {
    content: '\F168';
  }
  
  .mdi-flower:before {
    content: '\F169';
  }
  
  .mdi-font:before {
    content: '\F16A';
  }
  
  .mdi-fullscreen-alt:before {
    content: '\F16B';
  }
  
  .mdi-fullscreen-exit:before {
    content: '\F16C';
  }
  
  .mdi-fullscreen:before {
    content: '\F16D';
  }
  
  .mdi-functions:before {
    content: '\F16E';
  }
  
  .mdi-gas-station:before {
    content: '\F16F';
  }
  
  .mdi-gesture:before {
    content: '\F170';
  }
  
  .mdi-globe-alt:before {
    content: '\F171';
  }
  
  .mdi-globe-lock:before {
    content: '\F172';
  }
  
  .mdi-globe:before {
    content: '\F173';
  }
  
  .mdi-graduation-cap:before {
    content: '\F174';
  }
  
  .mdi-home:before {
    content: '\F175';
  }
  
  .mdi-hospital-alt:before {
    content: '\F176';
  }
  
  .mdi-hospital:before {
    content: '\F177';
  }
  
  .mdi-hotel:before {
    content: '\F178';
  }
  
  .mdi-hourglass-alt:before {
    content: '\F179';
  }
  
  .mdi-hourglass-outline:before {
    content: '\F17A';
  }
  
  .mdi-hourglass:before {
    content: '\F17B';
  }
  
  .mdi-http:before {
    content: '\F17C';
  }
  
  .mdi-image-alt:before {
    content: '\F17D';
  }
  
  .mdi-image-o:before {
    content: '\F17E';
  }
  
  .mdi-image:before {
    content: '\F17F';
  }
  
  .mdi-inbox:before {
    content: '\F180';
  }
  
  .mdi-invert-colors-off:before {
    content: '\F181';
  }
  
  .mdi-invert-colors:before {
    content: '\F182';
  }
  
  .mdi-key:before {
    content: '\F183';
  }
  
  .mdi-label-alt-outline:before {
    content: '\F184';
  }
  
  .mdi-label-alt:before {
    content: '\F185';
  }
  
  .mdi-label-heart:before {
    content: '\F186';
  }
  
  .mdi-label:before {
    content: '\F187';
  }
  
  .mdi-labels:before {
    content: '\F188';
  }
  
  .mdi-lamp:before {
    content: '\F189';
  }
  
  .mdi-landscape:before {
    content: '\F18A';
  }
  
  .mdi-layers-off:before {
    content: '\F18B';
  }
  
  .mdi-layers:before {
    content: '\F18C';
  }
  
  .mdi-library:before {
    content: '\F18D';
  }
  
  .mdi-link:before {
    content: '\F18E';
  }
  
  .mdi-lock-open:before {
    content: '\F18F';
  }
  
  .mdi-lock-outline:before {
    content: '\F190';
  }
  
  .mdi-lock:before {
    content: '\F191';
  }
  
  .mdi-mail-reply-all:before {
    content: '\F192';
  }
  
  .mdi-mail-reply:before {
    content: '\F193';
  }
  
  .mdi-mail-send:before {
    content: '\F194';
  }
  
  .mdi-mall:before {
    content: '\F195';
  }
  
  .mdi-map:before {
    content: '\F196';
  }
  
  .mdi-menu:before {
    content: '\F197';
  }
  
  .mdi-money-box:before {
    content: '\F198';
  }
  
  .mdi-money-off:before {
    content: '\F199';
  }
  
  .mdi-money:before {
    content: '\F19A';
  }
  
  .mdi-more-vert:before {
    content: '\F19B';
  }
  
  .mdi-more:before {
    content: '\F19C';
  }
  
  .mdi-movie-alt:before {
    content: '\F19D';
  }
  
  .mdi-movie:before {
    content: '\F19E';
  }
  
  .mdi-nature-people:before {
    content: '\F19F';
  }
  
  .mdi-nature:before {
    content: '\F1A0';
  }
  
  .mdi-navigation:before {
    content: '\F1A1';
  }
  
  .mdi-open-in-browser:before {
    content: '\F1A2';
  }
  
  .mdi-open-in-new:before {
    content: '\F1A3';
  }
  
  .mdi-palette:before {
    content: '\F1A4';
  }
  
  .mdi-parking:before {
    content: '\F1A5';
  }
  
  .mdi-pin-account:before {
    content: '\F1A6';
  }
  
  .mdi-pin-assistant:before {
    content: '\F1A7';
  }
  
  .mdi-pin-drop:before {
    content: '\F1A8';
  }
  
  .mdi-pin-help:before {
    content: '\F1A9';
  }
  
  .mdi-pin-off:before {
    content: '\F1AA';
  }
  
  .mdi-pin:before {
    content: '\F1AB';
  }
  
  .mdi-pizza:before {
    content: '\F1AC';
  }
  
  .mdi-plaster:before {
    content: '\F1AD';
  }
  
  .mdi-power-setting:before {
    content: '\F1AE';
  }
  
  .mdi-power:before {
    content: '\F1AF';
  }
  
  .mdi-print:before {
    content: '\F1B0';
  }
  
  .mdi-puzzle-piece:before {
    content: '\F1B1';
  }
  
  .mdi-quote:before {
    content: '\F1B2';
  }
  
  .mdi-railway:before {
    content: '\F1B3';
  }
  
  .mdi-receipt:before {
    content: '\F1B4';
  }
  
  .mdi-refresh-alt:before {
    content: '\F1B5';
  }
  
  .mdi-refresh-sync-alert:before {
    content: '\F1B6';
  }
  
  .mdi-refresh-sync-off:before {
    content: '\F1B7';
  }
  
  .mdi-refresh-sync:before {
    content: '\F1B8';
  }
  
  .mdi-refresh:before {
    content: '\F1B9';
  }
  
  .mdi-roller:before {
    content: '\F1BA';
  }
  
  .mdi-ruler:before {
    content: '\F1BB';
  }
  
  .mdi-scissors:before {
    content: '\F1BC';
  }
  
  .mdi-screen-rotation-lock:before {
    content: '\F1BD';
  }
  
  .mdi-screen-rotation:before {
    content: '\F1BE';
  }
  
  .mdi-search-for:before {
    content: '\F1BF';
  }
  
  .mdi-search-in-file:before {
    content: '\F1C0';
  }
  
  .mdi-search-in-page:before {
    content: '\F1C1';
  }
  
  .mdi-search-replace:before {
    content: '\F1C2';
  }
  
  .mdi-search:before {
    content: '\F1C3';
  }
  
  .mdi-seat:before {
    content: '\F1C4';
  }
  
  .mdi-settings-square:before {
    content: '\F1C5';
  }
  
  .mdi-settings:before {
    content: '\F1C6';
  }
  
  .mdi-shield-check:before {
    content: '\F1C7';
  }
  
  .mdi-shield-security:before {
    content: '\F1C8';
  }
  
  .mdi-shopping-basket:before {
    content: '\F1C9';
  }
  
  .mdi-shopping-cart-plus:before {
    content: '\F1CA';
  }
  
  .mdi-shopping-cart:before {
    content: '\F1CB';
  }
  
  .mdi-sign-in:before {
    content: '\F1CC';
  }
  
  .mdi-sort-amount-asc:before {
    content: '\F1CD';
  }
  
  .mdi-sort-amount-desc:before {
    content: '\F1CE';
  }
  
  .mdi-sort-asc:before {
    content: '\F1CF';
  }
  
  .mdi-sort-desc:before {
    content: '\F1D0';
  }
  
  .mdi-spellcheck:before {
    content: '\F1D1';
  }
  
  .mdi-storage:before {
    content: '\F1D2';
  }
  
  .mdi-store-24:before {
    content: '\F1D3';
  }
  
  .mdi-store:before {
    content: '\F1D4';
  }
  
  .mdi-subway:before {
    content: '\F1D5';
  }
  
  .mdi-sun:before {
    content: '\F1D6';
  }
  
  .mdi-tab-unselected:before {
    content: '\F1D7';
  }
  
  .mdi-tab:before {
    content: '\F1D8';
  }
  
  .mdi-tag-close:before {
    content: '\F1D9';
  }
  
  .mdi-tag-more:before {
    content: '\F1DA';
  }
  
  .mdi-tag:before {
    content: '\F1DB';
  }
  
  .mdi-thumb-down:before {
    content: '\F1DC';
  }
  
  .mdi-thumb-up-down:before {
    content: '\F1DD';
  }
  
  .mdi-thumb-up:before {
    content: '\F1DE';
  }
  
  .mdi-ticket-star:before {
    content: '\F1DF';
  }
  
  .mdi-toll:before {
    content: '\F1E0';
  }
  
  .mdi-toys:before {
    content: '\F1E1';
  }
  
  .mdi-traffic:before {
    content: '\F1E2';
  }
  
  .mdi-translate:before {
    content: '\F1E3';
  }
  
  .mdi-triangle-down:before {
    content: '\F1E4';
  }
  
  .mdi-triangle-up:before {
    content: '\F1E5';
  }
  
  .mdi-truck:before {
    content: '\F1E6';
  }
  
  .mdi-turning-sign:before {
    content: '\F1E7';
  }
  
  .mdi-wallpaper:before {
    content: '\F1E8';
  }
  
  .mdi-washing-machine:before {
    content: '\F1E9';
  }
  
  .mdi-window-maximize:before {
    content: '\F1EA';
  }
  
  .mdi-window-minimize:before {
    content: '\F1EB';
  }
  
  .mdi-window-restore:before {
    content: '\F1EC';
  }
  
  .mdi-wrench:before {
    content: '\F1ED';
  }
  
  .mdi-zoom-in:before {
    content: '\F1EE';
  }
  
  .mdi-zoom-out:before {
    content: '\F1EF';
  }
  
  .mdi-alert-circle-o:before {
    content: '\F1F0';
  }
  
  .mdi-alert-circle:before {
    content: '\F1F1';
  }
  
  .mdi-alert-octagon:before {
    content: '\F1F2';
  }
  
  .mdi-alert-polygon:before {
    content: '\F1F3';
  }
  
  .mdi-alert-triangle:before {
    content: '\F1F4';
  }
  
  .mdi-help-outline:before {
    content: '\F1F5';
  }
  
  .mdi-help:before {
    content: '\F1F6';
  }
  
  .mdi-info-outline:before {
    content: '\F1F7';
  }
  
  .mdi-info:before {
    content: '\F1F8';
  }
  
  .mdi-notifications-active:before {
    content: '\F1F9';
  }
  
  .mdi-notifications-add:before {
    content: '\F1FA';
  }
  
  .mdi-notifications-none:before {
    content: '\F1FB';
  }
  
  .mdi-notifications-off:before {
    content: '\F1FC';
  }
  
  .mdi-notifications-paused:before {
    content: '\F1FD';
  }
  
  .mdi-notifications:before {
    content: '\F1FE';
  }
  
  .mdi-account-add:before {
    content: '\F1FF';
  }
  
  .mdi-account-box-mail:before {
    content: '\F200';
  }
  
  .mdi-account-box-o:before {
    content: '\F201';
  }
  
  .mdi-account-box-phone:before {
    content: '\F202';
  }
  
  .mdi-account-box:before {
    content: '\F203';
  }
  
  .mdi-account-calendar:before {
    content: '\F204';
  }
  
  .mdi-account-circle:before {
    content: '\F205';
  }
  
  .mdi-account-o:before {
    content: '\F206';
  }
  
  .mdi-account:before {
    content: '\F207';
  }
  
  .mdi-accounts-add:before {
    content: '\F208';
  }
  
  .mdi-accounts-alt:before {
    content: '\F209';
  }
  
  .mdi-accounts-list-alt:before {
    content: '\F20A';
  }
  
  .mdi-accounts-list:before {
    content: '\F20B';
  }
  
  .mdi-accounts-outline:before {
    content: '\F20C';
  }
  
  .mdi-accounts:before {
    content: '\F20D';
  }
  
  .mdi-face:before {
    content: '\F20E';
  }
  
  .mdi-female:before {
    content: '\F20F';
  }
  
  .mdi-male-alt:before {
    content: '\F210';
  }
  
  .mdi-male-female:before {
    content: '\F211';
  }
  
  .mdi-male:before {
    content: '\F212';
  }
  
  .mdi-mood-bad:before {
    content: '\F213';
  }
  
  .mdi-mood:before {
    content: '\F214';
  }
  
  .mdi-run:before {
    content: '\F215';
  }
  
  .mdi-walk:before {
    content: '\F216';
  }
  
  .mdi-cloud-box:before {
    content: '\F217';
  }
  
  .mdi-cloud-circle:before {
    content: '\F218';
  }
  
  .mdi-cloud-done:before {
    content: '\F219';
  }
  
  .mdi-cloud-download:before {
    content: '\F21A';
  }
  
  .mdi-cloud-off:before {
    content: '\F21B';
  }
  
  .mdi-cloud-outline-alt:before {
    content: '\F21C';
  }
  
  .mdi-cloud-outline:before {
    content: '\F21D';
  }
  
  .mdi-cloud-upload:before {
    content: '\F21E';
  }
  
  .mdi-cloud:before {
    content: '\F21F';
  }
  
  .mdi-download:before {
    content: '\F220';
  }
  
  .mdi-file-plus:before {
    content: '\F221';
  }
  
  .mdi-file-text:before {
    content: '\F222';
  }
  
  .mdi-file:before {
    content: '\F223';
  }
  
  .mdi-folder-outline:before {
    content: '\F224';
  }
  
  .mdi-folder-person:before {
    content: '\F225';
  }
  
  .mdi-folder-star-alt:before {
    content: '\F226';
  }
  
  .mdi-folder-star:before {
    content: '\F227';
  }
  
  .mdi-folder:before {
    content: '\F228';
  }
  
  .mdi-gif:before {
    content: '\F229';
  }
  
  .mdi-upload:before {
    content: '\F22A';
  }
  
  .mdi-border-all:before {
    content: '\F22B';
  }
  
  .mdi-border-bottom:before {
    content: '\F22C';
  }
  
  .mdi-border-clear:before {
    content: '\F22D';
  }
  
  .mdi-border-color:before {
    content: '\F22E';
  }
  
  .mdi-border-horizontal:before {
    content: '\F22F';
  }
  
  .mdi-border-inner:before {
    content: '\F230';
  }
  
  .mdi-border-left:before {
    content: '\F231';
  }
  
  .mdi-border-outer:before {
    content: '\F232';
  }
  
  .mdi-border-right:before {
    content: '\F233';
  }
  
  .mdi-border-style:before {
    content: '\F234';
  }
  
  .mdi-border-top:before {
    content: '\F235';
  }
  
  .mdi-border-vertical:before {
    content: '\F236';
  }
  
  .mdi-copy:before {
    content: '\F237';
  }
  
  .mdi-crop:before {
    content: '\F238';
  }
  
  .mdi-format-align-center:before {
    content: '\F239';
  }
  
  .mdi-format-align-justify:before {
    content: '\F23A';
  }
  
  .mdi-format-align-left:before {
    content: '\F23B';
  }
  
  .mdi-format-align-right:before {
    content: '\F23C';
  }
  
  .mdi-format-bold:before {
    content: '\F23D';
  }
  
  .mdi-format-clear-all:before {
    content: '\F23E';
  }
  
  .mdi-format-clear:before {
    content: '\F23F';
  }
  
  .mdi-format-color-fill:before {
    content: '\F240';
  }
  
  .mdi-format-color-reset:before {
    content: '\F241';
  }
  
  .mdi-format-color-text:before {
    content: '\F242';
  }
  
  .mdi-format-indent-decrease:before {
    content: '\F243';
  }
  
  .mdi-format-indent-increase:before {
    content: '\F244';
  }
  
  .mdi-format-italic:before {
    content: '\F245';
  }
  
  .mdi-format-line-spacing:before {
    content: '\F246';
  }
  
  .mdi-format-list-bulleted:before {
    content: '\F247';
  }
  
  .mdi-format-list-numbered:before {
    content: '\F248';
  }
  
  .mdi-format-ltr:before {
    content: '\F249';
  }
  
  .mdi-format-rtl:before {
    content: '\F24A';
  }
  
  .mdi-format-size:before {
    content: '\F24B';
  }
  
  .mdi-format-strikethrough-s:before {
    content: '\F24C';
  }
  
  .mdi-format-strikethrough:before {
    content: '\F24D';
  }
  
  .mdi-format-subject:before {
    content: '\F24E';
  }
  
  .mdi-format-underlined:before {
    content: '\F24F';
  }
  
  .mdi-format-valign-bottom:before {
    content: '\F250';
  }
  
  .mdi-format-valign-center:before {
    content: '\F251';
  }
  
  .mdi-format-valign-top:before {
    content: '\F252';
  }
  
  .mdi-redo:before {
    content: '\F253';
  }
  
  .mdi-select-all:before {
    content: '\F254';
  }
  
  .mdi-space-bar:before {
    content: '\F255';
  }
  
  .mdi-text-format:before {
    content: '\F256';
  }
  
  .mdi-transform:before {
    content: '\F257';
  }
  
  .mdi-undo:before {
    content: '\F258';
  }
  
  .mdi-wrap-text:before {
    content: '\F259';
  }
  
  .mdi-comment-alert:before {
    content: '\F25A';
  }
  
  .mdi-comment-alt-text:before {
    content: '\F25B';
  }
  
  .mdi-comment-alt:before {
    content: '\F25C';
  }
  
  .mdi-comment-edit:before {
    content: '\F25D';
  }
  
  .mdi-comment-image:before {
    content: '\F25E';
  }
  
  .mdi-comment-list:before {
    content: '\F25F';
  }
  
  .mdi-comment-more:before {
    content: '\F260';
  }
  
  .mdi-comment-outline:before {
    content: '\F261';
  }
  
  .mdi-comment-text-alt:before {
    content: '\F262';
  }
  
  .mdi-comment-text:before {
    content: '\F263';
  }
  
  .mdi-comment-video:before {
    content: '\F264';
  }
  
  .mdi-comment:before {
    content: '\F265';
  }
  
  .mdi-comments:before {
    content: '\F266';
  }
  
  .mdi-check-all:before {
    content: '\F267';
  }
  
  .mdi-check-circle-u:before {
    content: '\F268';
  }
  
  .mdi-check-circle:before {
    content: '\F269';
  }
  
  .mdi-check-square:before {
    content: '\F26A';
  }
  
  .mdi-check:before {
    content: '\F26B';
  }
  
  .mdi-circle-o:before {
    content: '\F26C';
  }
  
  .mdi-circle:before {
    content: '\F26D';
  }
  
  .mdi-dot-circle-alt:before {
    content: '\F26E';
  }
  
  .mdi-dot-circle:before {
    content: '\F26F';
  }
  
  .mdi-minus-circle-outline:before {
    content: '\F270';
  }
  
  .mdi-minus-circle:before {
    content: '\F271';
  }
  
  .mdi-minus-square:before {
    content: '\F272';
  }
  
  .mdi-minus:before {
    content: '\F273';
  }
  
  .mdi-plus-circle-o-duplicate:before {
    content: '\F274';
  }
  
  .mdi-plus-circle-o:before {
    content: '\F275';
  }
  
  .mdi-plus-circle:before {
    content: '\F276';
  }
  
  .mdi-plus-square:before {
    content: '\F277';
  }
  
  .mdi-plus:before {
    content: '\F278';
  }
  
  .mdi-square-o:before {
    content: '\F279';
  }
  
  .mdi-star-circle:before {
    content: '\F27A';
  }
  
  .mdi-star-half:before {
    content: '\F27B';
  }
  
  .mdi-star-outline:before {
    content: '\F27C';
  }
  
  .mdi-star:before {
    content: '\F27D';
  }
  
  .mdi-bluetooth-connected:before {
    content: '\F27E';
  }
  
  .mdi-bluetooth-off:before {
    content: '\F27F';
  }
  
  .mdi-bluetooth-search:before {
    content: '\F280';
  }
  
  .mdi-bluetooth-setting:before {
    content: '\F281';
  }
  
  .mdi-bluetooth:before {
    content: '\F282';
  }
  
  .mdi-camera-add:before {
    content: '\F283';
  }
  
  .mdi-camera-alt:before {
    content: '\F284';
  }
  
  .mdi-camera-bw:before {
    content: '\F285';
  }
  
  .mdi-camera-front:before {
    content: '\F286';
  }
  
  .mdi-camera-mic:before {
    content: '\F287';
  }
  
  .mdi-camera-party-mode:before {
    content: '\F288';
  }
  
  .mdi-camera-rear:before {
    content: '\F289';
  }
  
  .mdi-camera-roll:before {
    content: '\F28A';
  }
  
  .mdi-camera-switch:before {
    content: '\F28B';
  }
  
  .mdi-camera:before {
    content: '\F28C';
  }
  
  .mdi-card-alert:before {
    content: '\F28D';
  }
  
  .mdi-card-off:before {
    content: '\F28E';
  }
  
  .mdi-card-sd:before {
    content: '\F28F';
  }
  
  .mdi-card-sim:before {
    content: '\F290';
  }
  
  .mdi-desktop-mac:before {
    content: '\F291';
  }
  
  .mdi-desktop-windows:before {
    content: '\F292';
  }
  
  .mdi-device-hub:before {
    content: '\F293';
  }
  
  .mdi-devices-off:before {
    content: '\F294';
  }
  
  .mdi-devices:before {
    content: '\F295';
  }
  
  .mdi-dock:before {
    content: '\F296';
  }
  
  .mdi-floppy:before {
    content: '\F297';
  }
  
  .mdi-gamepad:before {
    content: '\F298';
  }
  
  .mdi-gps-dot:before {
    content: '\F299';
  }
  
  .mdi-gps-off:before {
    content: '\F29A';
  }
  
  .mdi-gps:before {
    content: '\F29B';
  }
  
  .mdi-headset-mic:before {
    content: '\F29C';
  }
  
  .mdi-headset:before {
    content: '\F29D';
  }
  
  .mdi-input-antenna:before {
    content: '\F29E';
  }
  
  .mdi-input-composite:before {
    content: '\F29F';
  }
  
  .mdi-input-hdmi:before {
    content: '\F2A0';
  }
  
  .mdi-input-power:before {
    content: '\F2A1';
  }
  
  .mdi-input-svideo:before {
    content: '\F2A2';
  }
  
  .mdi-keyboard-hide:before {
    content: '\F2A3';
  }
  
  .mdi-keyboard:before {
    content: '\F2A4';
  }
  
  .mdi-laptop-chromebook:before {
    content: '\F2A5';
  }
  
  .mdi-laptop-mac:before {
    content: '\F2A6';
  }
  
  .mdi-laptop:before {
    content: '\F2A7';
  }
  
  .mdi-mic-off:before {
    content: '\F2A8';
  }
  
  .mdi-mic-outline:before {
    content: '\F2A9';
  }
  
  .mdi-mic-setting:before {
    content: '\F2AA';
  }
  
  .mdi-mic:before {
    content: '\F2AB';
  }
  
  .mdi-mouse:before {
    content: '\F2AC';
  }
  
  .mdi-network-alert:before {
    content: '\F2AD';
  }
  
  .mdi-network-locked:before {
    content: '\F2AE';
  }
  
  .mdi-network-off:before {
    content: '\F2AF';
  }
  
  .mdi-network-outline:before {
    content: '\F2B0';
  }
  
  .mdi-network-setting:before {
    content: '\F2B1';
  }
  
  .mdi-network:before {
    content: '\F2B2';
  }
  
  .mdi-phone-bluetooth:before {
    content: '\F2B3';
  }
  
  .mdi-phone-end:before {
    content: '\F2B4';
  }
  
  .mdi-phone-forwarded:before {
    content: '\F2B5';
  }
  
  .mdi-phone-in-talk:before {
    content: '\F2B6';
  }
  
  .mdi-phone-locked:before {
    content: '\F2B7';
  }
  
  .mdi-phone-missed:before {
    content: '\F2B8';
  }
  
  .mdi-phone-msg:before {
    content: '\F2B9';
  }
  
  .mdi-phone-paused:before {
    content: '\F2BA';
  }
  
  .mdi-phone-ring:before {
    content: '\F2BB';
  }
  
  .mdi-phone-setting:before {
    content: '\F2BC';
  }
  
  .mdi-phone-sip:before {
    content: '\F2BD';
  }
  
  .mdi-phone:before {
    content: '\F2BE';
  }
  
  .mdi-portable-wifi-changes:before {
    content: '\F2BF';
  }
  
  .mdi-portable-wifi-off:before {
    content: '\F2C0';
  }
  
  .mdi-portable-wifi:before {
    content: '\F2C1';
  }
  
  .mdi-radio:before {
    content: '\F2C2';
  }
  
  .mdi-reader:before {
    content: '\F2C3';
  }
  
  .mdi-remote-control-alt:before {
    content: '\F2C4';
  }
  
  .mdi-remote-control:before {
    content: '\F2C5';
  }
  
  .mdi-router:before {
    content: '\F2C6';
  }
  
  .mdi-scanner:before {
    content: '\F2C7';
  }
  
  .mdi-smartphone-android:before {
    content: '\F2C8';
  }
  
  .mdi-smartphone-download:before {
    content: '\F2C9';
  }
  
  .mdi-smartphone-erase:before {
    content: '\F2CA';
  }
  
  .mdi-smartphone-info:before {
    content: '\F2CB';
  }
  
  .mdi-smartphone-iphone:before {
    content: '\F2CC';
  }
  
  .mdi-smartphone-landscape-lock:before {
    content: '\F2CD';
  }
  
  .mdi-smartphone-landscape:before {
    content: '\F2CE';
  }
  
  .mdi-smartphone-lock:before {
    content: '\F2CF';
  }
  
  .mdi-smartphone-portrait-lock:before {
    content: '\F2D0';
  }
  
  .mdi-smartphone-ring:before {
    content: '\F2D1';
  }
  
  .mdi-smartphone-setting:before {
    content: '\F2D2';
  }
  
  .mdi-smartphone-setup:before {
    content: '\F2D3';
  }
  
  .mdi-smartphone:before {
    content: '\F2D4';
  }
  
  .mdi-speaker:before {
    content: '\F2D5';
  }
  
  .mdi-tablet-android:before {
    content: '\F2D6';
  }
  
  .mdi-tablet-mac:before {
    content: '\F2D7';
  }
  
  .mdi-tablet:before {
    content: '\F2D8';
  }
  
  .mdi-tv-alt-play:before {
    content: '\F2D9';
  }
  
  .mdi-tv-list:before {
    content: '\F2DA';
  }
  
  .mdi-tv-play:before {
    content: '\F2DB';
  }
  
  .mdi-tv:before {
    content: '\F2DC';
  }
  
  .mdi-usb:before {
    content: '\F2DD';
  }
  
  .mdi-videocam-off:before {
    content: '\F2DE';
  }
  
  .mdi-videocam-switch:before {
    content: '\F2DF';
  }
  
  .mdi-videocam:before {
    content: '\F2E0';
  }
  
  .mdi-watch:before {
    content: '\F2E1';
  }
  
  .mdi-wifi-alt-2:before {
    content: '\F2E2';
  }
  
  .mdi-wifi-alt:before {
    content: '\F2E3';
  }
  
  .mdi-wifi-info:before {
    content: '\F2E4';
  }
  
  .mdi-wifi-lock:before {
    content: '\F2E5';
  }
  
  .mdi-wifi-off:before {
    content: '\F2E6';
  }
  
  .mdi-wifi-outline:before {
    content: '\F2E7';
  }
  
  .mdi-wifi:before {
    content: '\F2E8';
  }
  
  .mdi-arrow-left-bottom:before {
    content: '\F2E9';
  }
  
  .mdi-arrow-left:before {
    content: '\F2EA';
  }
  
  .mdi-arrow-merge:before {
    content: '\F2EB';
  }
  
  .mdi-arrow-missed:before {
    content: '\F2EC';
  }
  
  .mdi-arrow-right-top:before {
    content: '\F2ED';
  }
  
  .mdi-arrow-right:before {
    content: '\F2EE';
  }
  
  .mdi-arrow-split:before {
    content: '\F2EF';
  }
  
  .mdi-arrows:before {
    content: '\F2F0';
  }
  
  .mdi-caret-down-circle:before {
    content: '\F2F1';
  }
  
  .mdi-caret-down:before {
    content: '\F2F2';
  }
  
  .mdi-caret-left-circle:before {
    content: '\F2F3';
  }
  
  .mdi-caret-left:before {
    content: '\F2F4';
  }
  
  .mdi-caret-right-circle:before {
    content: '\F2F5';
  }
  
  .mdi-caret-right:before {
    content: '\F2F6';
  }
  
  .mdi-caret-up-circle:before {
    content: '\F2F7';
  }
  
  .mdi-caret-up:before {
    content: '\F2F8';
  }
  
  .mdi-chevron-down:before {
    content: '\F2F9';
  }
  
  .mdi-chevron-left:before {
    content: '\F2FA';
  }
  
  .mdi-chevron-right:before {
    content: '\F2FB';
  }
  
  .mdi-chevron-up:before {
    content: '\F2FC';
  }
  
  .mdi-forward:before {
    content: '\F2FD';
  }
  
  .mdi-long-arrow-down:before {
    content: '\F2FE';
  }
  
  .mdi-long-arrow-left:before {
    content: '\F2FF';
  }
  
  .mdi-long-arrow-return:before {
    content: '\F300';
  }
  
  .mdi-long-arrow-right:before {
    content: '\F301';
  }
  
  .mdi-long-arrow-tab:before {
    content: '\F302';
  }
  
  .mdi-long-arrow-up:before {
    content: '\F303';
  }
  
  .mdi-rotate-ccw:before {
    content: '\F304';
  }
  
  .mdi-rotate-cw:before {
    content: '\F305';
  }
  
  .mdi-rotate-left:before {
    content: '\F306';
  }
  
  .mdi-rotate-right:before {
    content: '\F307';
  }
  
  .mdi-square-down:before {
    content: '\F308';
  }
  
  .mdi-square-right:before {
    content: '\F309';
  }
  
  .mdi-swap-alt:before {
    content: '\F30A';
  }
  
  .mdi-swap-vertical-circle:before {
    content: '\F30B';
  }
  
  .mdi-swap-vertical:before {
    content: '\F30C';
  }
  
  .mdi-swap:before {
    content: '\F30D';
  }
  
  .mdi-trending-down:before {
    content: '\F30E';
  }
  
  .mdi-trending-flat:before {
    content: '\F30F';
  }
  
  .mdi-trending-up:before {
    content: '\F310';
  }
  
  .mdi-unfold-less:before {
    content: '\F311';
  }
  
  .mdi-unfold-more:before {
    content: '\F312';
  }
  
  .mdi-apps:before {
    content: '\F313';
  }
  
  .mdi-grid-off:before {
    content: '\F314';
  }
  
  .mdi-grid:before {
    content: '\F315';
  }
  
  .mdi-view-agenda:before {
    content: '\F316';
  }
  
  .mdi-view-array:before {
    content: '\F317';
  }
  
  .mdi-view-carousel:before {
    content: '\F318';
  }
  
  .mdi-view-column:before {
    content: '\F319';
  }
  
  .mdi-view-comfy:before {
    content: '\F31A';
  }
  
  .mdi-view-compact:before {
    content: '\F31B';
  }
  
  .mdi-view-dashboard:before {
    content: '\F31C';
  }
  
  .mdi-view-day:before {
    content: '\F31D';
  }
  
  .mdi-view-headline:before {
    content: '\F31E';
  }
  
  .mdi-view-list-alt:before {
    content: '\F31F';
  }
  
  .mdi-view-list:before {
    content: '\F320';
  }
  
  .mdi-view-module:before {
    content: '\F321';
  }
  
  .mdi-view-quilt:before {
    content: '\F322';
  }
  
  .mdi-view-stream:before {
    content: '\F323';
  }
  
  .mdi-view-subtitles:before {
    content: '\F324';
  }
  
  .mdi-view-toc:before {
    content: '\F325';
  }
  
  .mdi-view-web:before {
    content: '\F326';
  }
  
  .mdi-view-week:before {
    content: '\F327';
  }
  
  .mdi-widgets:before {
    content: '\F328';
  }
  
  .mdi-alarm-check:before {
    content: '\F329';
  }
  
  .mdi-alarm-off:before {
    content: '\F32A';
  }
  
  .mdi-alarm-plus:before {
    content: '\F32B';
  }
  
  .mdi-alarm-snooze:before {
    content: '\F32C';
  }
  
  .mdi-alarm:before {
    content: '\F32D';
  }
  
  .mdi-calendar-alt:before {
    content: '\F32E';
  }
  
  .mdi-calendar-check:before {
    content: '\F32F';
  }
  
  .mdi-calendar-close:before {
    content: '\F330';
  }
  
  .mdi-calendar-note:before {
    content: '\F331';
  }
  
  .mdi-calendar:before {
    content: '\F332';
  }
  
  .mdi-time-countdown:before {
    content: '\F333';
  }
  
  .mdi-time-interval:before {
    content: '\F334';
  }
  
  .mdi-time-restore-setting:before {
    content: '\F335';
  }
  
  .mdi-time-restore:before {
    content: '\F336';
  }
  
  .mdi-time:before {
    content: '\F337';
  }
  
  .mdi-timer-off:before {
    content: '\F338';
  }
  
  .mdi-timer:before {
    content: '\F339';
  }
  
  .mdi-android-alt:before {
    content: '\F33A';
  }
  
  .mdi-android:before {
    content: '\F33B';
  }
  
  .mdi-apple:before {
    content: '\F33C';
  }
  
  .mdi-behance:before {
    content: '\F33D';
  }
  
  .mdi-codepen:before {
    content: '\F33E';
  }
  
  .mdi-dribbble:before {
    content: '\F33F';
  }
  
  .mdi-dropbox:before {
    content: '\F340';
  }
  
  .mdi-evernote:before {
    content: '\F341';
  }
  
  .mdi-facebook-box:before {
    content: '\F342';
  }
  
  .mdi-facebook:before {
    content: '\F343';
  }
  
  .mdi-github-box:before {
    content: '\F344';
  }
  
  .mdi-github:before {
    content: '\F345';
  }
  
  .mdi-google-drive:before {
    content: '\F346';
  }
  
  .mdi-google-earth:before {
    content: '\F347';
  }
  
  .mdi-google-glass:before {
    content: '\F348';
  }
  
  .mdi-google-maps:before {
    content: '\F349';
  }
  
  .mdi-google-pages:before {
    content: '\F34A';
  }
  
  .mdi-google-play:before {
    content: '\F34B';
  }
  
  .mdi-google-plus-box:before {
    content: '\F34C';
  }
  
  .mdi-google-plus:before {
    content: '\F34D';
  }
  
  .mdi-google:before {
    content: '\F34E';
  }
  
  .mdi-instagram:before {
    content: '\F34F';
  }
  
  .mdi-language-css3:before {
    content: '\F350';
  }
  
  .mdi-language-html5:before {
    content: '\F351';
  }
  
  .mdi-language-javascript:before {
    content: '\F352';
  }
  
  .mdi-language-python-alt:before {
    content: '\F353';
  }
  
  .mdi-language-python:before {
    content: '\F354';
  }
  
  .mdi-lastfm:before {
    content: '\F355';
  }
  
  .mdi-linkedin-box:before {
    content: '\F356';
  }
  
  .mdi-paypal:before {
    content: '\F357';
  }
  
  .mdi-pinterest-box:before {
    content: '\F358';
  }
  
  .mdi-pocket:before {
    content: '\F359';
  }
  
  .mdi-polymer:before {
    content: '\F35A';
  }
  
  .mdi-share:before {
    content: '\F35B';
  }
  
  .mdi-stackoverflow:before {
    content: '\F35C';
  }
  
  .mdi-steam-square:before {
    content: '\F35D';
  }
  
  .mdi-steam:before {
    content: '\F35E';
  }
  
  .mdi-twitter-box:before {
    content: '\F35F';
  }
  
  .mdi-twitter:before {
    content: '\F360';
  }
  
  .mdi-vk:before {
    content: '\F361';
  }
  
  .mdi-wikipedia:before {
    content: '\F362';
  }
  
  .mdi-windows:before {
    content: '\F363';
  }
  
  .mdi-aspect-ratio-alt:before {
    content: '\F364';
  }
  
  .mdi-aspect-ratio:before {
    content: '\F365';
  }
  
  .mdi-blur-circular:before {
    content: '\F366';
  }
  
  .mdi-blur-linear:before {
    content: '\F367';
  }
  
  .mdi-blur-off:before {
    content: '\F368';
  }
  
  .mdi-blur:before {
    content: '\F369';
  }
  
  .mdi-brightness-2:before {
    content: '\F36A';
  }
  
  .mdi-brightness-3:before {
    content: '\F36B';
  }
  
  .mdi-brightness-4:before {
    content: '\F36C';
  }
  
  .mdi-brightness-5:before {
    content: '\F36D';
  }
  
  .mdi-brightness-6:before {
    content: '\F36E';
  }
  
  .mdi-brightness-7:before {
    content: '\F36F';
  }
  
  .mdi-brightness-auto:before {
    content: '\F370';
  }
  
  .mdi-brightness-setting:before {
    content: '\F371';
  }
  
  .mdi-broken-image:before {
    content: '\F372';
  }
  
  .mdi-center-focus-strong:before {
    content: '\F373';
  }
  
  .mdi-center-focus-weak:before {
    content: '\F374';
  }
  
  .mdi-compare:before {
    content: '\F375';
  }
  
  .mdi-crop-16-9:before {
    content: '\F376';
  }
  
  .mdi-crop-3-2:before {
    content: '\F377';
  }
  
  .mdi-crop-5-4:before {
    content: '\F378';
  }
  
  .mdi-crop-7-5:before {
    content: '\F379';
  }
  
  .mdi-crop-din:before {
    content: '\F37A';
  }
  
  .mdi-crop-free:before {
    content: '\F37B';
  }
  
  .mdi-crop-landscape:before {
    content: '\F37C';
  }
  
  .mdi-crop-portrait:before {
    content: '\F37D';
  }
  
  .mdi-crop-square:before {
    content: '\F37E';
  }
  
  .mdi-exposure-alt:before {
    content: '\F37F';
  }
  
  .mdi-exposure:before {
    content: '\F380';
  }
  
  .mdi-filter-b-and-w:before {
    content: '\F381';
  }
  
  .mdi-filter-center-focus:before {
    content: '\F382';
  }
  
  .mdi-filter-frames:before {
    content: '\F383';
  }
  
  .mdi-filter-tilt-shift:before {
    content: '\F384';
  }
  
  .mdi-gradient:before {
    content: '\F385';
  }
  
  .mdi-grain:before {
    content: '\F386';
  }
  
  .mdi-graphic-eq:before {
    content: '\F387';
  }
  
  .mdi-hdr-off:before {
    content: '\F388';
  }
  
  .mdi-hdr-strong:before {
    content: '\F389';
  }
  
  .mdi-hdr-weak:before {
    content: '\F38A';
  }
  
  .mdi-hdr:before {
    content: '\F38B';
  }
  
  .mdi-iridescent:before {
    content: '\F38C';
  }
  
  .mdi-leak-off:before {
    content: '\F38D';
  }
  
  .mdi-leak:before {
    content: '\F38E';
  }
  
  .mdi-looks:before {
    content: '\F38F';
  }
  
  .mdi-loupe:before {
    content: '\F390';
  }
  
  .mdi-panorama-horizontal:before {
    content: '\F391';
  }
  
  .mdi-panorama-vertical:before {
    content: '\F392';
  }
  
  .mdi-panorama-wide-angle:before {
    content: '\F393';
  }
  
  .mdi-photo-size-select-large:before {
    content: '\F394';
  }
  
  .mdi-photo-size-select-small:before {
    content: '\F395';
  }
  
  .mdi-picture-in-picture:before {
    content: '\F396';
  }
  
  .mdi-slideshow:before {
    content: '\F397';
  }
  
  .mdi-texture:before {
    content: '\F398';
  }
  
  .mdi-tonality:before {
    content: '\F399';
  }
  
  .mdi-vignette:before {
    content: '\F39A';
  }
  
  .mdi-wb-auto:before {
    content: '\F39B';
  }
  
  .mdi-eject-alt:before {
    content: '\F39C';
  }
  
  .mdi-eject:before {
    content: '\F39D';
  }
  
  .mdi-equalizer:before {
    content: '\F39E';
  }
  
  .mdi-fast-forward:before {
    content: '\F39F';
  }
  
  .mdi-fast-rewind:before {
    content: '\F3A0';
  }
  
  .mdi-forward-10:before {
    content: '\F3A1';
  }
  
  .mdi-forward-30:before {
    content: '\F3A2';
  }
  
  .mdi-forward-5:before {
    content: '\F3A3';
  }
  
  .mdi-hearing:before {
    content: '\F3A4';
  }
  
  .mdi-pause-circle-outline:before {
    content: '\F3A5';
  }
  
  .mdi-pause-circle:before {
    content: '\F3A6';
  }
  
  .mdi-pause:before {
    content: '\F3A7';
  }
  
  .mdi-play-circle-outline:before {
    content: '\F3A8';
  }
  
  .mdi-play-circle:before {
    content: '\F3A9';
  }
  
  .mdi-play:before {
    content: '\F3AA';
  }
  
  .mdi-playlist-audio:before {
    content: '\F3AB';
  }
  
  .mdi-playlist-plus:before {
    content: '\F3AC';
  }
  
  .mdi-repeat-one:before {
    content: '\F3AD';
  }
  
  .mdi-repeat:before {
    content: '\F3AE';
  }
  
  .mdi-replay-10:before {
    content: '\F3AF';
  }
  
  .mdi-replay-30:before {
    content: '\F3B0';
  }
  
  .mdi-replay-5:before {
    content: '\F3B1';
  }
  
  .mdi-replay:before {
    content: '\F3B2';
  }
  
  .mdi-shuffle:before {
    content: '\F3B3';
  }
  
  .mdi-skip-next:before {
    content: '\F3B4';
  }
  
  .mdi-skip-previous:before {
    content: '\F3B5';
  }
  
  .mdi-stop:before {
    content: '\F3B6';
  }
  
  .mdi-surround-sound:before {
    content: '\F3B7';
  }
  
  .mdi-tune:before {
    content: '\F3B8';
  }
  
  .mdi-volume-down:before {
    content: '\F3B9';
  }
  
  .mdi-volume-mute:before {
    content: '\F3BA';
  }
  
  .mdi-volume-off:before {
    content: '\F3BB';
  }
  
  .mdi-volume-up:before {
    content: '\F3BC';
  }
  
  .mdi-n-1-square:before {
    content: '\F3BD';
  }
  
  .mdi-n-2-square:before {
    content: '\F3BE';
  }
  
  .mdi-n-3-square:before {
    content: '\F3BF';
  }
  
  .mdi-n-4-square:before {
    content: '\F3C0';
  }
  
  .mdi-n-5-square:before {
    content: '\F3C1';
  }
  
  .mdi-n-6-square:before {
    content: '\F3C2';
  }
  
  .mdi-neg-1:before {
    content: '\F3C3';
  }
  
  .mdi-neg-2:before {
    content: '\F3C4';
  }
  
  .mdi-plus-1:before {
    content: '\F3C5';
  }
  
  .mdi-plus-2:before {
    content: '\F3C6';
  }
  
  .mdi-sec-10:before {
    content: '\F3C7';
  }
  
  .mdi-sec-3:before {
    content: '\F3C8';
  }
  
  .mdi-zero:before {
    content: '\F3C9';
  }
  
  .mdi-airline-seat-flat-angled:before {
    content: '\F3CA';
  }
  
  .mdi-airline-seat-flat:before {
    content: '\F3CB';
  }
  
  .mdi-airline-seat-individual-suite:before {
    content: '\F3CC';
  }
  
  .mdi-airline-seat-legroom-extra:before {
    content: '\F3CD';
  }
  
  .mdi-airline-seat-legroom-normal:before {
    content: '\F3CE';
  }
  
  .mdi-airline-seat-legroom-reduced:before {
    content: '\F3CF';
  }
  
  .mdi-airline-seat-recline-extra:before {
    content: '\F3D0';
  }
  
  .mdi-airline-seat-recline-normal:before {
    content: '\F3D1';
  }
  
  .mdi-airplay:before {
    content: '\F3D2';
  }
  
  .mdi-closed-caption:before {
    content: '\F3D3';
  }
  
  .mdi-confirmation-number:before {
    content: '\F3D4';
  }
  
  .mdi-developer-board:before {
    content: '\F3D5';
  }
  
  .mdi-disc-full:before {
    content: '\F3D6';
  }
  
  .mdi-explicit:before {
    content: '\F3D7';
  }
  
  .mdi-flight-land:before {
    content: '\F3D8';
  }
  
  .mdi-flight-takeoff:before {
    content: '\F3D9';
  }
  
  .mdi-flip-to-back:before {
    content: '\F3DA';
  }
  
  .mdi-flip-to-front:before {
    content: '\F3DB';
  }
  
  .mdi-group-work:before {
    content: '\F3DC';
  }
  
  .mdi-hd:before {
    content: '\F3DD';
  }
  
  .mdi-hq:before {
    content: '\F3DE';
  }
  
  .mdi-markunread-mailbox:before {
    content: '\F3DF';
  }
  
  .mdi-memory:before {
    content: '\F3E0';
  }
  
  .mdi-nfc:before {
    content: '\F3E1';
  }
  
  .mdi-play-for-work:before {
    content: '\F3E2';
  }
  
  .mdi-power-input:before {
    content: '\F3E3';
  }
  
  .mdi-present-to-all:before {
    content: '\F3E4';
  }
  
  .mdi-satellite:before {
    content: '\F3E5';
  }
  
  .mdi-tap-and-play:before {
    content: '\F3E6';
  }
  
  .mdi-vibration:before {
    content: '\F3E7';
  }
  
  .mdi-voicemail:before {
    content: '\F3E8';
  }
  
  .mdi-group:before {
    content: '\F3E9';
  }
  
  .mdi-rss:before {
    content: '\F3EA';
  }
  
  .mdi-shape:before {
    content: '\F3EB';
  }
  
  .mdi-spinner:before {
    content: '\F3EC';
  }
  
  .mdi-ungroup:before {
    content: '\F3ED';
  }
  
  .mdi-500px:before {
    content: '\F3EE';
  }
  
  .mdi-8tracks:before {
    content: '\F3EF';
  }
  
  .mdi-amazon:before {
    content: '\F3F0';
  }
  
  .mdi-blogger:before {
    content: '\F3F1';
  }
  
  .mdi-delicious:before {
    content: '\F3F2';
  }
  
  .mdi-disqus:before {
    content: '\F3F3';
  }
  
  .mdi-flattr:before {
    content: '\F3F4';
  }
  
  .mdi-flickr:before {
    content: '\F3F5';
  }
  
  .mdi-github-alt:before {
    content: '\F3F6';
  }
  
  .mdi-google-old:before {
    content: '\F3F7';
  }
  
  .mdi-linkedin:before {
    content: '\F3F8';
  }
  
  .mdi-odnoklassniki:before {
    content: '\F3F9';
  }
  
  .mdi-outlook:before {
    content: '\F3FA';
  }
  
  .mdi-paypal-alt:before {
    content: '\F3FB';
  }
  
  .mdi-pinterest:before {
    content: '\F3FC';
  }
  
  .mdi-playstation:before {
    content: '\F3FD';
  }
  
  .mdi-reddit:before {
    content: '\F3FE';
  }
  
  .mdi-skype:before {
    content: '\F3FF';
  }
  
  .mdi-slideshare:before {
    content: '\F400';
  }
  
  .mdi-soundcloud:before {
    content: '\F401';
  }
  
  .mdi-tumblr:before {
    content: '\F402';
  }
  
  .mdi-twitch:before {
    content: '\F403';
  }
  
  .mdi-vimeo:before {
    content: '\F404';
  }
  
  .mdi-whatsapp:before {
    content: '\F405';
  }
  
  .mdi-xbox:before {
    content: '\F406';
  }
  
  .mdi-yahoo:before {
    content: '\F407';
  }
  
  .mdi-youtube-play:before {
    content: '\F408';
  }
  
  .mdi-youtube:before {
    content: '\F409';
  }
  
  .mdi-3d-rotation:before {
    content: '\F101';
  }
  
  .mdi-airplane-off:before {
    content: '\F102';
  }
  
  .mdi-airplane:before {
    content: '\F103';
  }
  
  .mdi-album:before {
    content: '\F104';
  }
  
  .mdi-archive:before {
    content: '\F105';
  }
  
  .mdi-assignment-account:before {
    content: '\F106';
  }
  
  .mdi-assignment-alert:before {
    content: '\F107';
  }
  
  .mdi-assignment-check:before {
    content: '\F108';
  }
  
  .mdi-assignment-o:before {
    content: '\F109';
  }
  
  .mdi-assignment-return:before {
    content: '\F10A';
  }
  
  .mdi-assignment-returned:before {
    content: '\F10B';
  }
  
  .mdi-assignment:before {
    content: '\F10C';
  }
  
  .mdi-attachment-alt:before {
    content: '\F10D';
  }
  
  .mdi-attachment:before {
    content: '\F10E';
  }
  
  .mdi-audio:before {
    content: '\F10F';
  }
  
  .mdi-badge-check:before {
    content: '\F110';
  }
  
  .mdi-balance-wallet:before {
    content: '\F111';
  }
  
  .mdi-balance:before {
    content: '\F112';
  }
  
  .mdi-battery-alert:before {
    content: '\F113';
  }
  
  .mdi-battery-flash:before {
    content: '\F114';
  }
  
  .mdi-battery-unknown:before {
    content: '\F115';
  }
  
  .mdi-battery:before {
    content: '\F116';
  }
  
  .mdi-bike:before {
    content: '\F117';
  }
  
  .mdi-block-alt:before {
    content: '\F118';
  }
  
  .mdi-block:before {
    content: '\F119';
  }
  
  .mdi-boat:before {
    content: '\F11A';
  }
  
  .mdi-book-image:before {
    content: '\F11B';
  }
  
  .mdi-book:before {
    content: '\F11C';
  }
  
  .mdi-bookmark-outline:before {
    content: '\F11D';
  }
  
  .mdi-bookmark:before {
    content: '\F11E';
  }
  
  .mdi-brush:before {
    content: '\F11F';
  }
  
  .mdi-bug:before {
    content: '\F120';
  }
  
  .mdi-bus:before {
    content: '\F121';
  }
  
  .mdi-cake:before {
    content: '\F122';
  }
  
  .mdi-car-taxi:before {
    content: '\F123';
  }
  
  .mdi-car-wash:before {
    content: '\F124';
  }
  
  .mdi-car:before {
    content: '\F125';
  }
  
  .mdi-card-giftcard:before {
    content: '\F126';
  }
  
  .mdi-card-membership:before {
    content: '\F127';
  }
  
  .mdi-card-travel:before {
    content: '\F128';
  }
  
  .mdi-card:before {
    content: '\F129';
  }
  
  .mdi-case-check:before {
    content: '\F12A';
  }
  
  .mdi-case-download:before {
    content: '\F12B';
  }
  
  .mdi-case-play:before {
    content: '\F12C';
  }
  
  .mdi-case:before {
    content: '\F12D';
  }
  
  .mdi-cast-connected:before {
    content: '\F12E';
  }
  
  .mdi-cast:before {
    content: '\F12F';
  }
  
  .mdi-chart-donut:before {
    content: '\F130';
  }
  
  .mdi-chart:before {
    content: '\F131';
  }
  
  .mdi-city-alt:before {
    content: '\F132';
  }
  
  .mdi-city:before {
    content: '\F133';
  }
  
  .mdi-close-circle-o:before {
    content: '\F134';
  }
  
  .mdi-close-circle:before {
    content: '\F135';
  }
  
  .mdi-close:before {
    content: '\F136';
  }
  
  .mdi-cocktail:before {
    content: '\F137';
  }
  
  .mdi-code-setting:before {
    content: '\F138';
  }
  
  .mdi-code-smartphone:before {
    content: '\F139';
  }
  
  .mdi-code:before {
    content: '\F13A';
  }
  
  .mdi-coffee:before {
    content: '\F13B';
  }
  
  .mdi-collection-bookmark:before {
    content: '\F13C';
  }
  
  .mdi-collection-case-play:before {
    content: '\F13D';
  }
  
  .mdi-collection-folder-image:before {
    content: '\F13E';
  }
  
  .mdi-collection-image-o:before {
    content: '\F13F';
  }
  
  .mdi-collection-image:before {
    content: '\F140';
  }
  
  .mdi-collection-item-1:before {
    content: '\F141';
  }
  
  .mdi-collection-item-2:before {
    content: '\F142';
  }
  
  .mdi-collection-item-3:before {
    content: '\F143';
  }
  
  .mdi-collection-item-4:before {
    content: '\F144';
  }
  
  .mdi-collection-item-5:before {
    content: '\F145';
  }
  
  .mdi-collection-item-6:before {
    content: '\F146';
  }
  
  .mdi-collection-item-7:before {
    content: '\F147';
  }
  
  .mdi-collection-item-8:before {
    content: '\F148';
  }
  
  .mdi-collection-item-9-plus:before {
    content: '\F149';
  }
  
  .mdi-collection-item-9:before {
    content: '\F14A';
  }
  
  .mdi-collection-item:before {
    content: '\F14B';
  }
  
  .mdi-collection-music:before {
    content: '\F14C';
  }
  
  .mdi-collection-pdf:before {
    content: '\F14D';
  }
  
  .mdi-collection-plus:before {
    content: '\F14E';
  }
  
  .mdi-collection-speaker:before {
    content: '\F14F';
  }
  
  .mdi-collection-text:before {
    content: '\F150';
  }
  
  .mdi-collection-video:before {
    content: '\F151';
  }
  
  .mdi-compass:before {
    content: '\F152';
  }
  
  .mdi-cutlery:before {
    content: '\F153';
  }
  
  .mdi-delete:before {
    content: '\F154';
  }
  
  .mdi-dialpad:before {
    content: '\F155';
  }
  
  .mdi-dns:before {
    content: '\F156';
  }
  
  .mdi-drink:before {
    content: '\F157';
  }
  
  .mdi-edit:before {
    content: '\F158';
  }
  
  .mdi-email-open:before {
    content: '\F159';
  }
  
  .mdi-email:before {
    content: '\F15A';
  }
  
  .mdi-eye-off:before {
    content: '\F15B';
  }
  
  .mdi-eye:before {
    content: '\F15C';
  }
  
  .mdi-eyedropper:before {
    content: '\F15D';
  }
  
  .mdi-favorite-outline:before {
    content: '\F15E';
  }
  
  .mdi-favorite:before {
    content: '\F15F';
  }
  
  .mdi-filter-list:before {
    content: '\F160';
  }
  
  .mdi-fire:before {
    content: '\F161';
  }
  
  .mdi-flag:before {
    content: '\F162';
  }
  
  .mdi-flare:before {
    content: '\F163';
  }
  
  .mdi-flash-auto:before {
    content: '\F164';
  }
  
  .mdi-flash-off:before {
    content: '\F165';
  }
  
  .mdi-flash:before {
    content: '\F166';
  }
  
  .mdi-flip:before {
    content: '\F167';
  }
  
  .mdi-flower-alt:before {
    content: '\F168';
  }
  
  .mdi-flower:before {
    content: '\F169';
  }
  
  .mdi-font:before {
    content: '\F16A';
  }
  
  .mdi-fullscreen-alt:before {
    content: '\F16B';
  }
  
  .mdi-fullscreen-exit:before {
    content: '\F16C';
  }
  
  .mdi-fullscreen:before {
    content: '\F16D';
  }
  
  .mdi-functions:before {
    content: '\F16E';
  }
  
  .mdi-gas-station:before {
    content: '\F16F';
  }
  
  .mdi-gesture:before {
    content: '\F170';
  }
  
  .mdi-globe-alt:before {
    content: '\F171';
  }
  
  .mdi-globe-lock:before {
    content: '\F172';
  }
  
  .mdi-globe:before {
    content: '\F173';
  }
  
  .mdi-graduation-cap:before {
    content: '\F174';
  }
  
  .mdi-home:before {
    content: '\F175';
  }
  
  .mdi-hospital-alt:before {
    content: '\F176';
  }
  
  .mdi-hospital:before {
    content: '\F177';
  }
  
  .mdi-hotel:before {
    content: '\F178';
  }
  
  .mdi-hourglass-alt:before {
    content: '\F179';
  }
  
  .mdi-hourglass-outline:before {
    content: '\F17A';
  }
  
  .mdi-hourglass:before {
    content: '\F17B';
  }
  
  .mdi-http:before {
    content: '\F17C';
  }
  
  .mdi-image-alt:before {
    content: '\F17D';
  }
  
  .mdi-image-o:before {
    content: '\F17E';
  }
  
  .mdi-image:before {
    content: '\F17F';
  }
  
  .mdi-inbox:before {
    content: '\F180';
  }
  
  .mdi-invert-colors-off:before {
    content: '\F181';
  }
  
  .mdi-invert-colors:before {
    content: '\F182';
  }
  
  .mdi-key:before {
    content: '\F183';
  }
  
  .mdi-label-alt-outline:before {
    content: '\F184';
  }
  
  .mdi-label-alt:before {
    content: '\F185';
  }
  
  .mdi-label-heart:before {
    content: '\F186';
  }
  
  .mdi-label:before {
    content: '\F187';
  }
  
  .mdi-labels:before {
    content: '\F188';
  }
  
  .mdi-lamp:before {
    content: '\F189';
  }
  
  .mdi-landscape:before {
    content: '\F18A';
  }
  
  .mdi-layers-off:before {
    content: '\F18B';
  }
  
  .mdi-layers:before {
    content: '\F18C';
  }
  
  .mdi-library:before {
    content: '\F18D';
  }
  
  .mdi-link:before {
    content: '\F18E';
  }
  
  .mdi-lock-open:before {
    content: '\F18F';
  }
  
  .mdi-lock-outline:before {
    content: '\F190';
  }
  
  .mdi-lock:before {
    content: '\F191';
  }
  
  .mdi-mail-reply-all:before {
    content: '\F192';
  }
  
  .mdi-mail-reply:before {
    content: '\F193';
  }
  
  .mdi-mail-send:before {
    content: '\F194';
  }
  
  .mdi-mall:before {
    content: '\F195';
  }
  
  .mdi-map:before {
    content: '\F196';
  }
  
  .mdi-menu:before {
    content: '\F197';
  }
  
  .mdi-money-box:before {
    content: '\F198';
  }
  
  .mdi-money-off:before {
    content: '\F199';
  }
  
  .mdi-money:before {
    content: '\F19A';
  }
  
  .mdi-more-vert:before {
    content: '\F19B';
  }
  
  .mdi-more:before {
    content: '\F19C';
  }
  
  .mdi-movie-alt:before {
    content: '\F19D';
  }
  
  .mdi-movie:before {
    content: '\F19E';
  }
  
  .mdi-nature-people:before {
    content: '\F19F';
  }
  
  .mdi-nature:before {
    content: '\F1A0';
  }
  
  .mdi-navigation:before {
    content: '\F1A1';
  }
  
  .mdi-open-in-browser:before {
    content: '\F1A2';
  }
  
  .mdi-open-in-new:before {
    content: '\F1A3';
  }
  
  .mdi-palette:before {
    content: '\F1A4';
  }
  
  .mdi-parking:before {
    content: '\F1A5';
  }
  
  .mdi-pin-account:before {
    content: '\F1A6';
  }
  
  .mdi-pin-assistant:before {
    content: '\F1A7';
  }
  
  .mdi-pin-drop:before {
    content: '\F1A8';
  }
  
  .mdi-pin-help:before {
    content: '\F1A9';
  }
  
  .mdi-pin-off:before {
    content: '\F1AA';
  }
  
  .mdi-pin:before {
    content: '\F1AB';
  }
  
  .mdi-pizza:before {
    content: '\F1AC';
  }
  
  .mdi-plaster:before {
    content: '\F1AD';
  }
  
  .mdi-power-setting:before {
    content: '\F1AE';
  }
  
  .mdi-power:before {
    content: '\F1AF';
  }
  
  .mdi-print:before {
    content: '\F1B0';
  }
  
  .mdi-puzzle-piece:before {
    content: '\F1B1';
  }
  
  .mdi-quote:before {
    content: '\F1B2';
  }
  
  .mdi-railway:before {
    content: '\F1B3';
  }
  
  .mdi-receipt:before {
    content: '\F1B4';
  }
  
  .mdi-refresh-alt:before {
    content: '\F1B5';
  }
  
  .mdi-refresh-sync-alert:before {
    content: '\F1B6';
  }
  
  .mdi-refresh-sync-off:before {
    content: '\F1B7';
  }
  
  .mdi-refresh-sync:before {
    content: '\F1B8';
  }
  
  .mdi-refresh:before {
    content: '\F1B9';
  }
  
  .mdi-roller:before {
    content: '\F1BA';
  }
  
  .mdi-ruler:before {
    content: '\F1BB';
  }
  
  .mdi-scissors:before {
    content: '\F1BC';
  }
  
  .mdi-screen-rotation-lock:before {
    content: '\F1BD';
  }
  
  .mdi-screen-rotation:before {
    content: '\F1BE';
  }
  
  .mdi-search-for:before {
    content: '\F1BF';
  }
  
  .mdi-search-in-file:before {
    content: '\F1C0';
  }
  
  .mdi-search-in-page:before {
    content: '\F1C1';
  }
  
  .mdi-search-replace:before {
    content: '\F1C2';
  }
  
  .mdi-search:before {
    content: '\F1C3';
  }
  
  .mdi-seat:before {
    content: '\F1C4';
  }
  
  .mdi-settings-square:before {
    content: '\F1C5';
  }
  
  .mdi-settings:before {
    content: '\F1C6';
  }
  
  .mdi-shield-check:before {
    content: '\F1C7';
  }
  
  .mdi-shield-security:before {
    content: '\F1C8';
  }
  
  .mdi-shopping-basket:before {
    content: '\F1C9';
  }
  
  .mdi-shopping-cart-plus:before {
    content: '\F1CA';
  }
  
  .mdi-shopping-cart:before {
    content: '\F1CB';
  }
  
  .mdi-sign-in:before {
    content: '\F1CC';
  }
  
  .mdi-sort-amount-asc:before {
    content: '\F1CD';
  }
  
  .mdi-sort-amount-desc:before {
    content: '\F1CE';
  }
  
  .mdi-sort-asc:before {
    content: '\F1CF';
  }
  
  .mdi-sort-desc:before {
    content: '\F1D0';
  }
  
  .mdi-spellcheck:before {
    content: '\F1D1';
  }
  
  .mdi-storage:before {
    content: '\F1D2';
  }
  
  .mdi-store-24:before {
    content: '\F1D3';
  }
  
  .mdi-store:before {
    content: '\F1D4';
  }
  
  .mdi-subway:before {
    content: '\F1D5';
  }
  
  .mdi-sun:before {
    content: '\F1D6';
  }
  
  .mdi-tab-unselected:before {
    content: '\F1D7';
  }
  
  .mdi-tab:before {
    content: '\F1D8';
  }
  
  .mdi-tag-close:before {
    content: '\F1D9';
  }
  
  .mdi-tag-more:before {
    content: '\F1DA';
  }
  
  .mdi-tag:before {
    content: '\F1DB';
  }
  
  .mdi-thumb-down:before {
    content: '\F1DC';
  }
  
  .mdi-thumb-up-down:before {
    content: '\F1DD';
  }
  
  .mdi-thumb-up:before {
    content: '\F1DE';
  }
  
  .mdi-ticket-star:before {
    content: '\F1DF';
  }
  
  .mdi-toll:before {
    content: '\F1E0';
  }
  
  .mdi-toys:before {
    content: '\F1E1';
  }
  
  .mdi-traffic:before {
    content: '\F1E2';
  }
  
  .mdi-translate:before {
    content: '\F1E3';
  }
  
  .mdi-triangle-down:before {
    content: '\F1E4';
  }
  
  .mdi-triangle-up:before {
    content: '\F1E5';
  }
  
  .mdi-truck:before {
    content: '\F1E6';
  }
  
  .mdi-turning-sign:before {
    content: '\F1E7';
  }
  
  .mdi-wallpaper:before {
    content: '\F1E8';
  }
  
  .mdi-washing-machine:before {
    content: '\F1E9';
  }
  
  .mdi-window-maximize:before {
    content: '\F1EA';
  }
  
  .mdi-window-minimize:before {
    content: '\F1EB';
  }
  
  .mdi-window-restore:before {
    content: '\F1EC';
  }
  
  .mdi-wrench:before {
    content: '\F1ED';
  }
  
  .mdi-zoom-in:before {
    content: '\F1EE';
  }
  
  .mdi-zoom-out:before {
    content: '\F1EF';
  }
  
  .mdi-alert-circle-o:before {
    content: '\F1F0';
  }
  
  .mdi-alert-circle:before {
    content: '\F1F1';
  }
  
  .mdi-alert-octagon:before {
    content: '\F1F2';
  }
  
  .mdi-alert-polygon:before {
    content: '\F1F3';
  }
  
  .mdi-alert-triangle:before {
    content: '\F1F4';
  }
  
  .mdi-help-outline:before {
    content: '\F1F5';
  }
  
  .mdi-help:before {
    content: '\F1F6';
  }
  
  .mdi-info-outline:before {
    content: '\F1F7';
  }
  
  .mdi-info:before {
    content: '\F1F8';
  }
  
  .mdi-notifications-active:before {
    content: '\F1F9';
  }
  
  .mdi-notifications-add:before {
    content: '\F1FA';
  }
  
  .mdi-notifications-none:before {
    content: '\F1FB';
  }
  
  .mdi-notifications-off:before {
    content: '\F1FC';
  }
  
  .mdi-notifications-paused:before {
    content: '\F1FD';
  }
  
  .mdi-notifications:before {
    content: '\F1FE';
  }
  
  .mdi-account-add:before {
    content: '\F1FF';
  }
  
  .mdi-account-box-mail:before {
    content: '\F200';
  }
  
  .mdi-account-box-o:before {
    content: '\F201';
  }
  
  .mdi-account-box-phone:before {
    content: '\F202';
  }
  
  .mdi-account-box:before {
    content: '\F203';
  }
  
  .mdi-account-calendar:before {
    content: '\F204';
  }
  
  .mdi-account-circle:before {
    content: '\F205';
  }
  
  .mdi-account-o:before {
    content: '\F206';
  }
  
  .mdi-account:before {
    content: '\F207';
  }
  
  .mdi-accounts-add:before {
    content: '\F208';
  }
  
  .mdi-accounts-alt:before {
    content: '\F209';
  }
  
  .mdi-accounts-list-alt:before {
    content: '\F20A';
  }
  
  .mdi-accounts-list:before {
    content: '\F20B';
  }
  
  .mdi-accounts-outline:before {
    content: '\F20C';
  }
  
  .mdi-accounts:before {
    content: '\F20D';
  }
  
  .mdi-face:before {
    content: '\F20E';
  }
  
  .mdi-female:before {
    content: '\F20F';
  }
  
  .mdi-male-alt:before {
    content: '\F210';
  }
  
  .mdi-male-female:before {
    content: '\F211';
  }
  
  .mdi-male:before {
    content: '\F212';
  }
  
  .mdi-mood-bad:before {
    content: '\F213';
  }
  
  .mdi-mood:before {
    content: '\F214';
  }
  
  .mdi-run:before {
    content: '\F215';
  }
  
  .mdi-walk:before {
    content: '\F216';
  }
  
  .mdi-cloud-box:before {
    content: '\F217';
  }
  
  .mdi-cloud-circle:before {
    content: '\F218';
  }
  
  .mdi-cloud-done:before {
    content: '\F219';
  }
  
  .mdi-cloud-download:before {
    content: '\F21A';
  }
  
  .mdi-cloud-off:before {
    content: '\F21B';
  }
  
  .mdi-cloud-outline-alt:before {
    content: '\F21C';
  }
  
  .mdi-cloud-outline:before {
    content: '\F21D';
  }
  
  .mdi-cloud-upload:before {
    content: '\F21E';
  }
  
  .mdi-cloud:before {
    content: '\F21F';
  }
  
  .mdi-download:before {
    content: '\F220';
  }
  
  .mdi-file-plus:before {
    content: '\F221';
  }
  
  .mdi-file-text:before {
    content: '\F222';
  }
  
  .mdi-file:before {
    content: '\F223';
  }
  
  .mdi-folder-outline:before {
    content: '\F224';
  }
  
  .mdi-folder-person:before {
    content: '\F225';
  }
  
  .mdi-folder-star-alt:before {
    content: '\F226';
  }
  
  .mdi-folder-star:before {
    content: '\F227';
  }
  
  .mdi-folder:before {
    content: '\F228';
  }
  
  .mdi-gif:before {
    content: '\F229';
  }
  
  .mdi-upload:before {
    content: '\F22A';
  }
  
  .mdi-border-all:before {
    content: '\F22B';
  }
  
  .mdi-border-bottom:before {
    content: '\F22C';
  }
  
  .mdi-border-clear:before {
    content: '\F22D';
  }
  
  .mdi-border-color:before {
    content: '\F22E';
  }
  
  .mdi-border-horizontal:before {
    content: '\F22F';
  }
  
  .mdi-border-inner:before {
    content: '\F230';
  }
  
  .mdi-border-left:before {
    content: '\F231';
  }
  
  .mdi-border-outer:before {
    content: '\F232';
  }
  
  .mdi-border-right:before {
    content: '\F233';
  }
  
  .mdi-border-style:before {
    content: '\F234';
  }
  
  .mdi-border-top:before {
    content: '\F235';
  }
  
  .mdi-border-vertical:before {
    content: '\F236';
  }
  
  .mdi-copy:before {
    content: '\F237';
  }
  
  .mdi-crop:before {
    content: '\F238';
  }
  
  .mdi-format-align-center:before {
    content: '\F239';
  }
  
  .mdi-format-align-justify:before {
    content: '\F23A';
  }
  
  .mdi-format-align-left:before {
    content: '\F23B';
  }
  
  .mdi-format-align-right:before {
    content: '\F23C';
  }
  
  .mdi-format-bold:before {
    content: '\F23D';
  }
  
  .mdi-format-clear-all:before {
    content: '\F23E';
  }
  
  .mdi-format-clear:before {
    content: '\F23F';
  }
  
  .mdi-format-color-fill:before {
    content: '\F240';
  }
  
  .mdi-format-color-reset:before {
    content: '\F241';
  }
  
  .mdi-format-color-text:before {
    content: '\F242';
  }
  
  .mdi-format-indent-decrease:before {
    content: '\F243';
  }
  
  .mdi-format-indent-increase:before {
    content: '\F244';
  }
  
  .mdi-format-italic:before {
    content: '\F245';
  }
  
  .mdi-format-line-spacing:before {
    content: '\F246';
  }
  
  .mdi-format-list-bulleted:before {
    content: '\F247';
  }
  
  .mdi-format-list-numbered:before {
    content: '\F248';
  }
  
  .mdi-format-ltr:before {
    content: '\F249';
  }
  
  .mdi-format-rtl:before {
    content: '\F24A';
  }
  
  .mdi-format-size:before {
    content: '\F24B';
  }
  
  .mdi-format-strikethrough-s:before {
    content: '\F24C';
  }
  
  .mdi-format-strikethrough:before {
    content: '\F24D';
  }
  
  .mdi-format-subject:before {
    content: '\F24E';
  }
  
  .mdi-format-underlined:before {
    content: '\F24F';
  }
  
  .mdi-format-valign-bottom:before {
    content: '\F250';
  }
  
  .mdi-format-valign-center:before {
    content: '\F251';
  }
  
  .mdi-format-valign-top:before {
    content: '\F252';
  }
  
  .mdi-redo:before {
    content: '\F253';
  }
  
  .mdi-select-all:before {
    content: '\F254';
  }
  
  .mdi-space-bar:before {
    content: '\F255';
  }
  
  .mdi-text-format:before {
    content: '\F256';
  }
  
  .mdi-transform:before {
    content: '\F257';
  }
  
  .mdi-undo:before {
    content: '\F258';
  }
  
  .mdi-wrap-text:before {
    content: '\F259';
  }
  
  .mdi-comment-alert:before {
    content: '\F25A';
  }
  
  .mdi-comment-alt-text:before {
    content: '\F25B';
  }
  
  .mdi-comment-alt:before {
    content: '\F25C';
  }
  
  .mdi-comment-edit:before {
    content: '\F25D';
  }
  
  .mdi-comment-image:before {
    content: '\F25E';
  }
  
  .mdi-comment-list:before {
    content: '\F25F';
  }
  
  .mdi-comment-more:before {
    content: '\F260';
  }
  
  .mdi-comment-outline:before {
    content: '\F261';
  }
  
  .mdi-comment-text-alt:before {
    content: '\F262';
  }
  
  .mdi-comment-text:before {
    content: '\F263';
  }
  
  .mdi-comment-video:before {
    content: '\F264';
  }
  
  .mdi-comment:before {
    content: '\F265';
  }
  
  .mdi-comments:before {
    content: '\F266';
  }
  
  .mdi-check-all:before {
    content: '\F267';
  }
  
  .mdi-check-circle-u:before {
    content: '\F268';
  }
  
  .mdi-check-circle:before {
    content: '\F269';
  }
  
  .mdi-check-square:before {
    content: '\F26A';
  }
  
  .mdi-check:before {
    content: '\F26B';
  }
  
  .mdi-circle-o:before {
    content: '\F26C';
  }
  
  .mdi-circle:before {
    content: '\F26D';
  }
  
  .mdi-dot-circle-alt:before {
    content: '\F26E';
  }
  
  .mdi-dot-circle:before {
    content: '\F26F';
  }
  
  .mdi-minus-circle-outline:before {
    content: '\F270';
  }
  
  .mdi-minus-circle:before {
    content: '\F271';
  }
  
  .mdi-minus-square:before {
    content: '\F272';
  }
  
  .mdi-minus:before {
    content: '\F273';
  }
  
  .mdi-plus-circle-o-duplicate:before {
    content: '\F274';
  }
  
  .mdi-plus-circle-o:before {
    content: '\F275';
  }
  
  .mdi-plus-circle:before {
    content: '\F276';
  }
  
  .mdi-plus-square:before {
    content: '\F277';
  }
  
  .mdi-plus:before {
    content: '\F278';
  }
  
  .mdi-square-o:before {
    content: '\F279';
  }
  
  .mdi-star-circle:before {
    content: '\F27A';
  }
  
  .mdi-star-half:before {
    content: '\F27B';
  }
  
  .mdi-star-outline:before {
    content: '\F27C';
  }
  
  .mdi-star:before {
    content: '\F27D';
  }
  
  .mdi-bluetooth-connected:before {
    content: '\F27E';
  }
  
  .mdi-bluetooth-off:before {
    content: '\F27F';
  }
  
  .mdi-bluetooth-search:before {
    content: '\F280';
  }
  
  .mdi-bluetooth-setting:before {
    content: '\F281';
  }
  
  .mdi-bluetooth:before {
    content: '\F282';
  }
  
  .mdi-camera-add:before {
    content: '\F283';
  }
  
  .mdi-camera-alt:before {
    content: '\F284';
  }
  
  .mdi-camera-bw:before {
    content: '\F285';
  }
  
  .mdi-camera-front:before {
    content: '\F286';
  }
  
  .mdi-camera-mic:before {
    content: '\F287';
  }
  
  .mdi-camera-party-mode:before {
    content: '\F288';
  }
  
  .mdi-camera-rear:before {
    content: '\F289';
  }
  
  .mdi-camera-roll:before {
    content: '\F28A';
  }
  
  .mdi-camera-switch:before {
    content: '\F28B';
  }
  
  .mdi-camera:before {
    content: '\F28C';
  }
  
  .mdi-card-alert:before {
    content: '\F28D';
  }
  
  .mdi-card-off:before {
    content: '\F28E';
  }
  
  .mdi-card-sd:before {
    content: '\F28F';
  }
  
  .mdi-card-sim:before {
    content: '\F290';
  }
  
  .mdi-desktop-mac:before {
    content: '\F291';
  }
  
  .mdi-desktop-windows:before {
    content: '\F292';
  }
  
  .mdi-device-hub:before {
    content: '\F293';
  }
  
  .mdi-devices-off:before {
    content: '\F294';
  }
  
  .mdi-devices:before {
    content: '\F295';
  }
  
  .mdi-dock:before {
    content: '\F296';
  }
  
  .mdi-floppy:before {
    content: '\F297';
  }
  
  .mdi-gamepad:before {
    content: '\F298';
  }
  
  .mdi-gps-dot:before {
    content: '\F299';
  }
  
  .mdi-gps-off:before {
    content: '\F29A';
  }
  
  .mdi-gps:before {
    content: '\F29B';
  }
  
  .mdi-headset-mic:before {
    content: '\F29C';
  }
  
  .mdi-headset:before {
    content: '\F29D';
  }
  
  .mdi-input-antenna:before {
    content: '\F29E';
  }
  
  .mdi-input-composite:before {
    content: '\F29F';
  }
  
  .mdi-input-hdmi:before {
    content: '\F2A0';
  }
  
  .mdi-input-power:before {
    content: '\F2A1';
  }
  
  .mdi-input-svideo:before {
    content: '\F2A2';
  }
  
  .mdi-keyboard-hide:before {
    content: '\F2A3';
  }
  
  .mdi-keyboard:before {
    content: '\F2A4';
  }
  
  .mdi-laptop-chromebook:before {
    content: '\F2A5';
  }
  
  .mdi-laptop-mac:before {
    content: '\F2A6';
  }
  
  .mdi-laptop:before {
    content: '\F2A7';
  }
  
  .mdi-mic-off:before {
    content: '\F2A8';
  }
  
  .mdi-mic-outline:before {
    content: '\F2A9';
  }
  
  .mdi-mic-setting:before {
    content: '\F2AA';
  }
  
  .mdi-mic:before {
    content: '\F2AB';
  }
  
  .mdi-mouse:before {
    content: '\F2AC';
  }
  
  .mdi-network-alert:before {
    content: '\F2AD';
  }
  
  .mdi-network-locked:before {
    content: '\F2AE';
  }
  
  .mdi-network-off:before {
    content: '\F2AF';
  }
  
  .mdi-network-outline:before {
    content: '\F2B0';
  }
  
  .mdi-network-setting:before {
    content: '\F2B1';
  }
  
  .mdi-network:before {
    content: '\F2B2';
  }
  
  .mdi-phone-bluetooth:before {
    content: '\F2B3';
  }
  
  .mdi-phone-end:before {
    content: '\F2B4';
  }
  
  .mdi-phone-forwarded:before {
    content: '\F2B5';
  }
  
  .mdi-phone-in-talk:before {
    content: '\F2B6';
  }
  
  .mdi-phone-locked:before {
    content: '\F2B7';
  }
  
  .mdi-phone-missed:before {
    content: '\F2B8';
  }
  
  .mdi-phone-msg:before {
    content: '\F2B9';
  }
  
  .mdi-phone-paused:before {
    content: '\F2BA';
  }
  
  .mdi-phone-ring:before {
    content: '\F2BB';
  }
  
  .mdi-phone-setting:before {
    content: '\F2BC';
  }
  
  .mdi-phone-sip:before {
    content: '\F2BD';
  }
  
  .mdi-phone:before {
    content: '\F2BE';
  }
  
  .mdi-portable-wifi-changes:before {
    content: '\F2BF';
  }
  
  .mdi-portable-wifi-off:before {
    content: '\F2C0';
  }
  
  .mdi-portable-wifi:before {
    content: '\F2C1';
  }
  
  .mdi-radio:before {
    content: '\F2C2';
  }
  
  .mdi-reader:before {
    content: '\F2C3';
  }
  
  .mdi-remote-control-alt:before {
    content: '\F2C4';
  }
  
  .mdi-remote-control:before {
    content: '\F2C5';
  }
  
  .mdi-router:before {
    content: '\F2C6';
  }
  
  .mdi-scanner:before {
    content: '\F2C7';
  }
  
  .mdi-smartphone-android:before {
    content: '\F2C8';
  }
  
  .mdi-smartphone-download:before {
    content: '\F2C9';
  }
  
  .mdi-smartphone-erase:before {
    content: '\F2CA';
  }
  
  .mdi-smartphone-info:before {
    content: '\F2CB';
  }
  
  .mdi-smartphone-iphone:before {
    content: '\F2CC';
  }
  
  .mdi-smartphone-landscape-lock:before {
    content: '\F2CD';
  }
  
  .mdi-smartphone-landscape:before {
    content: '\F2CE';
  }
  
  .mdi-smartphone-lock:before {
    content: '\F2CF';
  }
  
  .mdi-smartphone-portrait-lock:before {
    content: '\F2D0';
  }
  
  .mdi-smartphone-ring:before {
    content: '\F2D1';
  }
  
  .mdi-smartphone-setting:before {
    content: '\F2D2';
  }
  
  .mdi-smartphone-setup:before {
    content: '\F2D3';
  }
  
  .mdi-smartphone:before {
    content: '\F2D4';
  }
  
  .mdi-speaker:before {
    content: '\F2D5';
  }
  
  .mdi-tablet-android:before {
    content: '\F2D6';
  }
  
  .mdi-tablet-mac:before {
    content: '\F2D7';
  }
  
  .mdi-tablet:before {
    content: '\F2D8';
  }
  
  .mdi-tv-alt-play:before {
    content: '\F2D9';
  }
  
  .mdi-tv-list:before {
    content: '\F2DA';
  }
  
  .mdi-tv-play:before {
    content: '\F2DB';
  }
  
  .mdi-tv:before {
    content: '\F2DC';
  }
  
  .mdi-usb:before {
    content: '\F2DD';
  }
  
  .mdi-videocam-off:before {
    content: '\F2DE';
  }
  
  .mdi-videocam-switch:before {
    content: '\F2DF';
  }
  
  .mdi-videocam:before {
    content: '\F2E0';
  }
  
  .mdi-watch:before {
    content: '\F2E1';
  }
  
  .mdi-wifi-alt-2:before {
    content: '\F2E2';
  }
  
  .mdi-wifi-alt:before {
    content: '\F2E3';
  }
  
  .mdi-wifi-info:before {
    content: '\F2E4';
  }
  
  .mdi-wifi-lock:before {
    content: '\F2E5';
  }
  
  .mdi-wifi-off:before {
    content: '\F2E6';
  }
  
  .mdi-wifi-outline:before {
    content: '\F2E7';
  }
  
  .mdi-wifi:before {
    content: '\F2E8';
  }
  
  .mdi-arrow-left-bottom:before {
    content: '\F2E9';
  }
  
  .mdi-arrow-left:before {
    content: '\F2EA';
  }
  
  .mdi-arrow-merge:before {
    content: '\F2EB';
  }
  
  .mdi-arrow-missed:before {
    content: '\F2EC';
  }
  
  .mdi-arrow-right-top:before {
    content: '\F2ED';
  }
  
  .mdi-arrow-right:before {
    content: '\F2EE';
  }
  
  .mdi-arrow-split:before {
    content: '\F2EF';
  }
  
  .mdi-arrows:before {
    content: '\F2F0';
  }
  
  .mdi-caret-down-circle:before {
    content: '\F2F1';
  }
  
  .mdi-caret-down:before {
    content: '\F2F2';
  }
  
  .mdi-caret-left-circle:before {
    content: '\F2F3';
  }
  
  .mdi-caret-left:before {
    content: '\F2F4';
  }
  
  .mdi-caret-right-circle:before {
    content: '\F2F5';
  }
  
  .mdi-caret-right:before {
    content: '\F2F6';
  }
  
  .mdi-caret-up-circle:before {
    content: '\F2F7';
  }
  
  .mdi-caret-up:before {
    content: '\F2F8';
  }
  
  .mdi-chevron-down:before {
    content: '\F2F9';
  }
  
  .mdi-chevron-left:before {
    content: '\F2FA';
  }
  
  .mdi-chevron-right:before {
    content: '\F2FB';
  }
  
  .mdi-chevron-up:before {
    content: '\F2FC';
  }
  
  .mdi-forward:before {
    content: '\F2FD';
  }
  
  .mdi-long-arrow-down:before {
    content: '\F2FE';
  }
  
  .mdi-long-arrow-left:before {
    content: '\F2FF';
  }
  
  .mdi-long-arrow-return:before {
    content: '\F300';
  }
  
  .mdi-long-arrow-right:before {
    content: '\F301';
  }
  
  .mdi-long-arrow-tab:before {
    content: '\F302';
  }
  
  .mdi-long-arrow-up:before {
    content: '\F303';
  }
  
  .mdi-rotate-ccw:before {
    content: '\F304';
  }
  
  .mdi-rotate-cw:before {
    content: '\F305';
  }
  
  .mdi-rotate-left:before {
    content: '\F306';
  }
  
  .mdi-rotate-right:before {
    content: '\F307';
  }
  
  .mdi-square-down:before {
    content: '\F308';
  }
  
  .mdi-square-right:before {
    content: '\F309';
  }
  
  .mdi-swap-alt:before {
    content: '\F30A';
  }
  
  .mdi-swap-vertical-circle:before {
    content: '\F30B';
  }
  
  .mdi-swap-vertical:before {
    content: '\F30C';
  }
  
  .mdi-swap:before {
    content: '\F30D';
  }
  
  .mdi-trending-down:before {
    content: '\F30E';
  }
  
  .mdi-trending-flat:before {
    content: '\F30F';
  }
  
  .mdi-trending-up:before {
    content: '\F310';
  }
  
  .mdi-unfold-less:before {
    content: '\F311';
  }
  
  .mdi-unfold-more:before {
    content: '\F312';
  }
  
  .mdi-apps:before {
    content: '\F313';
  }
  
  .mdi-grid-off:before {
    content: '\F314';
  }
  
  .mdi-grid:before {
    content: '\F315';
  }
  
  .mdi-view-agenda:before {
    content: '\F316';
  }
  
  .mdi-view-array:before {
    content: '\F317';
  }
  
  .mdi-view-carousel:before {
    content: '\F318';
  }
  
  .mdi-view-column:before {
    content: '\F319';
  }
  
  .mdi-view-comfy:before {
    content: '\F31A';
  }
  
  .mdi-view-compact:before {
    content: '\F31B';
  }
  
  .mdi-view-dashboard:before {
    content: '\F31C';
  }
  
  .mdi-view-day:before {
    content: '\F31D';
  }
  
  .mdi-view-headline:before {
    content: '\F31E';
  }
  
  .mdi-view-list-alt:before {
    content: '\F31F';
  }
  
  .mdi-view-list:before {
    content: '\F320';
  }
  
  .mdi-view-module:before {
    content: '\F321';
  }
  
  .mdi-view-quilt:before {
    content: '\F322';
  }
  
  .mdi-view-stream:before {
    content: '\F323';
  }
  
  .mdi-view-subtitles:before {
    content: '\F324';
  }
  
  .mdi-view-toc:before {
    content: '\F325';
  }
  
  .mdi-view-web:before {
    content: '\F326';
  }
  
  .mdi-view-week:before {
    content: '\F327';
  }
  
  .mdi-widgets:before {
    content: '\F328';
  }
  
  .mdi-alarm-check:before {
    content: '\F329';
  }
  
  .mdi-alarm-off:before {
    content: '\F32A';
  }
  
  .mdi-alarm-plus:before {
    content: '\F32B';
  }
  
  .mdi-alarm-snooze:before {
    content: '\F32C';
  }
  
  .mdi-alarm:before {
    content: '\F32D';
  }
  
  .mdi-calendar-alt:before {
    content: '\F32E';
  }
  
  .mdi-calendar-check:before {
    content: '\F32F';
  }
  
  .mdi-calendar-close:before {
    content: '\F330';
  }
  
  .mdi-calendar-note:before {
    content: '\F331';
  }
  
  .mdi-calendar:before {
    content: '\F332';
  }
  
  .mdi-time-countdown:before {
    content: '\F333';
  }
  
  .mdi-time-interval:before {
    content: '\F334';
  }
  
  .mdi-time-restore-setting:before {
    content: '\F335';
  }
  
  .mdi-time-restore:before {
    content: '\F336';
  }
  
  .mdi-time:before {
    content: '\F337';
  }
  
  .mdi-timer-off:before {
    content: '\F338';
  }
  
  .mdi-timer:before {
    content: '\F339';
  }
  
  .mdi-android-alt:before {
    content: '\F33A';
  }
  
  .mdi-android:before {
    content: '\F33B';
  }
  
  .mdi-apple:before {
    content: '\F33C';
  }
  
  .mdi-behance:before {
    content: '\F33D';
  }
  
  .mdi-codepen:before {
    content: '\F33E';
  }
  
  .mdi-dribbble:before {
    content: '\F33F';
  }
  
  .mdi-dropbox:before {
    content: '\F340';
  }
  
  .mdi-evernote:before {
    content: '\F341';
  }
  
  .mdi-facebook-box:before {
    content: '\F342';
  }
  
  .mdi-facebook:before {
    content: '\F343';
  }
  
  .mdi-github-box:before {
    content: '\F344';
  }
  
  .mdi-github:before {
    content: '\F345';
  }
  
  .mdi-google-drive:before {
    content: '\F346';
  }
  
  .mdi-google-earth:before {
    content: '\F347';
  }
  
  .mdi-google-glass:before {
    content: '\F348';
  }
  
  .mdi-google-maps:before {
    content: '\F349';
  }
  
  .mdi-google-pages:before {
    content: '\F34A';
  }
  
  .mdi-google-play:before {
    content: '\F34B';
  }
  
  .mdi-google-plus-box:before {
    content: '\F34C';
  }
  
  .mdi-google-plus:before {
    content: '\F34D';
  }
  
  .mdi-google:before {
    content: '\F34E';
  }
  
  .mdi-instagram:before {
    content: '\F34F';
  }
  
  .mdi-language-css3:before {
    content: '\F350';
  }
  
  .mdi-language-html5:before {
    content: '\F351';
  }
  
  .mdi-language-javascript:before {
    content: '\F352';
  }
  
  .mdi-language-python-alt:before {
    content: '\F353';
  }
  
  .mdi-language-python:before {
    content: '\F354';
  }
  
  .mdi-lastfm:before {
    content: '\F355';
  }
  
  .mdi-linkedin-box:before {
    content: '\F356';
  }
  
  .mdi-paypal:before {
    content: '\F357';
  }
  
  .mdi-pinterest-box:before {
    content: '\F358';
  }
  
  .mdi-pocket:before {
    content: '\F359';
  }
  
  .mdi-polymer:before {
    content: '\F35A';
  }
  
  .mdi-share:before {
    content: '\F35B';
  }
  
  .mdi-stackoverflow:before {
    content: '\F35C';
  }
  
  .mdi-steam-square:before {
    content: '\F35D';
  }
  
  .mdi-steam:before {
    content: '\F35E';
  }
  
  .mdi-twitter-box:before {
    content: '\F35F';
  }
  
  .mdi-twitter:before {
    content: '\F360';
  }
  
  .mdi-vk:before {
    content: '\F361';
  }
  
  .mdi-wikipedia:before {
    content: '\F362';
  }
  
  .mdi-windows:before {
    content: '\F363';
  }
  
  .mdi-aspect-ratio-alt:before {
    content: '\F364';
  }
  
  .mdi-aspect-ratio:before {
    content: '\F365';
  }
  
  .mdi-blur-circular:before {
    content: '\F366';
  }
  
  .mdi-blur-linear:before {
    content: '\F367';
  }
  
  .mdi-blur-off:before {
    content: '\F368';
  }
  
  .mdi-blur:before {
    content: '\F369';
  }
  
  .mdi-brightness-2:before {
    content: '\F36A';
  }
  
  .mdi-brightness-3:before {
    content: '\F36B';
  }
  
  .mdi-brightness-4:before {
    content: '\F36C';
  }
  
  .mdi-brightness-5:before {
    content: '\F36D';
  }
  
  .mdi-brightness-6:before {
    content: '\F36E';
  }
  
  .mdi-brightness-7:before {
    content: '\F36F';
  }
  
  .mdi-brightness-auto:before {
    content: '\F370';
  }
  
  .mdi-brightness-setting:before {
    content: '\F371';
  }
  
  .mdi-broken-image:before {
    content: '\F372';
  }
  
  .mdi-center-focus-strong:before {
    content: '\F373';
  }
  
  .mdi-center-focus-weak:before {
    content: '\F374';
  }
  
  .mdi-compare:before {
    content: '\F375';
  }
  
  .mdi-crop-16-9:before {
    content: '\F376';
  }
  
  .mdi-crop-3-2:before {
    content: '\F377';
  }
  
  .mdi-crop-5-4:before {
    content: '\F378';
  }
  
  .mdi-crop-7-5:before {
    content: '\F379';
  }
  
  .mdi-crop-din:before {
    content: '\F37A';
  }
  
  .mdi-crop-free:before {
    content: '\F37B';
  }
  
  .mdi-crop-landscape:before {
    content: '\F37C';
  }
  
  .mdi-crop-portrait:before {
    content: '\F37D';
  }
  
  .mdi-crop-square:before {
    content: '\F37E';
  }
  
  .mdi-exposure-alt:before {
    content: '\F37F';
  }
  
  .mdi-exposure:before {
    content: '\F380';
  }
  
  .mdi-filter-b-and-w:before {
    content: '\F381';
  }
  
  .mdi-filter-center-focus:before {
    content: '\F382';
  }
  
  .mdi-filter-frames:before {
    content: '\F383';
  }
  
  .mdi-filter-tilt-shift:before {
    content: '\F384';
  }
  
  .mdi-gradient:before {
    content: '\F385';
  }
  
  .mdi-grain:before {
    content: '\F386';
  }
  
  .mdi-graphic-eq:before {
    content: '\F387';
  }
  
  .mdi-hdr-off:before {
    content: '\F388';
  }
  
  .mdi-hdr-strong:before {
    content: '\F389';
  }
  
  .mdi-hdr-weak:before {
    content: '\F38A';
  }
  
  .mdi-hdr:before {
    content: '\F38B';
  }
  
  .mdi-iridescent:before {
    content: '\F38C';
  }
  
  .mdi-leak-off:before {
    content: '\F38D';
  }
  
  .mdi-leak:before {
    content: '\F38E';
  }
  
  .mdi-looks:before {
    content: '\F38F';
  }
  
  .mdi-loupe:before {
    content: '\F390';
  }
  
  .mdi-panorama-horizontal:before {
    content: '\F391';
  }
  
  .mdi-panorama-vertical:before {
    content: '\F392';
  }
  
  .mdi-panorama-wide-angle:before {
    content: '\F393';
  }
  
  .mdi-photo-size-select-large:before {
    content: '\F394';
  }
  
  .mdi-photo-size-select-small:before {
    content: '\F395';
  }
  
  .mdi-picture-in-picture:before {
    content: '\F396';
  }
  
  .mdi-slideshow:before {
    content: '\F397';
  }
  
  .mdi-texture:before {
    content: '\F398';
  }
  
  .mdi-tonality:before {
    content: '\F399';
  }
  
  .mdi-vignette:before {
    content: '\F39A';
  }
  
  .mdi-wb-auto:before {
    content: '\F39B';
  }
  
  .mdi-eject-alt:before {
    content: '\F39C';
  }
  
  .mdi-eject:before {
    content: '\F39D';
  }
  
  .mdi-equalizer:before {
    content: '\F39E';
  }
  
  .mdi-fast-forward:before {
    content: '\F39F';
  }
  
  .mdi-fast-rewind:before {
    content: '\F3A0';
  }
  
  .mdi-forward-10:before {
    content: '\F3A1';
  }
  
  .mdi-forward-30:before {
    content: '\F3A2';
  }
  
  .mdi-forward-5:before {
    content: '\F3A3';
  }
  
  .mdi-hearing:before {
    content: '\F3A4';
  }
  
  .mdi-pause-circle-outline:before {
    content: '\F3A5';
  }
  
  .mdi-pause-circle:before {
    content: '\F3A6';
  }
  
  .mdi-pause:before {
    content: '\F3A7';
  }
  
  .mdi-play-circle-outline:before {
    content: '\F3A8';
  }
  
  .mdi-play-circle:before {
    content: '\F3A9';
  }
  
  .mdi-play:before {
    content: '\F3AA';
  }
  
  .mdi-playlist-audio:before {
    content: '\F3AB';
  }
  
  .mdi-playlist-plus:before {
    content: '\F3AC';
  }
  
  .mdi-repeat-one:before {
    content: '\F3AD';
  }
  
  .mdi-repeat:before {
    content: '\F3AE';
  }
  
  .mdi-replay-10:before {
    content: '\F3AF';
  }
  
  .mdi-replay-30:before {
    content: '\F3B0';
  }
  
  .mdi-replay-5:before {
    content: '\F3B1';
  }
  
  .mdi-replay:before {
    content: '\F3B2';
  }
  
  .mdi-shuffle:before {
    content: '\F3B3';
  }
  
  .mdi-skip-next:before {
    content: '\F3B4';
  }
  
  .mdi-skip-previous:before {
    content: '\F3B5';
  }
  
  .mdi-stop:before {
    content: '\F3B6';
  }
  
  .mdi-surround-sound:before {
    content: '\F3B7';
  }
  
  .mdi-tune:before {
    content: '\F3B8';
  }
  
  .mdi-volume-down:before {
    content: '\F3B9';
  }
  
  .mdi-volume-mute:before {
    content: '\F3BA';
  }
  
  .mdi-volume-off:before {
    content: '\F3BB';
  }
  
  .mdi-volume-up:before {
    content: '\F3BC';
  }
  
  .mdi-n-1-square:before {
    content: '\F3BD';
  }
  
  .mdi-n-2-square:before {
    content: '\F3BE';
  }
  
  .mdi-n-3-square:before {
    content: '\F3BF';
  }
  
  .mdi-n-4-square:before {
    content: '\F3C0';
  }
  
  .mdi-n-5-square:before {
    content: '\F3C1';
  }
  
  .mdi-n-6-square:before {
    content: '\F3C2';
  }
  
  .mdi-neg-1:before {
    content: '\F3C3';
  }
  
  .mdi-neg-2:before {
    content: '\F3C4';
  }
  
  .mdi-plus-1:before {
    content: '\F3C5';
  }
  
  .mdi-plus-2:before {
    content: '\F3C6';
  }
  
  .mdi-sec-10:before {
    content: '\F3C7';
  }
  
  .mdi-sec-3:before {
    content: '\F3C8';
  }
  
  .mdi-zero:before {
    content: '\F3C9';
  }
  
  .mdi-airline-seat-flat-angled:before {
    content: '\F3CA';
  }
  
  .mdi-airline-seat-flat:before {
    content: '\F3CB';
  }
  
  .mdi-airline-seat-individual-suite:before {
    content: '\F3CC';
  }
  
  .mdi-airline-seat-legroom-extra:before {
    content: '\F3CD';
  }
  
  .mdi-airline-seat-legroom-normal:before {
    content: '\F3CE';
  }
  
  .mdi-airline-seat-legroom-reduced:before {
    content: '\F3CF';
  }
  
  .mdi-airline-seat-recline-extra:before {
    content: '\F3D0';
  }
  
  .mdi-airline-seat-recline-normal:before {
    content: '\F3D1';
  }
  
  .mdi-airplay:before {
    content: '\F3D2';
  }
  
  .mdi-closed-caption:before {
    content: '\F3D3';
  }
  
  .mdi-confirmation-number:before {
    content: '\F3D4';
  }
  
  .mdi-developer-board:before {
    content: '\F3D5';
  }
  
  .mdi-disc-full:before {
    content: '\F3D6';
  }
  
  .mdi-explicit:before {
    content: '\F3D7';
  }
  
  .mdi-flight-land:before {
    content: '\F3D8';
  }
  
  .mdi-flight-takeoff:before {
    content: '\F3D9';
  }
  
  .mdi-flip-to-back:before {
    content: '\F3DA';
  }
  
  .mdi-flip-to-front:before {
    content: '\F3DB';
  }
  
  .mdi-group-work:before {
    content: '\F3DC';
  }
  
  .mdi-hd:before {
    content: '\F3DD';
  }
  
  .mdi-hq:before {
    content: '\F3DE';
  }
  
  .mdi-markunread-mailbox:before {
    content: '\F3DF';
  }
  
  .mdi-memory:before {
    content: '\F3E0';
  }
  
  .mdi-nfc:before {
    content: '\F3E1';
  }
  
  .mdi-play-for-work:before {
    content: '\F3E2';
  }
  
  .mdi-power-input:before {
    content: '\F3E3';
  }
  
  .mdi-present-to-all:before {
    content: '\F3E4';
  }
  
  .mdi-satellite:before {
    content: '\F3E5';
  }
  
  .mdi-tap-and-play:before {
    content: '\F3E6';
  }
  
  .mdi-vibration:before {
    content: '\F3E7';
  }
  
  .mdi-voicemail:before {
    content: '\F3E8';
  }
  
  .mdi-group:before {
    content: '\F3E9';
  }
  
  .mdi-rss:before {
    content: '\F3EA';
  }
  
  .mdi-shape:before {
    content: '\F3EB';
  }
  
  .mdi-spinner:before {
    content: '\F3EC';
  }
  
  .mdi-ungroup:before {
    content: '\F3ED';
  }
  
  .mdi-500px:before {
    content: '\F3EE';
  }
  
  .mdi-8tracks:before {
    content: '\F3EF';
  }
  
  .mdi-amazon:before {
    content: '\F3F0';
  }
  
  .mdi-blogger:before {
    content: '\F3F1';
  }
  
  .mdi-delicious:before {
    content: '\F3F2';
  }
  
  .mdi-disqus:before {
    content: '\F3F3';
  }
  
  .mdi-flattr:before {
    content: '\F3F4';
  }
  
  .mdi-flickr:before {
    content: '\F3F5';
  }
  
  .mdi-github-alt:before {
    content: '\F3F6';
  }
  
  .mdi-google-old:before {
    content: '\F3F7';
  }
  
  .mdi-linkedin:before {
    content: '\F3F8';
  }
  
  .mdi-odnoklassniki:before {
    content: '\F3F9';
  }
  
  .mdi-outlook:before {
    content: '\F3FA';
  }
  
  .mdi-paypal-alt:before {
    content: '\F3FB';
  }
  
  .mdi-pinterest:before {
    content: '\F3FC';
  }
  
  .mdi-playstation:before {
    content: '\F3FD';
  }
  
  .mdi-reddit:before {
    content: '\F3FE';
  }
  
  .mdi-skype:before {
    content: '\F3FF';
  }
  
  .mdi-slideshare:before {
    content: '\F400';
  }
  
  .mdi-soundcloud:before {
    content: '\F401';
  }
  
  .mdi-tumblr:before {
    content: '\F402';
  }
  
  .mdi-twitch:before {
    content: '\F403';
  }
  
  .mdi-vimeo:before {
    content: '\F404';
  }
  
  .mdi-whatsapp:before {
    content: '\F405';
  }
  
  .mdi-xbox:before {
    content: '\F406';
  }
  
  .mdi-yahoo:before {
    content: '\F407';
  }
  
  .mdi-youtube-play:before {
    content: '\F408';
  }
  
  .mdi-youtube:before {
    content: '\F409';
  }
  
  .mdi-import-export:before {
    content: '\F30C';
  }
  
  .mdi-swap-vertical-:before {
    content: '\F30C';
  }
  
  .mdi-airplanemode-inactive:before {
    content: '\F102';
  }
  
  .mdi-airplanemode-active:before {
    content: '\F103';
  }
  
  .mdi-rate-review:before {
    content: '\F103';
  }
  
  .mdi-comment-sign:before {
    content: '\F25A';
  }
  
  .mdi-network-warning:before {
    content: '\F2AD';
  }
  
  .mdi-shopping-cart-add:before {
    content: '\F1CA';
  }
  
  .mdi-file-add:before {
    content: '\F221';
  }
  
  .mdi-network-wifi-scan:before {
    content: '\F2E4';
  }
  
  .mdi-collection-add:before {
    content: '\F14E';
  }
  
  .mdi-format-playlist-add:before {
    content: '\F3AC';
  }
  
  .mdi-format-queue-music:before {
    content: '\F3AB';
  }
  
  .mdi-plus-box:before {
    content: '\F277';
  }
  
  .mdi-tag-backspace:before {
    content: '\F1D9';
  }
  
  .mdi-alarm-add:before {
    content: '\F32B';
  }
  
  .mdi-battery-charging:before {
    content: '\F114';
  }
  
  .mdi-daydream-setting:before {
    content: '\F217';
  }
  
  .mdi-more-horiz:before {
    content: '\F19C';
  }
  
  .mdi-book-photo:before {
    content: '\F11B';
  }
  
  .mdi-incandescent:before {
    content: '\F189';
  }
  
  .mdi-wb-iridescent:before {
    content: '\F38C';
  }
  
  .mdi-calendar-remove:before {
    content: '\F330';
  }
  
  .mdi-refresh-sync-disabled:before {
    content: '\F1B7';
  }
  
  .mdi-refresh-sync-problem:before {
    content: '\F1B6';
  }
  
  .mdi-crop-original:before {
    content: '\F17E';
  }
  
  .mdi-power-off:before {
    content: '\F1AF';
  }
  
  .mdi-power-off-setting:before {
    content: '\F1AE';
  }
  
  .mdi-leak-remove:before {
    content: '\F38D';
  }
  
  .mdi-star-border:before {
    content: '\F27C';
  }
  
  .mdi-brightness-low:before {
    content: '\F36D';
  }
  
  .mdi-brightness-medium:before {
    content: '\F36E';
  }
  
  .mdi-brightness-high:before {
    content: '\F36F';
  }
  
  .mdi-smartphone-portrait:before {
    content: '\F2D4';
  }
  
  .mdi-live-tv:before {
    content: '\F2D9';
  }
  
  .mdi-format-textdirection-l-to-r:before {
    content: '\F249';
  }
  
  .mdi-format-textdirection-r-to-l:before {
    content: '\F24A';
  }
  
  .mdi-arrow-back:before {
    content: '\F2EA';
  }
  
  .mdi-arrow-forward:before {
    content: '\F2EE';
  }
  
  .mdi-arrow-in:before {
    content: '\F2E9';
  }
  
  .mdi-arrow-out:before {
    content: '\F2ED';
  }
  
  .mdi-rotate-90-degrees-ccw:before {
    content: '\F304';
  }
  
  .mdi-adb:before {
    content: '\F33A';
  }
  
  .mdi-network-wifi:before {
    content: '\F2E8';
  }
  
  .mdi-network-wifi-alt:before {
    content: '\F2E3';
  }
  
  .mdi-network-wifi-lock:before {
    content: '\F2E5';
  }
  
  .mdi-network-wifi-off:before {
    content: '\F2E6';
  }
  
  .mdi-network-wifi-outline:before {
    content: '\F2E7';
  }
  
  .mdi-network-wifi-info:before {
    content: '\F2E4';
  }
  
  .mdi-layers-clear:before {
    content: '\F18B';
  }
  
  .mdi-colorize:before {
    content: '\F15D';
  }
  
  .mdi-format-paint:before {
    content: '\F1BA';
  }
  
  .mdi-format-quote:before {
    content: '\F1B2';
  }
  
  .mdi-camera-monochrome-photos:before {
    content: '\F285';
  }
  
  .mdi-sort-by-alpha:before {
    content: '\F1CF';
  }
  
  .mdi-folder-shared:before {
    content: '\F225';
  }
  
  .mdi-folder-special:before {
    content: '\F226';
  }
  
  .mdi-comment-dots:before {
    content: '\F260';
  }
  
  .mdi-reorder:before {
    content: '\F31E';
  }
  
  .mdi-dehaze:before {
    content: '\F197';
  }
  
  .mdi-sort:before {
    content: '\F1CE';
  }
  
  .mdi-pages:before {
    content: '\F34A';
  }
  
  .mdi-stack-overflow:before {
    content: '\F35C';
  }
  
  .mdi-calendar-account:before {
    content: '\F204';
  }
  
  .mdi-paste:before {
    content: '\F109';
  }
  
  .mdi-cut:before {
    content: '\F1BC';
  }
  
  .mdi-save:before {
    content: '\F297';
  }
  
  .mdi-smartphone-code:before {
    content: '\F139';
  }
  
  .mdi-directions-bike:before {
    content: '\F117';
  }
  
  .mdi-directions-boat:before {
    content: '\F11A';
  }
  
  .mdi-directions-bus:before {
    content: '\F121';
  }
  
  .mdi-directions-car:before {
    content: '\F125';
  }
  
  .mdi-directions-railway:before {
    content: '\F1B3';
  }
  
  .mdi-directions-run:before {
    content: '\F215';
  }
  
  .mdi-directions-subway:before {
    content: '\F1D5';
  }
  
  .mdi-directions-walk:before {
    content: '\F216';
  }
  
  .mdi-local-hotel:before {
    content: '\F178';
  }
  
  .mdi-local-activity:before {
    content: '\F1DF';
  }
  
  .mdi-local-play:before {
    content: '\F1DF';
  }
  
  .mdi-local-airport:before {
    content: '\F103';
  }
  
  .mdi-local-atm:before {
    content: '\F198';
  }
  
  .mdi-local-bar:before {
    content: '\F137';
  }
  
  .mdi-local-cafe:before {
    content: '\F13B';
  }
  
  .mdi-local-car-wash:before {
    content: '\F124';
  }
  
  .mdi-local-convenience-store:before {
    content: '\F1D3';
  }
  
  .mdi-local-dining:before {
    content: '\F153';
  }
  
  .mdi-local-drink:before {
    content: '\F157';
  }
  
  .mdi-local-florist:before {
    content: '\F168';
  }
  
  .mdi-local-gas-station:before {
    content: '\F16F';
  }
  
  .mdi-local-grocery-store:before {
    content: '\F1CB';
  }
  
  .mdi-local-hospital:before {
    content: '\F177';
  }
  
  .mdi-local-laundry-service:before {
    content: '\F1E9';
  }
  
  .mdi-local-library:before {
    content: '\F18D';
  }
  
  .mdi-local-mall:before {
    content: '\F195';
  }
  
  .mdi-local-movies:before {
    content: '\F19D';
  }
  
  .mdi-local-offer:before {
    content: '\F187';
  }
  
  .mdi-local-parking:before {
    content: '\F1A5';
  }
  
  .mdi-local-parking:before {
    content: '\F1A5';
  }
  
  .mdi-local-pharmacy:before {
    content: '\F176';
  }
  
  .mdi-local-phone:before {
    content: '\F2BE';
  }
  
  .mdi-local-pizza:before {
    content: '\F1AC';
  }
  
  .mdi-local-post-office:before {
    content: '\F15A';
  }
  
  .mdi-local-printshop:before {
    content: '\F1B0';
  }
  
  .mdi-local-see:before {
    content: '\F28C';
  }
  
  .mdi-local-shipping:before {
    content: '\F1E6';
  }
  
  .mdi-local-store:before {
    content: '\F1D4';
  }
  
  .mdi-local-taxi:before {
    content: '\F123';
  }
  
  .mdi-local-wc:before {
    content: '\F211';
  }
  
  .mdi-my-location:before {
    content: '\F299';
  }
  
  .mdi-directions:before {
    content: '\F1E7';
  }
  
